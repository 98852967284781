import React from 'react';
import {ReactComponent as Phone} from '../../../../../@casino/img/phone2.svg';
import {ReactComponent as Email} from '../../../../../@casino/img/email2.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {translate} from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    method: {
        display: 'flex', 
        width: '100%', 
        alignItems: 'center'
    },
    activeTabTwo: {
        display: 'flex', 
        alignItems: 'center', 
        width: '50%', 
        borderRadius: 3, 
        border: '1px solid #FF005C',
        cursor: 'pointer',
        color: '#fff',
        userSelect: 'none',
    },
    noActiveTabTwo: {
        display: 'flex', 
        alignItems: 'center', 
        width: '50%', 
        background: '#0034A5', 
        borderRadius: 3, 
        cursor: 'pointer',
        userSelect: 'none'
    },
    label: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    label2: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    mr18: {
        marginRight: 18
    },
    mr5: {
        marginRight: 5
    }
}))

const Method = ({setMethod, method, formik}) => {
    const classes = useStyles();
    const active = formik.values.active;

    return (    
        <div className={classes.method}>
            <div 
                onClick={() => {
                    setMethod('phone');
                    formik.setFieldValue('email', '');
                    formik.setFieldValue('phone', '');
                    formik.setFieldValue('password', '');
                }}
                className={method === 'phone' ? classes.activeTabTwo : classes.noActiveTabTwo} 
                style={{padding: '14px 28px 14px 36px', marginRight: 9}}
            >
                <Phone className={classes.mr18}  style={{fill: method === 'phone' ? '#ffffff' : '#8E9EC2'}} />
                <span className={classes.label} style={{color: method === 'phone' ? '#FFFFFF' : '#8E9EC2'}}>
                {translate('common.phoneUpper')}
                </span>
            </div>
            <div 
                onClick={() => {
                    setMethod('email');
                    formik.setFieldValue('email', '');
                    formik.setFieldValue('phone', '');
                    formik.setFieldValue('password', '');
                }}
                className={method === 'email' ? classes.activeTabTwo : classes.noActiveTabTwo}  
                style={{padding: active === 'registration' ? '19px 39px' : '19px 10px', width: '100%'}}
            >
                <Email className={active === 'registration' ? classes.mr18 : classes.mr5}  style={{fill: method === 'email' ? '#ffffff' : '#8E9EC2'}}  />
                <span className={classes.label2} style={{color: method === 'email' ? '#FFFFFF' : '#8E9EC2', fontSize: active === 'registration' ? 16 : 14}}>{active === 'registration' ? 'E-mail' : translate('common.emailOrLogin')}</span>
            </div>
        </div>
    )
}

export default Method;