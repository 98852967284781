import {postConfirmationPhone} from '../api/postConfirmationPhone';
import { setPostAuthorization } from './setPostAuthorization';
import { setPostRegistration } from './setPostRegistration';

export const setPostConfirmationPhone = (params, setShowModalConfirm, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postConfirmationPhone(params);
            setShowModalConfirm('');
           
            dispatch(setPostRegistration({
                login: params.phone,
                password: params.password,
                type: params.method,
                promocode: params.promocode,
                currency: params.currency,
                code: params.code,
                activeBonus: params.activeBonus,
                country: params.country
            }))

        } catch(error) {
            setError(true)
        }
    }
}