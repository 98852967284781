import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%', maxWidth: 1140, margin: '0 auto', color: '#fff'
    },
    paragraf: {
        color: 'rgba(255,255,255,0.8)!important',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const RulesTR = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>GİRİİŞ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1. Sitede hesap açarken, kulübümüzün herhangi bir bölümünü ziyaret ederken veya kullanırken (bundan böyle Web Sitesi olarak anılacaktır)
                Sitenin şart ve koşullarını, herhangi bir oyunun kurallarını, promosyon faaliyetlerinin şartlarını, bonusları, özel teklifleri kabul etmektesiniz.
                Yukarıda belirtilen tüm kurallar bundan sonra "Şartlar ve Koşullar" olarak anılacaktır. Lütfen kabul etmeden önce Şartları dikkatlice okuyun.
                Herhangi bir nedenle Koşulları kabul etmeye hazır değilseniz, bir hesap açmamanızı ve/veya Web Sitesini kullanmaya devam etmemenizi rica ederiz.
                Web Sitesini kullanmaya devam ederek, Koşulları kabul ettiğinizi belirtirsiniz.
            </p>
                
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>BAĞLANTILAR</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                2.1. Sitede bilgilendirme veya tanıtım amacıyla sağlanan tüm bağlantıları, kendi takdirinize bağlı olarak ve riski size ait olmak üzere kullanırsınız.
                Şirket, herhangi bir üçüncü taraf sitelerinin içeriğinden, sahiplerinin eylemlerinden veya ihmallerinden sorumlu değildir,
                bu sitelerdeki üçüncü taraf reklamlarının ve sponsorluklarının içeriği için. Web Sitesi diğer web sitelerine bağlantılar içerebilir,
                ayrıca Şirketin kontrolü dışında olan ve Şartlarda belirtilmeyen.
            </p>
                
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>ŞARTLARDA DEĞİŞİKLİKLER</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                3.1. Müşteri hizmetleri nedenleriyle herhangi bir Şartı düzenleme, güncelleme ve değiştirme hakkını saklı tutarız,
                ve ayrıca yasa ve yönetmeliklerdeki değişiklikler nedeniyle.
                Koşulların değiştirilmesi/güncellenmesi durumunda, tüm bilgiler Web Sitesinde sağlanacaktır.
                Oyuncu, mevcut Koşulları gözden geçirmekten sorumludur.
                Şirket, sitenin işleyişinde, yazılımda ve hizmet sağlama prosedüründe herhangi bir değişiklik yapma hakkına sahiptir.
                Oyuncuya önceden haber vermeksizin, mevcut mevzuata göre gereklilikleri değiştirmenin yanı sıra,
                Hizmetlerin erişimi ve kullanımı için yürütülmesi gerekli olan.
                <br/><br/>
                3.2. Değişiklikleri kabul etmiyorsanız hesabınızı kapatabilir ve/veya Web Sitesini kullanmayı bırakabilirsiniz,
                bu Koşulların 13. maddesini yerine getirerek.
                Revize Koşulların yürürlüğe girdiği tarihten sonra Web Sitesinin herhangi bir bölümünü kullanmaya devam ederseniz,
                bunu, (şüpheye mahal vermemek için) herhangi bir ekleme de dahil olmak üzere, revize edilmiş Koşulların kabulü ve kabulü olarak değerlendiriyoruz,
                bu Koşulların kaldırılması, değiştirilmesi veya başka şekilde değiştirilmesi.
            </p>
                
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>YASAL YÜKÜMLÜLÜKLER</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                4.1. Web Sitesi Hizmetleri, 18 yaşın altındaki kişiler tarafından kullanılamaz.
                Site hizmetlerinin 18 yaşından küçük kişiler tarafından kullanılması Şartların ihlalidir.
                Şirket, oyuncunun on sekiz yaşına ulaştığından emin olmak için herhangi bir aşamada yaşının belgesel kanıtını talep etme hakkına sahiptir.
                Şirketin, yaşıyla ilgili belgesel kanıt sunmaması durumunda hizmeti kullanmayı reddetme ve oyuncunun hesabını askıya alma hakkı vardır,
                veya Şirket, 18 yaşından küçük bir oyuncunun hizmeti kullandığından şüphelenirse.
                <br/><br/>
                4.2. Web Sitesi tarafından sağlanan hizmetleri kullanımınız kendi seçiminize ve takdirinize bağlıdır.
                Web Sitesi hizmetlerini kullanımınızın yasal olup olmadığına karar vermekten yalnızca siz sorumlusunuz.
                yetki alanınızda yürürlükte olan yasalara uygun olarak.
                Çevrimiçi kumarın bazı yargı alanlarında yasa dışı olabileceğini anlıyor ve kabul ediyorsunuz,
                ve şirketin, Web Sitesi hizmetlerini kullanımınızın yasallığı konusunda size yasal tavsiye veya garanti sağlayamaması.
                <br/><br/>
                4.3. Web Sitesindeki hizmetlerin kullanımının yürürlükteki tüm yasalara uygun olduğunu beyan, garanti ve kabul etmektesiniz,
                yetki alanınızdaki tüzük ve kurallar. gerçeği anlıyor ve kabul ediyorsun
                Şirketin, Web Sitesinin hizmetlerinin sizin tarafınızdan herhangi bir yasa dışı veya yetkisiz kullanımından sorumlu olmadığını.
                <br/><br/>
                4.4. Oyuncu, herhangi bir kazançla bağlantılı olarak geçerli tüm vergi ve ücretlerin ödenmesinden tek başına sorumludur,
                Web Sitesinin kullanılması sonucunda elde edilmiştir. Oyuncu ayrıca raporlamadan sorumludur
                kazançları ve/veya kayıpları hakkında, kazançları vergiye tabi ise ilgili mercilere
                yerel yasama, vergi veya diğer makamlar tarafından verilen herhangi bir karar ve işlem.
            </p>

            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>AÇILIŞ HESAP</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                5.1. Web Sitesinin hizmetlerini kullanmaya başlamak için bir hesap (Kayıt) açmalısınız.
                Bunun için e-posta adresinizi, telefon numaranızı belirtmeli ve bir şifre seçmelisiniz,
                adınız, soyadınız, doğum tarihiniz dahil olmak üzere kişisel bilgileri sağlamanın yanı sıra.
                Seçtiğiniz şifre sisteme giriş yapmak için kullanılacaktır.
                <br/><br/>
                5.2. Kayıt olurken gerçek adınızı belirtmelisiniz.
                Tarafınızdan sağlanan verilerin doğruluğunu teyit etmek için Şirket herhangi bir zamanda belge talep etme hakkını saklı tutar,
                kimliğinizi kanıtlamak (bunlarla sınırlı olmamak üzere: pasaportunuzun / kimlik kartınızın / veya kullanılan herhangi bir ödeme kartının bir kopyası).
                Herhangi bir nedenle bu tür bilgileri sağlayamazsanız, Şirket, bilgilerinizi askıya alma hakkına sahiptir.
                gerekli veriler sağlanana kadar hesabınızı ve / veya sağlanmamaları durumunda hesabınızı kalıcı olarak kapatın.
                <br/><br/>
                5.3. Web Sitesine kaydolarak,
                Kendiniz hakkında doğru, eksiksiz ve gerçek bilgiler vermişsiniz ve herhangi bir değişiklik olması durumunda,
                Bu bilgileri uygun şekilde güncelleyeceksiniz. Bu gereksinim karşılanmazsa
                Şirket, işlemlere, ikramiyelere kısıtlamalar uygulayabilir ve/veya hesabınızı kapatabilir. 5.4.
                Kayıt olurken herhangi bir sorunla karşılaşırsanız veya herhangi bir sorunuz varsa,
                Destek servisine "İletişim" bölümünden e-posta ile ulaşabilir veya çevrimiçi sohbete yazabilirsiniz.
                <br/><br/>
                5.5. Web Sitesinde sadece bir hesap açabilirsiniz. Açtığınız diğer hesaplar "Yinelenen Hesap" olarak değerlendirilecek ve kapatılacak ve ayrıca:
                <br/><br/>
                5.5.1. Çift Hesaptan yapılan tüm işlemler geçersiz sayılır;
                <br/><br/>
                5.5.2. Çift Hesap kullanılarak yapılan tüm bahisler veya para yatırma işlemleri iade edilir;
                <br/><br/>
                5.5.3. Aktif kullanım sırasında alınan veya yapılan herhangi bir geri ödeme, kazanç veya ikramiye
                Yinelenen hesap sizin tarafınızdan kaybedilecektir. Şirketin talebi üzerine, Mükerrer Hesaptan çekilen tüm fonları iade etmeniz istenecektir.
                <br/><br/>
                5.5.4 Çevrimiçi kumar, yargı bölgelerinde yasa dışı olabilir
                Neredesin; eğer öyleyse, bu siteye ödeme yapmak için bir ödeme kartı kullanma yetkiniz yoktur.
                <br/><br/>
            </p>

            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>KİMLİĞİNİZİ ONAYLAMAK; KARA AKLAMAYA KARŞI KORUMA GEREKLİLİKLERİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                6.1. Hizmetleri kullanmanız için Size verilen haklar göz önüne alındığında, aşağıdakileri garanti, beyan, taahhüt ve kabul etmektesiniz:
                <br/><br/>
                6.1.1. Yetki alanınızdaki yasalara göre kumar oynamak için yasal yaşa ulaştınız.
                <br/><br/>
                6.1.2. Hesabınızdaki fonların tam sahibi sizsiniz. Sağlanan tüm bilgiler
                kayıt işlemi sırasında ve/veya sonrasında, herhangi bir işlemin parçası olarak dahil olmak üzere, sizin tarafınızdan doğru, güncel,
                Kredi/banka ödeme kart(lar)ındaki veya diğer cari hesaplardaki isim(ler) ile doğru ve tam olarak tutarlı,
                bu, hesabınıza para yatırmak veya hesabınıza para yatırmak için kullanılacaktır.
                <br/><br/>
                6.1.3. Web Sitesindeki hizmetleri kullanma sürecinde para kaybedebileceğinizi kabul ediyor, anlıyor ve kabul ediyorsunuz.
                Bu Web Sitesinin hizmetlerinin kullanımıyla ilgili herhangi bir kayıptan yalnızca sizin sorumlu olduğunuzu kabul etmektesiniz.
                Hizmetleri kullanımınızın yalnızca kendi seçiminize, kararınıza ve riskinizin size ait olduğunu kabul etmektesiniz.
                Kaybı durumunda, Şirket'e karşı herhangi bir hak talebinde bulunma hakkınız yoktur.
                <br/><br/>
                6.1.4. İnternet'te hizmet sağlamak ve oynamak için genel kuralları, yöntemleri ve prosedürleri anlıyorsunuz.
                Bu bahislerin ve oyunların doğru olduğundan emin olmanın sizin sorumluluğunuzda olduğunu anlıyorsunuz.
                Şirketin itibarını zedeleyebilecek herhangi bir işlem veya davranışta bulunmamayı kabul etmektesiniz.
                <br/><br/>
                6.2. Şartları kabul ederek, Şirketin kendi takdirine bağlı olarak zaman zaman incelemeler yapabileceğini,
                ve kimliğinizi ve iletişim bilgilerinizi doğrulamak için üçüncü tarafların (düzenleyici makamlar dahil) talebi üzerine ("Doğrulama").
                <br/><br/>
                6.3. Çekler sırasında hesabınızdan para çekme olanağının Şirket tarafından sınırlandırılabileceğini kabul etmektesiniz.
                <br/><br/>
                6.4. Şirket hesabınızı derhal kapatma ve/veya reddetme hakkına sahiptir.
                Yanlış, yanlış, yanıltıcı ve/veya eksik bilgi vermeniz durumunda Web Sitesinin hizmetlerini kullanmanız.
                <br/><br/>
                6.5. 18 yaşınızı doldurduğunuzu teyit etmek mümkün değilse, Şirket hesabınızı askıya alma hakkına sahiptir.
                Web Sitesinin kumar faaliyetlerine katıldığınız sırada Kısıtlı Yaşın altındaysanız,
                böyle bir durumda, hesabınızdaki parayı size iade etmek veya başka bir şekilde tazmin etmek zorunda olmayacağız:
                <br/><br/>
                6.5.1. hesabınız kapatılacak;
                <br/><br/>
                6.5.2. Bu süre zarfında yapılan tüm işlemler iptal edilecek ve fonlar
                sizin tarafınızdan hesaba yatırılan paralar iade edilemez ve ayrıca çekilen paralar pahasına iade edilmiş sayılacaktır;
                <br/><br/>
                6.5.3. Bu süre içinde yaptığınız bahisler iptal edilir ve iade edilmez,
                ve ayrıca çekilen fonlar pahasına iade edilmiş sayılacaktır;
                <br/><br/>
                6.5.4. Kısıtlı Yaşın altında olduğunuz dönemde biriken her türlü kazanç miktarı,
                sizin tarafınızdan kaybedilecek ve talep üzerine hesabınızdan çekilen tüm parayı bize iade etmeniz istenecektir.
                <br/><br/>
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>KULLANICI ADI, ŞİFRE VE GÜVENLİK</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                7.1. Kayıt olduktan ve bir hesap açtıktan sonra, kullanıcı adınız ve şifreniz gizli kalmalıdır.
            Hesap bilgilerinizi kaybettiyseniz veya unuttuysanız, "Unuttun mu?" butonuna tıklayarak şifrenizi geri alabilirsiniz. bağlantı,
            giriş penceresinin (LOGIN) altında ve sağında bulunur.
            <br/><br/>
            7.2. Bir hesap açtıktan sonra, şifrenizin güvenliğinden tamamen siz sorumlusunuz.
            Ayrıca, hesabınızda yapılan her türlü işlem ve işlemlerden ve üçüncü kişilerin kusuru nedeniyle hesabınızda uğrayacağınız tüm zararlardan da siz sorumlusunuz.
            <br/><br/>
            7.3. Hesabınıza yetkisiz erişim ve/veya diğer herhangi bir güvenlik ihlali durumunda
            Derhal Şirkete haber vermelisiniz. Talep üzerine bu tür yetkisiz erişimlerin kanıtını sağlamayı kabul edersiniz.
            Şirket hiçbir koşulda herhangi bir zarardan sorumlu olmayacaktır.
            Kullanıcı adınızın ve şifrenizin başka bir kişi tarafından kötüye kullanılması veya Hesabınıza yetkisiz erişim nedeniyle sizin tarafınızdan,
            sizin tarafınızdan veya bilginiz dışında yetkilendirilmiş olsunlar.
        </p>

        <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>MEVDUAT, HESAPTA PARA AÇILMASI VE HESAPTAN PARA ÇEKİLMESİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                8.1. Web Sitesinde oyun oynamak için hesabınıza belirli miktarda para yatırmanız gerekir.
                <br/><br/>
                8.2. Web Sitesinde para yatırarak, x3 çevrim şartının depozito için geçerli olduğunu kabul etmiş olursunuz.
                Bu, komisyon olmadan para çekmek için yatırdığınız miktarı 3 kat aşan bahis miktarını yapmanız gerektiği anlamına gelir.
                Rulet ve kart oyunları hariç tüm oyunlarda paranızı geri kazanabilirsiniz.
                <br/><br/>
                8.3. Aşağıdakileri kabul ve taahhüt etmektesiniz:
                <br/><br/>
                8.3.1. Hesabınıza yatırdığınız paralar cezai ve/veya yasa dışı ve/veya yetkisiz kaynaktan değildir;
                <br/><br/>
                8.3.2. Daha önce yapmış olduğunuz işlemleri reddetmemeyi ve/veya tarafınızca yapılan ödemeleri reddetmemeyi/iptal etmemeyi taahhüt ettiğinizi,
                herhangi bir yasal sorumluluktan kaçınmak için üçüncü bir tarafın ters ibrazına neden olabilir.
                <br/><br/>
                8.4. Oyuncunun hesabına sadece kendi adına kayıtlı hesap/sistem ve/veya ödeme kartlarından para yatırması gerekmektedir.
                Üçüncü şahıslardan, yani arkadaşlardan, akrabalardan, ortaklardan ve/veya eşlerden gelen fonlar Şirket tarafından kabul edilmez.
                Aksi takdirde, tüm kazançlara el konulacaktır.
                <br/><br/>
                8.5. Hak sahibine geri ödeme için bir banka havalesi talebi olması durumunda, tüm banka masrafları/ücretleri alıcı tarafından karşılanacaktır.
                <br/><br/>
                8.6. Birkaç telefon numarası kullanma hakkına sahipsiniz, ancak her numaranın zorunlu olarak onaylanmasıyla,
                Hesabınızı ücretli SMS servisini kullanarak doldurursanız. Bu numara profilinizde belirtilmelidir.
                Mobil şebeke operatöründen herhangi bir şekilde kredi alınması yasaktır.
                Negatif hesap bakiyesi ile sms kredisi kullanarak sms mevduatı yapmak yasaktır.
                Bu kural ihlal edilirse, hesabınız para çekme imkanı olmadan bloke edilir.
                <br/><br/>
                8.7. Bize gönderilen parayı kabul etmiyoruz.
                Hem ödemelerinizi hem de ödemelerinizi işlemek için üçüncü taraf elektronik ödeme işlemcilerini ve/veya finansal kurumları kullanabiliriz.
                ve size yapılan ödemeler, yalnızca bu tür üçüncü tarafların hüküm ve koşulları
                elektronik ödemelerin işlenmesi ve/veya finansal kuruluşlar bu Koşulların hükümleriyle çelişmez.
                <br/><br/>
                8.8. Şirket hesabınızı bloke etme, yapılan ödemeleri iptal etme ve kazançları tahsil etme hakkını saklı tutar.
                çalıntı kredi kartlarının kullanımı ve/veya diğer faaliyetler dahil olmak üzere şüpheli veya hileli nakit para yatırma durumunda
                dolandırıcılık niteliği (her türlü geri ödeme veya ödeme iptali dahil). ilgililere bilgi verme hakkımız vardır.
                herhangi bir ödeme sahtekarlığı veya diğer yasa dışı faaliyetlerle ilgili makamlar ve/veya kuruluşlar (kredi referans kuruluşları dahil).
                Geri ödemeler için Tahsilat acenteleri kiralama hakkımız saklıdır. Şirket hiçbir koşulda
                Kredi kartının çalıntı bildirimi olsun ya da olmasın, kredi kartlarının izinsiz kullanılmasından sorumlu değildir.
                <br/><br/>
                8.9. Hesabınızdaki herhangi bir pozitif bakiyeyi, Şirkete borçlu olduğunuz herhangi bir tutardan herhangi bir zamanda mahsup edebiliriz,
                Madde 5.5, Madde 11 kapsamındaki yeniden bahisler veya bahisler dahil (sınırlama olmaksızın) ("danışma, yanıltıcı hareketler,
                dolandırıcılık ve suç faaliyeti") veya 16. paragraf ("Hatalar ve ihmaller").
                <br/><br/>
                8.10. Hesabınızın bir banka hesabı olmadığını ve dolayısıyla onunla ilgili olduğunu kabul ve beyan etmektesiniz.
                sistemlerden sigorta, garanti, ikmal veya diğer koruma araçları yoktur
                mevduat sigortası veya banka sigortasının yanı sıra benzer sigorta planları. Nakit için,
                hesabınıza yatırılır, faiz uygulanmaz. 8.11. Aşağıdaki koşulların sağlanması koşuluyla, hesabınızdan istediğiniz zaman para çekme talebi gönderebilirsiniz:
                <br/><br/>
                8.11.1. Hesabınıza aktarılan tüm ödemeler, kabul edilemez etkinlik açısından kontrol edilmiştir,
                ve hiçbir ödeme iptal edilmedi veya başka bir şekilde geri çevrilmedi;
                <br/><br/>
                8.11.2. Bölüm 6'da bahsedilen doğrulama faaliyetleri uygun şekilde yürütülmüştür;
                <br/><br/>
                8.12. Para çekmek için başvuru yaparken aşağıdaki noktaları göz önünde bulundurmalısınız:
                <br/><br/>
                8.12.1. Profilinizdeki bilgiler doldurulmalıdır; 8.12.2. Hesabınızdan sadece bu şekilde para çekebilirsiniz.
                bu ödeme sistemiyle ilgili ayrıntılarınızı doğrulama prosedürüne tabi olarak, daha önce veya başka bir şekilde kredilendirildikleri.
                <br/><br/>
                8.12.3. MasterCard düzenlemeleri nedeniyle, MasterCard kredi kartınıza para iadesi yapamayız.
                Bu nedenle MasterCard kredi kartı ile yapılan depozitolar alternatif ödeme yöntemleri kullanılarak iade edilecektir.
                <br/><br/>
                8.12.4. Talep edilen para çekme tutarı, yatırılan para miktarını aşıyorsa, Şirketin bir kimlik belirleme prosedürü gerçekleştirmesi gerekecektir,
                ve ayrıca çekim tutarı 250 ABD Doları (250 ABD Doları) eşdeğerini aşarsa. Kimlik tespiti için bize belgenizin bir fotoğrafını göndermeniz,
                pasaport veya kimlik kartı gibi kimlik belgesi. Pasaportun seri ve numarası resmin üzerine boyanabilir.
                Hesabınızı plastik bir kart kullanarak doldurduysanız, bu kartın ön ve arka yüzünün fotokopilerini de göndermelisiniz.
                Kartın ilk altı ve son dört hanesi kart numarasında görünmelidir (lütfen
                Eğer kabartmalı bir kart numaranız varsa, aynı numaralar ön yüzde olduğu gibi arka yüzde de kapatılmalıdır). CVV2 kodu da doldurulmalıdır.
                <br/><br/>
                8.12.5. Şirket, çekilen tutarın %10'unu (ancak 0,5 USD'den az olmamak üzere) ödemelerin yapılması için gider olarak tutma hakkını saklı tutar,
                hesaptaki fonların cirosu (bahis miktarı) yatırılan miktarın üç katı büyüklüğünden az ise.&nbsp;
                <br/><br/>
                8.12.6. Hesabınızdan günlük 10.000$, haftalık 20.000$ ve aylık 30.000$'a kadar para çekebilirsiniz.
                Yüksek bahisler için oynarsanız, Şirket, para çekme koşullarını lehinize değiştirme ve ödeme limitini bireysel olarak artırma hakkına sahiptir.
                <br/><br/>
                8.12.7. 30.000 ABD Doları veya daha fazla eşdeğeri kazanırsanız,
                Şirket, borcun faizi ödenmeden ödemenin tamamı ödenene kadar ödemeleri 30.000 ABD Doları tutarında aylık ödemelere bölme hakkına sahiptir.
                Aşamalı jackpotlardan elde edilen kazançlar bu koşullara tabi değildir.
                <br/><br/>
                8.12.8. Şirket, para çekme talebi yapıldıktan sonra ödeme işlemlerinde meydana gelen gecikmelerden sorumlu değildir.
                yöneticilerimiz tarafından işlenir. Para çekme talepleri haftanın yedi günü işlenir.
                <br/><br/>
                8.12.9 Kazançların ödeme süresi, çekilen para miktarına bağlıdır:
                Şirket, oyuncunun talebi üzerine anında nakit ödeme yapma hakkını saklı tutar, ancak aynı zamanda hakkı da vardır.
                8.12.6 maddesindeki koşulları kendi takdirinize bağlı olarak uygulayın. Çekim miktarı ne olursa olsun, kulüp yönetimi,
                kazançların doğrulanması. Yazılımda herhangi bir arıza olmadığından ve kazanmanın dürüstlüğünden emin olduktan sonra,
                Tüm paralar oyuncunun hesabına aktarılacaktır. Doğrulama, kazanılan miktarın geri çekilmesi için başvurunun oluşturulduğu andan itibaren 1 ila 14 gün sürebilir.
                <br/><br/>
                8.12.10 Müşteri, en az bir kez para yatırmak kaydıyla hesaptan para çekme talebinde bulunma hakkına sahiptir.
                <br/><br/>
                8.13. Para çekme maliyetlerimize eşit bir ücreti alıkoyma hakkımızı saklı tutarız.<br/><br/>
                8.14. Para çekme işlemi yapabilmeniz için depozitonuzu yatırmanız gerekir. Her depozito çift bahis yapılmalıdır.
                <br/><br/>
                8.15. 5.000 TL üzeri çekim yapılmak istenildiği zaman ödeme tutarı parçalara bölünerek yapılır. Tek seferde yapılacak olan ödeme limiti 5.000 TL olarak belirlenmiştir. Platformda / web sitemizde kazanılan ödemenin tamamı tarafınıza transfer/ havale/ EFT yoluyla gönderilecektir.
                <br/><br/>
                8.16. 5.000 TL'den 19.999 TL'ye kadar ücret müşteri talep ettiği andan itibaren 5 iş günü içerisinde ödenecektir.
                <br/><br/>
                8.17. 20.000 TL'den 49.999 TL'ye kadar ücret müşteri talep ettiği andan itibaren 10 iş günü içerisinde ödenecektir.
                <br/><br/>
                8.18. 50.000 TL'den 99.999 TL'ye kadar ücret müşteri talep ettiği andan itibaren 14 iş günü içerisinde ödenecektir.
                <br/><br/>
                8.19. 100.000 TL'den 149.999 TL'ye kadar ücret müşteri talep ettiği andan itibaren 21 iş günü içerisinde ödenecektir.
                <br/><br/>
                {/* 8.20. 500.000 ruble'den 999.999 ruble'ye kadar olan para çekme tutarı, hafta sonları ve tatil günleri hariç, başvuruların yapıldığı tarihten itibaren 21 gün içinde müşterinin hesabına ödenir.
                <br/><br/> */}
                8.20. 150.000 tl ve üstü müşteri talep ettiği andan itibaren 30 iş günü içerisinde ödenecektir. </p>
                <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SİTE ÜZERİNDEKİ OYUN KURALLARI VE BAHİS</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                9.1. Oyun sırasında işleminizi onaylamadan önce, yaptığınız herhangi bir işlemin detaylarının doğru olduğundan emin olun. Bundan siz sorumlusunuz.
            <br/><br/>
            9.2. İşlemlerinizin geçmişine Web Sitesindeki "Profil"e giriş yaparak ulaşabilirsiniz.
            <br/><br/>
            9.3. Herhangi bir işlemi gerçekleştirmeyi kısmen veya tamamen (tamamen kendi takdirimize bağlı olarak) reddetme hakkımızı her zaman saklı tutarız,
            Şartları ihlal ettiyseniz, Web Sitesi aracılığıyla tarafınızca talep edilen Bizden onay alana kadar hiçbir işlem kabul edilmiş sayılmaz.
            İşleminizin kabul edildiğine dair bir onay almadıysanız, desteğe başvurmanız gerekir.
            <br/><br/>
            9.4. Destek ekibine bir istek göndererek para çekme işlemini istediğiniz zaman iptal edebilirsiniz.
            <br/><br/>
            9.5. Bir işlemin iptali, iptal onayı alındıktan sonra yürürlüğe girer. İşlem iptal talebinin zamanında ulaşmaması ve/veya işleme koyulmaması durumunda,
            İşleminizin işlemde kalabileceğini ve kabul için hazır olabileceğini kabul edersiniz.
        </p>

            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>MÜDAHALE, ORTA DAVALAR, DOLANDIRICILIK VE CEZA FAALİYETLERİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                10.1. İzin verilmedi:
            <br/><br/>
            10.1.1. Üçüncü taraflara bilgi sağlanması;<br/><br/>
            10.1.2. Kötü amaçlı yazılım kullanımı, yazılımımızdaki hatalar, otomatik oynatıcıların ("botlar") kullanımı dahil olmak üzere dolandırıcılık;<br/><br/>
            10.1.3. Hesabınıza para yatırmak için yasa dışı yollarla elde edilmiş kredi veya banka kartı bilgilerini kullanmak;<br/><br/>
            10.1.4. Cezai sonuçları olan herhangi bir suç faaliyetine katılım;<br/><br/>
            10.1.5. Web Sitesinde oynarken başka bir oyuncuyla doğrudan veya dolaylı olarak herhangi bir gizli anlaşmaya girmek veya gizli anlaşmaya teşebbüs etmek ve/veya bu anlaşmaya girmek niyetinde olmak.<br/><br/></p><p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                10.2. Şirket, muvazaaları ve katılımcılarını tespit etmenin yanı sıra hariç tutmak için tüm makul önlemleri alacaktır. Bunlara karşı gerekli işlem yapılacaktır. Gizli, hileli faaliyetler ve diğer yasa dışı operasyonlar veya aldatma sonucunda sizin veya başka bir oyuncunun maruz kaldığı zararlardan sorumlu değiliz.
            <br/><br/>
            10.3. Bir kişinin gizli anlaşma yaptığından veya dolandırıcılık faaliyetinde bulunduğundan şüpheleniyorsanız, mümkün olan en kısa sürede bizi e-posta ile bilgilendirmelisiniz.
            <br/><br/>
            10.4. Hizmetlerin ve/veya yazılımın herhangi bir yasa dışı veya hileli faaliyet için kullanılması yasaktır. Şirket, herhangi bir zamanda sizi ve sistemdeki diğer hesapları askıya alma veya bloke etme ve sahtekarlık tespiti durumunda para kesintisi yapma hakkını saklı tutar.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SİTE ÜZERİNDEKİ DİĞER YASAK İŞLEMLER</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                11.1. Web Sitesinin hem oyuncuları hem de çalışanları ile ilgili olarak küfür, tehdit, aşağılama kullanılmasına izin verilmez.
            <br/><br/>
            11.2. Herhangi bir şekilde sitenin işleyişini etkileyebilecek herhangi bir işlem yapılması yasaktır. Virüs veya benzeri kötü amaçlı programların dağıtılması, herhangi bir toplu bilgi gönderimi yasaktır. Web Sitesinde bulunan herhangi bir bilgiyi bozmak, silmek veya başka bir şekilde değiştirmek yasaktır.
            <br/><br/>
            11.3. Sitenin ticari amaçlarla kullanılması, site yönetiminden önceden izin alınmadan herhangi bir bilginin kopyalanması yasaktır.
            <br/><br/>
            11.4. Güvenlik sistemini hacklemek, erişim sağlamaya çalışmak yasaktır. Bu tür eylemlerde bulunulması durumunda, hesabınızı derhal bloke etmek ve siteye erişimi kapatmak zorunda kalacağız ve ayrıca ilgili makamları bu konuda bilgilendirmek zorunda kalacağız.
            <br/><br/>
            11.5. Web Sitesini kullanırken ve/veya Web Sitesinde yayınlanan herhangi bir materyali ve/veya Web Sitesinde yer alan herhangi bir bağlantıyı indirirken virüsler, saldırılar, bilgi teknolojisi araçlarının arızalanmasından kaynaklanan maddi kayıplardan Şirket sorumlu değildir ve zararları tazmin etmez. .site.
            <br/><br/>
            11.6. Oyuncular arasında hesap satmak veya transfer etmek veya başka bir oyuncuya chip transfer etmek için kasıtlı olarak chip kaybetmek yasaktır. Başka bir kullanıcıya para transfer etmek için kasten kaybettiğinizde fişlerin kasıtlı kaybı meydana gelir.
            <br/><br/>
            11.7. Siteyi Proxy sunucuları kullanarak ziyaret etmek ve ayrıca VPN (Sanal Özel Ağ) kullanmak yasaktır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SÖZLEŞMENİN SÜRESİ VE İPTALİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                12.1 Şirket, aşağıdaki durumlarda hesabınızı (kullanıcı adı ve şifre dahil) önceden haber vermeksizin feshedebilir:
            <br/><br/>
            12.1.1 Herhangi bir nedenle, genel olarak veya size özel hizmet vermeyi durdurmaya karar verdik;<br/><br/>
            12.1.2. Hesabınız silinmiş bir hesaba herhangi bir şekilde bağlıysa.<br/><br/>
            12.1.3. Hesabınız mevcut engellenen hesaplara bağlıysa.&nbsp;<br/><br/>
            12.1.4. Sistemi gizliyor veya hacklemeye çalışıyorsunuz;<br/><br/>
            12.1.5. Yazılımı kurcalıyorsunuz;<br/><br/>
            12.1.6. Hesabınızı, yürürlükteki yasalara göre yasa dışı sayılabilecek amaçlar için kullanıyorsunuz. Örneğin, Web Sitesine kumarın yasak olduğu bir yargı alanından erişmeye çalışıyorsanız;<br/><br/>
            12.1.7. Web Sitesinde rahatsız edici içerik yayınlıyorsunuz;<br/><br/>
            12.2. Hesabınız uzun bir süre boyunca (altı ay veya daha fazla) hareketsiz kalırsa, hesabınızı önceden haber vermeksizin kapatabilir veya askıya alabiliriz. Bu tür bir hesabın kapatılması durumunda, Koşullar söz konusu fesih tarihinden itibaren otomatik olarak feshedilecektir.<br/><br/>
            12.3. Şirket, iletişim bilgilerinde belirtilen adrese elektronik bir bildirim (veya önceden bildirim) göndererek hesabınızı kapatabilir ve Koşulları feshedebilir. Tarafımızdan bu tür bir iptal durumunda, Koşulların bu şekilde kapatılması ve feshedilmesinin 10. madde ("Hile, Aldatıcı Uygulama, Dolandırıcılık ve Suç Faaliyetleri") veya 17. Madde ("Şartların İhlali" ile tutarlı olması dışında ) bu Şartların, hesabınızın bakiyesini iade edeceğiz. Sizinle iletişime geçemezsek, fonlar Şirkete veya denetim makamına aktarılacaktır.<br/><br/>
            12.4.1 Yüz seksen (180) gün boyunca ("Ek Dönem") bir Oyuncu Hesabından bahis oynamadıysanız, Hesabınız Aktif Değil olarak kabul edilecektir.<br/><br/>
            12.4.2 Hesabınızın etkin olmadığı kabul edildiğinde, bir çek hesabının bakımı için Sizden bir ücret talep etme hakkımız olacaktır ('Etkin Olmayan Hesap Ücreti'). Yetkisiz Hesap Ücreti Çizelgesi uyarınca, Yetkisiz Dönem'in son gününden başlayarak ve daha sonra her otuz (30) günde bir oyun bakiyenizden Etkin Olmayan Hesap Ücretine eşit bir tutarı düşebiliriz. Hesabınız arka arkaya on iki aya eşit bir süre boyunca bizim tarafımızdan etkin olmayan bir hesap olarak tanınmaya devam ederse, paranızın güvenliğinin garantisi olarak, Oyuncu Hesabınızdaki herhangi bir bakiyeyi alıkoyabilir ve Oyuncu Hesabınızı kapatabiliriz. . En son Bahis oynadığınızdan sonraki 5 yıl içinde, ödenmesi gereken hizmet ücretleri düşüldükten sonra kalan bakiyenizi talep etmek için bizimle iletişime geçebilirsiniz. <br/><br/>
            12.4.3 Hesabınıza bahis yatırılırsa ve hesabınız yeniden etkinleştirilirse veya 13.1 maddesi uyarınca hesabınız kapatılırsa, Aktif Olmayan Hesap Ücretini Oyuncu Hesabınızdan düşmeyi durduracağız. <br/><br/>
            12.4.4 Etkin Olmayan Hesap ücreti, aylık 5 ABD Dolarına eşittir.<br/><br/>
            12.4.5 Her türlü komisyon ve diğer cezaların miktarları zaman zaman değişebilir.<br/><br/></p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SİTE DEĞİŞİKLİKLERİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                13.1. Şirket, Web Sitesini sürdürmek ve güncellemek için herhangi bir zamanda Web Sitesinde sunulan herhangi bir hizmette değişiklik veya ilave yapma hakkına sahiptir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SİSTEM HATALARI</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>14.1. Sistemde herhangi bir arıza veya oyunda bir hata (herhangi bir nedenle oyun mantığının normal işleyişinden sapma) olması durumunda Şirket en kısa sürede durumu düzeltecektir. Şirket, sizin veya diğer oyuncuların Web Sitesine erişmek için kullandığı ekipmanın çalışmasından kaynaklanan herhangi bir bilgi teknolojisi arızasından ve ayrıca İnternet servis sağlayıcınızın veya diğer oyuncuların İnternet servis sağlayıcısının çalışmasındaki arızalardan sorumlu değildir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>HATALAR VEYA ARIZALAR</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                15.1. Web Sitesinin hizmetlerini kullanma sürecinde, Şirket tarafından bir bahsin kabul edilmesi veya ödemenin hatalı yapılması (örneğin, sonuç olarak bizim tarafımızdan oyun bahislerinin koşullarının yanlış ayarlanması) bazı durumlar ortaya çıkabilir. bilgi girerken bariz bir hata veya eksiklikten veya bir bilgisayar arızasının bir sonucu olarak veya manuel veya otomatik olarak yanlış veri girişinin bir sonucu da dahil olmak üzere sizin tarafınızdan kazanılan/iadelerin sayısını hesaplarken tarafımızdan yapılan bir hata). 15.2. Şirket herhangi bir bahsi sınırlayabilir veya iptal edebilir.
            <br/><br/>
            15.3. Hesabınıza yatırılan veya yanlışlıkla size aktarılan paraları sonraki bahisleri yapmak veya bir oyuna katılmak için kullandıysanız, bu tür bahisleri ve/veya bu tür paraları kullanarak elde edebileceğiniz kazançları iptal edebiliriz ve eğer bu tür bahisler veya oyunlar için size zaten para ödemişsek, bu tutarlar size emanet edilmiş olarak kabul edilecek ve talebimiz üzerine bunları derhal bize iade etmeniz gerekecektir.
            <br/><br/>
            15.4. Şirket (çalışanlarımız veya acentelerimiz dahil), ortaklarımız veya tedarikçileriniz, sizin veya bizim tarafımızdan kaynaklanan bir hatadan kaynaklanan kazanç kaybı da dahil olmak üzere hiçbir zarardan sorumlu olmayacaktır.
            <br/><br/>
            15.5. Şirket ve lisans sahipleri, distribütörleri, yan kuruluşları, iştirakleri ve tüm çalışanları ve yöneticileri, İnternet üzerinden iletilen herhangi bir bilginin ele geçirilmesi veya kötüye kullanılmasından kaynaklanabilecek herhangi bir kayıp veya zarardan sorumlu tutulamaz.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SORUMLULUĞUMUZUN SINIRLANDIRILMASI</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                16.1. Web Sitesini kullanıp kullanmama seçiminin tamamen size bağlı olduğunu ve bunu tamamen kendi seçiminize, takdirinize ve riski size ait olduğunu anlıyor ve kabul ediyorsunuz.
                <br/><br/>
                16.2. Web Sitesinin işleyişi, bu Web Sitesinde açıklanan Hüküm ve Koşullara tabidir. Web Sitesi veya Web Sitesinde sunulan hizmetler ile ilgili olarak başka hiçbir beyanda bulunmamakta veya garanti vermemekteyiz ve bu vesile ile tüm zımni garantiler için sorumluluğumuzu (yasaların izin verdiği ölçüde) hariç tutmaktayız. ŞARTLARIN İHLALİ 17.1. Şartları ihlal etmenizin bir sonucu olarak ortaya çıkabilecek her türlü talep, yükümlülük, maliyet veya gider (yasal ücretler dahil) ve diğer tüm masraflar için bizi tam olarak tazmin edeceğinizi anlıyor ve kabul ediyorsunuz.
                <br/><br/>
            17.2. Şirketin, marka olmayan ortaklarının ve ilgili şirketlerinin ve ayrıca ilgili memurlarının, yöneticilerinin ve çalışanlarının çıkarlarını her türlü iddia, talep, sorumluluk, zarar, kayıptan tamamen tazmin etmeye, savunmaya ve savunmaya hazır olduğunuzu onaylarsınız. , masraflar ve masraflar, yasal masraflar dahil olmak üzere ve aşağıdakilerden dolayı herhangi bir nedenle yapılan diğer masraflar: 17.2.1. Kullanım Koşullarını ihlal etmeniz;<br/><br/>
            17.2.2. Yasaları veya üçüncü şahısların haklarını ihlal etmeniz;<br/><br/>
            17.2.3. Hizmetlere erişiminizi izniniz olsun veya olmasın başka bir kişi tarafından kullanmak veya bu şekilde elde edilen kazançları kabul etmek.<br/><br/>
            17.3. Şartları ihlal ederseniz, hakkımızı saklı tutarız ancak aşağıdakileri yapmakla yükümlü değiliz: <br/><br/>
            17.3.1. Şartları ihlal ettiğinize dair bir bildirim (iletişim bilgilerinizi kullanarak) göndererek, ihlale son vermenizi talep etmek;<br/><br/>
            17.3.2. Web Sitesinde bahis oynamanızı veya oyun oynamanızı engellemek için Hesabınızı askıya almak;
            17.3.3. Hesabınızı Engelleyin;<br/><br/>
            17.3.4. Herhangi bir ciddi ihlal sonucunda elde ettiğiniz bonusları veya kazançları geçersiz kılmak;<br/><br/>
            17.4. Şartların herhangi bir hükmüne uymazsanız, Şirket kullanıcı adınızı ve şifrenizi iptal etme hakkına sahiptir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>FİKRİ MÜLKİYET HAKLARI</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                18.1. Web Sitesinin içeriği, Şirkete ait olan veya üçüncü şahıs telif hakkı sahiplerinin lisansı altında kullanılan telif hakkı ve diğer mülkiyet haklarına tabidir. Web Sitesinde yayınlanan herhangi bir materyalin ticari amaçlarla kullanılması yasaktır.
            <br/><br/>
            18.2. Web Sitesinin kullanımı, kullanıcıya Şirketin veya başka herhangi bir üçüncü şahsın sahip olduğu fikri mülkiyet hakları (telif hakları, know-how veya ticari markalar gibi) vermez.
            <br/><br/>
            18.3. Web Sitesinde görüntülenen ticari unvan, ticari marka, logo veya diğer yaratıcı materyallerin kullanılması veya çoğaltılması yasaktır.
            <br/><br/>
            18.4. Yasaklanmış herhangi bir faaliyetin komisyonundan kaynaklanan veya bunlarla bağlantılı olarak ortaya çıkan her türlü zararı, maliyeti veya masrafı tazmin etmeyi kabul edersiniz. Herhangi bir kişi tarafından yasaklanmış herhangi bir faaliyeti Şirkete bildirmeyi, Şirkete gerekli yardımı sağlamayı ve bu konuyla ilgili sahip olduğunuz tüm bilgileri sağlamayı kabul etmektesiniz.<br/><br/></p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>KİŞİSEL BİLGİLERİN</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                19.1. Şirkete kişisel bilgiler vererek, kişisel verilerinizin site yönetimi tarafından Koşullarda açıklanan amaçlarla işlenmesini kabul etmiş olursunuz.
            <br/><br/>
            19.2. Oyuncuların kişisel bilgilerini, hizmet sağlamak için verilere erişmesi gereken çalışanlar dışında hiç kimseye ifşa etmeyiz.
            <br/><br/>
            19.3. Şirket, sizden alınan tüm bilgileri doğru bir şekilde kaydetmek için sizden alınan tüm yazışmaları (tüm e-postaların kopyaları dahil) saklar.
            <br/><br/>
            19.4. Şirket, kullanıcı tarafından kayıt sırasında belirtilen e-posta adresine ve SMS (SMS) bilgilendirme yazıları gönderme hakkına sahiptir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>WEB SİTESİNDE ÇEREZ KULLANIMI</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                20.1. Web Sitesinin işlevselliğini sağlamak için Şirket, çerezleri kullanır. Çerezleri silme veya kontrol etme hakkında daha fazla bilgiyi www.aboutcookies.org adresinde bulabilirsiniz. Şirket tanımlama bilgilerinin silinmesi veya gelecekte bilgisayarınızda saklanmasını önlemek için adımlar atılması, Web Sitesinin belirli alanlarına veya özelliklerine erişilememesiyle sonuçlanabilir. ŞİKAYET VE BİLDİRİMLER 21.1. Web Sitesi ile ilgili bir talepte bulunmak için lütfen destek ekibimizle iletişime geçin.
            <br/><br/>
            21.2. Sunucudaki kayıtların, herhangi bir anlaşmazlığın sonucunun belirlenmesinde nihai kanıt olarak hareket edeceğini anlıyor ve kabul ediyorsunuz.
            <br/><br/>
            21.3. Tüm oyunların sonuçlarını kabul ediyorsunuz ve sonucun rastgele sayı üreteci tarafından belirlendiğini kabul ediyorsunuz. Ekranınızda görüntülenen bilgiler hesabınızdaki bakiyeyle eşleşmezse, Şirket sunucusundaki bakiye belirleyici kabul edilir. YORUM 22.1. Şartların orijinal metni Rusça yazılmıştır, bu nedenle Rusça sürüm önceliklidir. Şartlar metninin herhangi bir yorumu, orijinal Rusça metne dayanmalıdır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>HAK VE YÜKÜMLÜLÜKLERİN DEVRİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>23.1. Şirket, Şartları tamamen veya kısmen herhangi bir kişiye (sizin onayınız olmadan) devredebilir, devredebilir ve alt lisansını verebilir veya rehin verebilir, ancak bu devir aynı şartlar veya sizin için daha az lehte olmayan şartlarda olacaktır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Mücbir Sebep Durumları</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>24.1. Şirket, doğal afetler, savaş, sivil kargaşa, kamu iletişim ağlarında veya hizmetlerinde kesintiler de dahil olmak üzere mücbir sebeplerin neden olduğu Koşullar kapsamındaki yükümlülüklerimizden herhangi birinin yerine getirilmemesi veya yerine getirilmesinde gecikme olması durumunda sorumlu olmayacaktır. , endüstriyel anlaşmazlıklar veya DDOS saldırıları ve olumsuz sonuçları olabilecek benzer İnternet saldırıları (“Mücbir Sebep”).
            <br/><br/>
            24.2. Mücbir sebep süresine eşit bir süre için, yükümlülüklerin yerine getirilmesinde bir gecikme vardır. Şirketin Mücbir Sebep süresince faaliyetleri durdurulmuş sayılır. Şirket, Mücbir Sebebi durdurmak veya Mücbir Sebebe rağmen yükümlülüklerini yerine getirebileceği bir çözüm aramak için mümkün olan tüm kaynakları kullanacaktır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SORUMLULUK REDDİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                25.1. Şirketin, yükümlülüklerinizden herhangi birine tam olarak uymanızı sağlamaması veya Şirketin sahip olduğu hak veya çözüm yollarından herhangi birini kullanmaması durumunda, bu, söz konusu haklardan veya çözüm yollarından feragat edildiği anlamına gelmez ve sizi yükümlülüklerinizden kurtarmaz.
            <br/><br/>
            25.2. Şirketin Şartların herhangi bir yükümlülüğünü yerine getirmeyi reddetmesi, resmileştirilmedikçe ve yukarıda belirtilenlere uygun olarak yazılı, elektronik veya sözlü olarak size iletilmediği sürece yasal olarak geçerli olmayacaktır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SÖZLEŞMENİN BÖLÜNMESİ</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>26.1. Şartlardan herhangi biri uygulanamaz hale gelirse, "koşul" veya "koşul" terimleri, yasaların öngördüğü şekilde tam olarak yürürlükte ve yürürlükte kalacak olan kalan şartlar, koşullar ve dilden uygun ölçüde ayrılacaktır. Bu gibi durumlarda, geçersiz sayılan kısım, maksimize etmek için yürürlükteki kanuna göre değiştirilir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>MEVZUAT VE YARGI</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                27.1 Bu Kurallar, Kıbrıs Cumhuriyeti yasalarına tabi olacak ve bu yasalara göre yorumlanacaktır ve Oyuncu, herhangi bir anlaşmazlığı (tazminat talepleri dahil olmak üzere) çözmek için Kıbrıs Cumhuriyeti mahkemelerinin münhasır (tek) hakkını geri alınamaz bir şekilde kabul eder. ve karşı iddialar) oluşturma, yasallık, etki, yorum veya eylem veya Kurallar tarafından kurulan hukuki ilişki veya herhangi bir şekilde Kurallardan kaynaklanan hukuki ilişki ile bağlantılı olarak ortaya çıkabilecek.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>PAZARLAMA VE ANALİTİK</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                28.1. ACTIV AGUA ALIANZA LIMITADA, Siteyi, bölümlerini, sayfalardaki içeriğini ve oyunlardaki kullanıcı deneyimini geliştirmek için web işaretçileri, pikseller, izleme komut dosyaları ve çerezler kullanır. Aynı zamanda ACTIV AGUA ALIANZA LIMITADA, Siteye yapılan ziyaretleri ve oyunlardaki etkinlikleri kaydeder; Oyuncunun ne zaman ve hangi sayfaları, bölümleri ziyaret ettiği; Oyuncunun Siteye en son ne zaman eriştiği; Sitede yetkilendirme zamanı; Oyuncunun oynadığı oyunlar; Oyuncunun promosyon malzemeleri ve pazarlama teklifleriyle nasıl etkileşime girdiği. ACTIV AGUA ALIANZA LIMITADA'nin bu izleme amacıyla kullandığı teknolojilerin bazıları, sırasıyla Gizlilik Politikasına tabi olan ve kendi Gizlilik Politikalarını uygulayan üçüncü taraflarca sağlanmaktadır.<br/><br/>
            28.2. Oyuncuya kârlı kişiselleştirilmiş promosyonlar, promosyon malzemeleri, promosyonlar, haberler ve ayrıca oyun hesabının durumu veya önemli olaylar hakkında bildirimler sağlamak için ACTIV AGUA ALIANZA LIMITADA, e-posta, SMS yoluyla bildirim göndermek için Oyuncunun e-posta adresini ve telefon numarasını kullanabilir. veya aramaların yanı sıra Sitede veya reklam ağındaki üçüncü taraf sitelerde reklam afişlerinin ve reklamların görüntülenmesi (yeniden pazarlama).<br/><br/>
            28.3. ACTIV AGUA ALIANZA LIMITADA, Oyuncunun mahremiyet hakkına saygı duyar, bu nedenle ACTIV AGUA ALIANZA LIMITADA, pazarlama tekliflerini ve bildirimlerini almayı reddetmek için kolay bir yol sağlar. Oyuncu, herhangi bir bildirim almayı devre dışı bırakmak veya bu konuda daha fazla bilgi edinmek için e-posta yoluyla desteğe başvurabilir. Oyuncunun pazarlama tekliflerini tamamen veya kısmen reddetmesi durumunda, Oyuncu ilgili ve faydalı bilgileri alamayacaktır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SORUMLU OYUN</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                29.1. Sitede sunulan oyunlar eğlence amaçlıdır. Oyuncu, kumarın para kazanmanın veya borçlarını kapatmanın bir yolu olmadığını anlamalıdır. ACTIV AGUA ALIANZA LIMITADA, çevrimiçi kumarhanelerde harcanan zaman ve para miktarını günlük olarak takip etmenizi önerir.<br/><br/>
            29.2. Oyuncu, karşılayabileceğinden daha fazla para harcamaya başladığını veya oyunun normal yaşam tarzına müdahale etmeye başladığını düşünüyorsa, Oyuncu aşağıdaki önlemleri alabilir:
            Oyunda geçici yasaklama
            Oyuncu, destek ile e-posta yoluyla iletişime geçebilir ve ACTIV AGUA ALIANZA LIMITADA'ye Hesabını geçici olarak askıya alma kararını bildirebilir. ACTIV AGUA ALIANZA LIMITADA, Oyuncunun Siteye erişimini engellemek ve tüm reklam postalarını durdurmak için tüm önlemleri alacaktır.
            Güvenli ve sorumlu oyun oynamayı sağlamak için ACTIV AGUA ALIANZA LIMITADA, Oyuncunun kendisini kontrol etmesine ve yalnızca karşılayabileceği parayla oynamasına izin veren bir dizi araç sunar. Oyuncu şunları yapabilir: depozitoyu sınırlayabilir, kaybı sınırlayabilir, bahis sınırını değiştirebilir.</p>
            <p style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif',color: 'gold'
            }}><b>bağımsız yardım</b></p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>Oyuncu, tavsiye ve destek için aşağıdaki kuruluşlardan herhangi biriyle iletişime geçebilir:</p>
            <ul style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
            <li>Gambling Anonymous</li>
            <li>GamCare</li>
            <li>Gambling Therapy</li>
            </ul>
            <b style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif',color: 'gold'
            }}>küçüklerin korunması</b>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>ACTIV AGUA ALIANZA LIMITADA yalnızca reşit yaştaki oyuncuları kabul eder ve reşit olmayanların Siteye kaydolmamasını veya Sitede oynamamasını sağlamak için mevcut tüm yöntemleri kullanır. Aynı zamanda, internete açık erişim nedeniyle, reşit olmayan oyuncular, aldatma ve bilgi sahtekarlığı yoluyla çevrimiçi kumarhanelerde oynama fırsatına sahiptir. Bu nedenle, ACTIV AGUA ALIANZA LIMITADA ebeveynleri çocuklarına erişimi engellemeye yardım etmeye çağırıyor. İlgili bilgiler aşağıdaki web sitelerinde bulunabilir:</p>
            <ul style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
            <li><a href="http://www.cyberpatrol.com/" target="_blank" rel="noopener noreferrer" style={{color: 'rgba(255,255,255, 0.8'}}>http://www.cyberpatrol.com/</a></li>
            <li><a href="http://www.gamblock.com/" target="_blank" rel="noopener noreferrer"style={{color: 'rgba(255,255,255, 0.8'}}>http://www.gamblock.com/</a></li>
            <li><a href="http://www.solidoak.com/" target="_blank" rel="noopener noreferrer" style={{color: 'rgba(255,255,255, 0.8'}}>http://www.solidoak.com/</a></li>
            <li><a href="http://www.netnanny.com/" target="_blank" rel="noopener noreferrer" style={{color: 'rgba(255,255,255, 0.8'}}>http://www.netnanny.com/</a></li>
            </ul>
    </div>
    )
}

export default RulesTR;