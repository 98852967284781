import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    field: {
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        outline: 'none',
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        padding: '15px 19px',
        width: '93%',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    title: {
        margin: 0, 
        padding: 0, 
        marginBottom: 50,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 30,
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF'
    },
    root: {
        paddingTop: 108, 
        paddingBottom: 100, 
        maxWidth: 1140, 
        width: '100%',
        margin: '0 auto',
    },
    wrapTitle: {
        display: 'flex', 
        justifyContent: 'center',
    },
    wrapForm: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',
    },
    form: {
        overflow: 'hidden',
        width: '100%'
    },
    wrapLabel: {
        display: 'flex', 
        width: '100%', 
        columnGap: 10, 
        marginBottom: 12,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    w50: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    wrapBtn: {
        marginTop: 20,  
        display: 'flex', 
        justifyContent: 'flex-end'
    },
    wrapTextArea: {
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 30px)'
        }
    },
    wrapRoot: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px'
        }
    }
}))

export {
    useStyles
}