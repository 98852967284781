import React from 'react';
import Label from '../../../../../@casino/ui/label';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    wrapLabel: {
        display: 'flex',
        justifyContent: 'center',
    },
    gamegame: {
        position: 'absolute', 
        top: 0, 
        width: 210, 
        height: 141, 
        background: 'rgba(0,0,0,.5)', 
        zIndex: 100, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity .3s ease',
        '&:hover': {
            opacity: 1,
            transition: 'opacity .3s ease',
        }
    },
}))

const DescriptionTournament = ({tournament}) => {
    const classes = useStyles();

    let month = [
        translate('month.jan'), 
        translate('month.feb'), 
        translate('month.mar'), 
        translate('month.mar'), 
        translate('month.may'), 
        translate('month.jun'), 
        translate('month.jul'), 
        translate('month.avg'), 
        translate('month.sep'), 
        translate('month.oct'), 
        translate('month.nov'), 
        translate('month.dec')
    ];

    return (
        <>
            <div className={classes.wrapLabel}>
                <Label title={translate('tournament.description')} />
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    lineHeight: '24px',
                    userSelect: 'none',
                    color: '#fff',
                    textAlign: 'center'
                }}>
                    {tournament.tournament.description}
                </span>
            
                <span  style={{
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 700,
                    lineHeight: '24px',
                    userSelect: 'none',
                    color: '#fff',
                    textAlign: 'center',
                    marginTop: 60,
                }}>{translate('tournament.startTo')} 
                    <span style={{color: '#FF005C', fontSize: 35}}>
                        03:00 {new Date(+tournament.tournament.date_from).getDate() < 10 ? '0' + new Date(+tournament.tournament.date_from).getDate() : new Date(+tournament.tournament.date_from).getDate()} {month[new Date(+tournament.tournament.date_from).getMonth()]}
                    </span>  
                    {translate('tournament.endTo')} <span style={{color: '#FF005C', fontSize: 35}}>03:00 {new Date(+tournament.tournament.date_to).getDate() < 10 ? '0' + new Date(+tournament.tournament.date_to).getDate() : new Date(+tournament.tournament.date_to).getDate()} {month[new Date(+tournament.tournament.date_to).getMonth()]}</span></span>
                <span style={{
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    lineHeight: '24px',
                    userSelect: 'none',
                    color: '#fff',
                    textAlign: 'center',
                    marginTop: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>{translate('tournament.prizefond')}&nbsp;&nbsp; — <span style={{color: '#FFBF39', fontSize: 50, fontWeight: 700}}>&nbsp;{tournament.tournament.fund}</span></span>
            
                <h3 style={{
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                    fontWeight: 400,
                    lineHeight: '24px',
                    userSelect: 'none',
                    color: '#fff',
                    textAlign: 'center',
                    marginTop: 60,
               
                }}>{translate('tournament.howToWin')}</h3>
                <nav style={{display: 'flex', flexDirection: 'column', marginBottom: 70}}>
                    <span style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '34px',
                        userSelect: 'none',
                        color: '#fff',
                        textAlign: 'center',
                    }}>— {translate('tournament.openRandomGame')}.</span>
                    <span  style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '34px',
                        userSelect: 'none',
                        color: '#fff',
                        textAlign: 'center'
                    }}>— {translate('tournament.quafilication')} — <span style={{color: '#FF005C', fontSize: 30, fontWeight: 700}}>{tournament.tournament.count_spin} {translate('tournament.spin')}</span> {translate('tournament.bet')} <span style={{color: '#FF005C', fontSize: 30, fontWeight: 700}}>{tournament.tournament.min_bet}</span></span>
                    <span  style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '34px',
                        userSelect: 'none',
                        color: '#fff',
                        textAlign: 'center'
                    }}>— {translate('tournament.qualifcationBefore')} <span style={{color: '#FF005C', fontSize: 30, fontWeight: 700}}>{tournament.tournament.min_bet}</span> {translate('tournament.qualifcationBeforeTo')}.</span>

                    <span  style={{
                        fontSize: '18px',
                        fontStyle: 'normal',
                        fontFamily: 'Montserrat, sans-serif',
                        fontWeight: 400,
                        lineHeight: '34px',
                        userSelect: 'none',
                        color: '#fff',
                        textAlign: 'center'
                    }}>— {translate('tournament.prizer')} <span style={{color: '#FFBF39', fontSize: 30, fontWeight: 700}}>{tournament.tournament.count_winners} {translate('tournament.prizerCount')}</span> {translate('tournament.what')}.</span>    
                

                </nav>

            
            </div>
        </>
    )
}

export default DescriptionTournament;