import React from 'react';
import {useSelector, useDispatch } from 'react-redux';
import WinPageBlock from '../../../../@casino/ui/WinPageBlock';
import Score from '../components/score';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ScoreMobile from '../components/scoreMobile';
import {translate} from '../../../../dictionaries';
import ChangePasswordDesktop from '../components/changePasswordDesktop';
import ChangePasswordMobile from '../components/changePasswordMobile';

const useStyles = makeStyles((theme) => ({
    desktop: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }
    },
    field: {
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        }
    },
    savebtn: {
        padding: '10px 40px', 
        background: '#FF005C', 
        borderRadius: '50px',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        userSelect: 'none',
        cursor: 'pointer'
    }
}))

const Account = () => {
    document.title = translate('title.account')
    const profile = useSelector(state => state.profile.view);
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <div>
            <WinPageBlock
                title={translate('navigation.account')}
                description={translate('navigation.accounts')}
            >
                <div className={classes.desktop}>
                    <Score profile={profile}/>  
                    <ChangePasswordDesktop />
                </div>
                <div className={classes.mobile}>
                    <ScoreMobile profile={profile} />
                    <ChangePasswordMobile />
                </div>        
            </WinPageBlock>
        </div>
        
    )
}

export default Account;