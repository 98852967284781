import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import TextFieldEmail from '../../../../../../../@casino/ui/TextFieldEmail';
import TextFieldPhone from '../../../../../../../@casino/ui/TextFieldPhone';
import TextFieldPassword from '../../../../../../../@casino/ui/TextFieldPassword';
import TextField from '../../../../../../../@casino/ui/TextField';
import Tabs from '../../../tab';
import Method from '../../../method';
import {useStyles, getValidation} from '../../helper';
import { translate } from '../../../../../../../dictionaries';


const MethodUse = ({formik, setError, setErrorRegistration, error, errorRegistration}) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapBody}>
            <Tabs 
                formik={formik} 
                activeTab={formik.values.active} 
                setActiveTab={(v) => {
                    formik.setFieldValue('active', v)
                   
                    setError(false);
                    setErrorRegistration(false);
                }} 
                setMethod={(v) => {
                    formik.setFieldValue('method', v);
                }}
            />
                <div style={{marginTop: 10}}>
                    {
                        formik.values.active !== 'registration' &&
                        <>
                            <div className={classes.mb12}>
                                <span className={classes.description}>{translate('common.methodUsed')}</span>
                            </div>
                            <Method 
                                method={formik.values.method} 
                                setMethod={(v) => {
                                    formik.setFieldValue('method', v)
                                    setError(false);
                                    setErrorRegistration(false);
                                }} 
                                formik={formik}
                            />
                        </>
                    }
                    <div style={{marginTop: formik.values.active !== 'registration' ? 20 : 20, position: 'relative'}}>
                        {
                            formik.values.method === 'phone' &&
                            <TextFieldPhone 
                                name="phone"
                                value={formik.values.phone}
                                placeholder={translate('common.fieldPhone')}
                                title={translate('common.phoneUpper')}
                                helper={formik.values.active === 'registration' && translate('common.dangeres')}
                                formik={formik}
                                onChange={(v, item) => {
                                    if (!isNaN(+v.target.value) && v.target.value.length <= item.length) {
                                        formik.setFieldValue('phone', v.target.value);
                                    }
                                    setError(false);
                                    setErrorRegistration(false);
                                }}
                            />
                        }
                        {
                            formik.values.method === 'email' &&
                            <TextFieldEmail
                                name="email" 
                                errors={formik.values.active === 'registration' && formik.errors}
                                value={formik.values.active === 'registration' ? formik.values.email : formik.values.email2} 
                                placeholder={formik.values.active === 'auth' ? translate('common.fieldEmailOrLogin') : translate('common.fieldEmail') }
                                title={formik.values.active === 'auth' ? translate('common.emailOrLogin') : 'E-mail'}
                                onChange={(v) => {
                                    if (formik.values.active === 'registration') {
                                        formik.setFieldValue('email', v.target.value)
                                    } else {
                                        formik.setFieldValue('email2', v.target.value)
                                    }
                                   
                                    setError(false);
                                    setErrorRegistration(false)
                                }}
                            />
                        }
                        <TextFieldPassword 
                            name="password"
                            value={formik.values.password}
                            placeholder={translate('common.fieldPassword')}
                            title={translate('common.passwordUpper')}
                            active={formik.values.active}
                            style={{marginBottom: 0}}
                            onChange={(v) => {
                                formik.setFieldValue('password', v.target.value)
                                setError(false);
                                setErrorRegistration(false);
                            }}
                        />
                        {
                            formik.values.active === 'registration' &&
                            <div style={{display: 'flex', flexDirection: 'column',  marginTop: 10}}>
                                <div style={{marginRight: 12, color: '#fff', fontFamily: 'Monthserrat, sans-serif', marginBottom: 8}}>
                                    <span>{translate('common.currency')}: </span>
                                </div>
                                
                                <select 
                                    value={formik.values.currency}
                                    onChange={(v) => formik.setFieldValue('currency', v.target.value)}
                                    // disabled
                                    style={{width: '100%', height: 40, border: '1px solid #7B61FF', borderRadius: 4, outline: 'none', background: 'rgba(0, 30, 91)', color: '#fff', paddingLeft: 10}}
                                >
                                    <option style={{display: 'none'}}>{translate('common.currencySelect')}</option>
                                    <option value="UZS">UZS</option>
                                </select>
                                
                            </div>
                        }
                        {
                            formik.values.active === 'registration' && 
                            <TextField 
                                name="promocode"
                                value={formik.values.promocode}
                                placeholder={translate('common.fieldPromocode')}
                                onChange={(v) => {
                                    formik.setFieldValue('promocode', v.target.value)
                                }}
                            />
                        }
                                            
                                            
                    </div>
                    <div style={{height: 13}}>
                        {
                            error && 
                            <span style={{
                                color: 'red',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: 11,
                            }}>{translate('common.badLoginOrPassword')}</span>
                        }
                        {
                            errorRegistration &&
                            <span style={{
                                color: 'red',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: 11,
                            }}>{translate('common.userCreatedAfter')}</span>
                        }
                    </div>
                    <div className={formik.values.active === 'auth' && classes.mt20}>
                        {
                            formik.values.active === 'auth' &&
                            <span
                                className={classes.btn}
                                onClick={() => {
                                    formik.submitForm()
                                }}
                            >{translate('auth.signin')}</span>
                        }
                        {
                            formik.values.active !== 'auth' && formik.values.check &&
                            <span
                                className={getValidation(formik.values) && (formik.errors && formik.errors.email !== 'Invalid email') ? classes.btn : classes.btn2}
                                style={{marginTop: 12}}
                                onClick={() => {
                                    if (getValidation(formik.values)) {
                                        formik.submitForm();
                                    }
                               }}
                            >{translate('reg.signup')}</span>
                        } 
                        {
                            formik.values.active !== 'auth' && !formik.values.check &&
                            <Tooltip title={translate('common.18year')}>
                                <span
                                    className={classes.btn2}
                                >
                                    {translate('reg.signup')}
                                </span>
                            </Tooltip>
                        }                   
                    </div>
                    {
                        formik.values.active === 'registration' &&
                        <div style={{marginTop: 20}}>
                            <label htmlFor="check" >
                                <input 
                                    type="checkbox" 
                                    name="check" 
                                    id="check"
                                    checked={formik.values.check}
                                    onChange={(v) => formik.setFieldValue('check', v.target.checked)}
                                />
                                <span style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '10px',
                                    lineHeight: '12px',
                                    color: '#8E9EC2',
                                    userSelect: 'none',
                                }}>
                                     {translate('common.18yearBr')} <span style={{textDecoration: 'underline', cursor: 'pointer'}}>{translate('common.regulationsCheck')}</span>, <span style={{textDecoration: 'underline', cursor: 'pointer'}}>{translate('common.regulationsGame')}</span>, <span style={{textDecoration: 'underline', cursor: 'pointer'}}>{translate('common.regulationsCancelPays')}</span>
                                </span>
                            </label>
                        </div>
                    }
                </div>
            </div>
    )
}

export default MethodUse;