
import {getLoterryView} from '../api/getLoterryView';
import {setGetLoterryViewAction} from '../action';

export const setGetLoterryView = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getLoterryView(params);

            if (data.status === 'success') {
               
                dispatch(setGetLoterryViewAction(data.data));
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}