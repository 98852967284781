
import {setGetBonusesNotificationAction} from '../action';
import { getBonusesNotification } from '../api/getBonusesNotification';

export const setGetBonusesNotification = (params) => {
    return async (dispatch, getState) => {
        try {

            const {data} = await getBonusesNotification(params);

            if (data.status === 'success') {
                dispatch(setGetBonusesNotificationAction({
                    bonuses: data.data.bonuses,
                    jackpot: data.data.jackpot
                }))
            }

        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}