
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%', maxWidth: 1140, margin: '0 auto', color: '#fff'
    },
    paragraf: {
        color: 'rgba(255,255,255,0.8)!important',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const Kyc = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>ACTIV AGUA ALIANZA LIMITADA</h2>
            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold' }}>
                NEDEN DOKÜMANTASYON SAĞLAMAM GEREKİYOR?
            </h2>

            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Birkaç sebep var:
                <br />
                Sosyal açıdan sorumlu bir eğlence oyun platformu sağlamaya kararlıyız. Tüm üyelerimizin 18 yaşından büyük olması gerekmektedir ve bunu lisans sözleşmemiz gereğince onaylamamız gerekmektedir.
                <br />
                İkinci olarak, saygın bir çevrimiçi ve küresel şirket olarak, tüm işlemlerin mümkün olduğunca güvenli olmasını sağlamak bizim çıkarımızadır.
                <br/>
                Üçüncüsü, kara para aklamayla mücadele etmek için politikamızın uluslararası bankacılık standartlarına uygun olmasını istiyoruz. Her bir üye ile doğrulanmış bir iş ilişkisi, tüm tarafların korunması için bir zorunluluktur. Lisans sözleşmemiz de buna uymamızı zorunlu kılmaktadır.
                <br/>
                Son olarak, hesap bilgilerinizin kesinlikle doğru olduğundan emin olarak, "kaçırılan ödemeler" gibi rahatsızlıklardan kaçınabilirsiniz.
            </p>

            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold' }}>
                HANGİ BELGELERİ SAĞLAMAM GEREKİYOR?
            </h2>

            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                KİMLİK ONAYI:
                <br />
                Geçerli bir devlet kimliğinin (ehliyet, pasaport, devlet kimliği veya askeri kimlik) renkli bir kopyası.
                <br />
                ADRES ONAYI:
                <br/>
                Adresinizi gösteren yeni bir elektrik faturasının bir kopyası.
                <br/>
                Not. Adresiniz devlet tarafından verilen kimliğinizdeyse, ek adres kanıtı sağlamanız gerekmez.
                <br/>
                Seçtiğiniz para çekme yöntemine bağlı olarak ek belgeler gerekebilir.
            </p>

            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold' }}>
                BU BELGELERİ NE ZAMAN VERMELİYİM?
            </h2>

            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                İşlemlerinizin işlenmesinde gecikmeleri önlemek için bunları mümkün olan en kısa sürede sağlama konusundaki işbirliğiniz için çok teşekkür ederiz. Herhangi bir nakit işlemi size geri gönderilmeden önce belgeleri almalıyız. Özel durumlarda, hesabınızda başka işlemlerden (para yatırma ve bahis oynama) önce belgeler isteyebiliriz.
                <br/>
                Lütfen gerekli belgelere sahip olmadığımız takdirde bekleyen para çekme işlemlerinizin iptal edileceğini ve hesabınıza iade edileceğini anlayın. Bu olduğunda, bildirim sistemi aracılığıyla bilgilendirileceksiniz.
            </p>

            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold' }}>
                BU BELGELERİ SİZE NASIL GÖNDEREBİLİRİM?
            </h2>

            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Lütfen belgelerinizi tarayın veya dijital kameranızdan yüksek kaliteli bir fotoğraf çekin, görüntüleri jpeg olarak kaydedin ve ardından dosyaları güvenli formumuzu kullanarak hesabınıza yükleyin.
            </p>

            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold' }}>
                BELGELERİMİN GÜVENDE OLDUĞUNU NASIL ANLARIM?
            </h2>

            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Belgelerinizin güvenliği her şeyden önemlidir. Tüm dosyalar, doğrulama sürecinin her adımında en yüksek düzeyde şifreleme ile korunur. Alınan tüm belgeler azami saygı ve gizlilikle ele alınır.
                 <br/>
                 {window.location.origin} oynamak için daha güvenli bir yer haline getirmemize yardımcı olan işbirliğiniz için teşekkür ederiz.
            </p>

            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold' }}>
            BİZİMLE İLETİŞİME GEÇİN
            </h2>

            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Bu Politika hakkında daha fazla bilgi edinmek için bizimle iletişime geçmek veya bireysel haklarla ilgili herhangi bir sorunuz için bizimle iletişime geçmek isterseniz, win777fun@gmail.com adresine bir e-posta gönderebilirsiniz.
            </p>


        </div>
    )
}

export default Kyc;