import React from 'react';
import Banner from './Banner.png';
import BannerTR from './BannerTRY.png';
import BannerUSA from './BannerUSA.png';
import {useStyles} from './helper';

const BannerSix = () => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{position: 'relative'}}>
            <div className={classes.wrapIcon} style={{marginLeft: 2, marginTop: -40}}>
                <img src={BannerTR} alt="banner" style={{width: 1140, height: 390}}/>      
            </div>
        </div>
    )
}

export default BannerSix;