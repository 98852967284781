import { postUpdateProfile } from '../api/postUpdateProfile';


export const setPostUpdateProfile = (params) => {
    return async (dispatch, getState) => {
        try {
            await postUpdateProfile(params);
        
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}