import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fund from '../../../../../@casino/img/fund.png';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        justifyContent: 'center'
    },
    wrapDescription: {
        display: 'flex', 
        justifyContent: 'center'
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        textAlign: 'justify',
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 30,
        width: 320
    },
    wrapTournament: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginBottom: 30,
    },
    labelStarted: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '22px',
        lineHeight: '27px',
        color: '#FFBF39',
    },
    date: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontSize: '30px',
        lineHeight: '27px',
        color: '#FFFFFF',
        fontWeight: 700,
    },
    wrapFund: {
        display: 'flex', 
        justifyContent: 'center', 
        marginBottom: 42
    },
    rootFund: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        width: 360, 
        height: 200, 
        background: `url(${Fund})`, 
        backgroundPositionX: 0
    },
    labelFund: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '22px',
        textAlign: 'center',
        color: '#FFBF39'
    },
    amountFund: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontSize: '70px',
        lineHeight: '70px',
        textAlign: 'center',
        fontWeight: 600,
        color: '#FFFFFF',
    },
    wrapHowWin: {
        display: 'flex',
        justifyContent: 'center'
    },
    titlehowwin: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF'
    },
    wrapItem: {
        display: 'flex', 
        justifyContent: 'center',
        marginBottom: 10
    },
    dash: {
        width: 27, 
        height: 2, 
        background: '#FFBF39',
        marginRight: 11, 
        marginTop: 10
    },
    titleItem: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 282,
    },
    color: {
        color: '#FFBF39'
    }
}))

const DescriptionMobile = ({tournament}) => {
    const classes = useStyles();
    let month = [
        translate('month.jan'), 
        translate('month.feb'), 
        translate('month.mar'), 
        translate('month.mar'), 
        translate('month.may'), 
        translate('month.jun'), 
        translate('month.jul'), 
        translate('month.avg'), 
        translate('month.sep'), 
        translate('month.oct'), 
        translate('month.nov'), 
        translate('month.dec')
    ];
    
    return (
        <>
            <h3 className={classes.title}>{translate('tournament.description')}</h3>
            <div className={classes.wrapDescription}>
                <span className={classes.description}>{tournament.tournament.description}</span>
            </div>
            <div className={classes.wrapTournament}>
                <span className={classes.labelStarted}>{translate('tournament.startTo')}</span>
                <span className={classes.date}>
                    {translate('tournament.in')} 03:00 {new Date(+tournament.tournament.date_from).getDate() < 10 ? '0' + new Date(+tournament.tournament.date_from).getDate() : new Date(+tournament.tournament.date_from).getDate()} {month[new Date(+tournament.tournament.date_from).getMonth()]}
                </span>
                <span className={classes.labelStarted}>{translate('tournament.endTo')}</span>
                <span className={classes.date}>
                    {translate('tournament.in')} 03:00 {new Date(+tournament.tournament.date_to).getDate() < 10 ? '0' + new Date(+tournament.tournament.date_to).getDate() : new Date(+tournament.tournament.date_to).getDate()} {month[new Date(+tournament.tournament.date_to).getMonth()]}
                </span>
            </div>
            <div className={classes.wrapFund}>
                <div className={classes.rootFund}>
                    <span className={classes.labelFund}>{translate('tournament.prizefond')}</span>
                    <span className={classes.amountFund}>{tournament.tournament.fund}</span>
                </div>
            </div>
            <div>
                <div className={classes.wrapHowWin}>
                    <h3 className={classes.titlehowwin}>{translate('tournament.howToWin')}</h3>
                </div>
                    
                <div>
                    <div className={classes.wrapItem}>
                        <span className={classes.dash}></span>
                        <span className={classes.titleItem}>{translate('tournament.openRandomGame')}.</span>
                    </div>
                    <div className={classes.wrapItem}>
                        <span className={classes.dash}></span>
                        <span className={classes.titleItem}>{translate('tournament.quafilication')} - <span className={classes.color}>{tournament.tournament.count_spin} {translate('tournament.spin')}</span> {translate('tournament.bet')} <span className={classes.color}>{tournament.tournament.min_bet}.</span></span>
                    </div>
                    <div className={classes.wrapItem}>
                        <span className={classes.dash}></span>
                        <span className={classes.titleItem}>{translate('tournament.qualifcationBefore')} <span className={classes.color}>{tournament.tournament.min_bet}</span> {translate('tournament.qualifcationBeforeTo')}.</span>
                    </div>
                    <div className={classes.wrapItem}>
                        <span className={classes.dash}></span>
                        <span className={classes.titleItem}>{translate('tournament.prizer')} <span className={classes.color}>{tournament.tournament.count_winners} {translate('tournament.prizerCount')}</span> {translate('tournament.what')}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DescriptionMobile;