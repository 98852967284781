
import axios from 'axios';
import {backendHost} from '../../../../../store/config';

export const getListProvider = (params) => {
    if (localStorage.getItem('token')) {
        return axios.get(`${backendHost}/api/v2/games/provider/list`, {params});
    } else {
        return axios.get(`${backendHost}/api/games/provider/list/demo`, {params})
    }
};