import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGamesListSlot } from '../../store/thunk/setGamesSlot';
import makeStyles from '@material-ui/core/styles/makeStyles'
import Dialog from '@material-ui/core/Dialog';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import {ReactComponent as Search} from '../../../../../../../@casino/img/search.svg';
import {setGetListProvider} from '../../../../../home/store/thunk/setGetListProvider';
import { translate } from '../../../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '29px 10px 20px 10px',
        background: 'rgba(2, 0, 4, 0.55)',
        marginBottom: 15,
    },
    item_nav: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8e9ec2',
        marginRight: 28,
        cursor: 'pointer',
        userSelect: 'none',
        transition: 'color .2s ease',
        '&:hover': {
            color: '#FF005C',
            transition: 'color .2s ease',
        }
    },
    item_active: {
        color: '#FF005C'
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        border: '1px solid #FF005C',
        borderRadius: '50px',
        padding: '10px 40px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        color: '#FF005C',
        '&:hover': {
            background: '#ED074C',
            color: '#fff',
            transition: 'background, color .3s ease',
            
        }
    },
    gamegame: {
        position: 'absolute', 
        top: 0, 
        width: '100%', 
        height: 141, 
        background: 'rgba(0,0,0,.5)', 
        zIndex: 100, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity .3s ease',
        '&:hover': {
            opacity: 1,
            transition: 'opacity .3s ease',
        }
    },
    search: {
        border: 'none',
        borderRadius: 3,
        paddingTop: 2,
        height: 40,
        width: '100%',
        color: '#D2D8E3',
        fontSize: 16,
        fontFamily: 'Montserrat, sans-serif',
        background: 'rgba(2, 0, 4, 0.3)',
        boxShadow: 'inset 0px 4px 4px rgba(2, 0, 4, 0.1)',
        paddingLeft: 20,
        outline: 'none',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    wrapSearch: {
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    wrapGames: {
        paddingBottom: 60, 
        display: 'flex', 
        justifyContent: 'flex-start', 
        flexWrap: 'wrap', 
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 10
        }
    },
    wrapGameItem: {
        width: 210, 
        height: 141, 
        borderRadius: 3, 
        position: 'relative', 
        transition: 'all .3s ease',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 141
        }
    },
    wrapImageGame: {
        width: 210, 
        height: 141, 
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minHeight: 141
        }
    }
}))

const SlotGameMobile = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const classes = useStyles();
    const [showModal, setShowModal] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState('')
    const [selectProvider, setSelectProvider] = React.useState('');

    React.useEffect(() => {
        dispatch(setGetListProvider())    
    }, [])

    const games = useSelector((state) => state.mobile_games.slot);
    const profile = useSelector((state) => state.profile)
    const provider = useSelector(state => state.games.list_provider);

    return (
        <div className={classes.root}>
            <div style={{marginBottom: 12}}>
                    <span style={{fontFamily: 'Montserrat, sans-serif', fontSize: 16, color: '#fff'}}>{translate('common.provider')}</span>
                </div>
            <div style={{display: 'flex', alignItems: 'center', padding: '0 10px 10px', overflowY: 'scroll'}}>
                     <span 
                        onClick={() => {
                            
                            dispatch(setGamesListSlot({
                                page: 1,
                                type: 'slots',
                                provider: "",
                                is_desktop: 0,
                                is_mobile: 1,
                                search: "",
                            }));
                            setSelectProvider('')
                        }}
                        style={{color: '#fff', fontFamily: 'Montserrat, sans-serif', marginRight: 12, padding: 10, border: '1px solid #eee', borderRadius: 4}}>
                            {translate('common.all')}
                        </span>
                {
                    provider.map((item, key) => item.provider !== 'Ezugi' && item.provider !== 'Betgames' && <span 
                        key={key}
                        onClick={() => {
                            dispatch(setGamesListSlot({
                                page: 1,
                                type: 'slots',
                                provider: item.provider, 
                                is_desktop: 0,
                                is_mobile: 1,
                                search: "",
                            }));
                            setSelectProvider(item.provider)
                        }}
                        style={{color: '#fff', fontFamily: 'Montserrat, sans-serif', marginRight: 12, padding: 10, border: '1px solid #eee', borderRadius: 4}}>
                            { item.provider}
                        </span>)
                }
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginBottom: 30}}>
                <div style={{marginBottom: 12}}>
                    <span style={{fontFamily: 'Montserrat, sans-serif', fontSize: 20, color: '#fff'}}>{translate('common.slotGame')}</span>
                </div>
                <div style={{width: 'calc(65%)', position: 'relative'}}>
                    <input 
                        type="text" 
                        name="" 
                        id=""
                        placeholder={translate('common.search')}
                        className={classes.search}
                        onChange={(v) => {
                            dispatch(setGamesListSlot({
                                page: 1,
                                type: 'slots',
                                provider: selectProvider, 
                                is_desktop: 0,
                                is_mobile: 1,
                                search: v.target.value
                            }));
                            setSearch(v.target.value)
                        }}
                    />
                    <div>
                        <Search style={{position: 'absolute', top: 15, right: 13}}/>
                    </div>
                
                </div>
            </div>
            <div className={classes.wrapGames}>
                {
                    games.list.map((item, key) => (
                        <div
                            key={key}
                            className={classes.wrapGameItem}
                            onClick={() => {
                                if ((item.provider === 'Betgames' || item.provider === 'Ezugi') && (profile && (profile.air_balance || profile.wager))) {
                                    setShowModal(true)
                                } else {
                                    window.location.replace(window.location.origin + '/games/' + item.uuid)
                                }
                                
                            }}
                        >
            
                            <div className={classes.gamegame}>
                                <span style={{ cursor: 'pointer', userSelect: 'none', marginTop: 5, background: '#FF005C', padding: '10px 40px', borderRadius: '50px', color: '#fff', fontFamily: 'Montserrat', fontWeight: 500}}>
                                    {translate('common.gaming')}
                                </span>
                            </div>
                        
                            <img 
                                src={item.image}
                                className={classes.wrapImageGame}
                                alt=""/>
                        </div>
                    ))
                }
            </div>
            {
                page !== games.page &&
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <span 
                        onClick={() => {
                            dispatch(setGamesListSlot({

                                page: page + 1,
                                type: 'slots',
                                provider: selectProvider, 
                                is_desktop: 0,
                                is_mobile: 1,
                                search: search
                            }));
                            setPage(page + 1);
                        }}
                    className={classes.btn}>
                        {translate('common.show')}
                    </span>
                </div>
            }
            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
            >
                <div className={classes.root}>
                    <div style={{display: 'flex', justifyContent: 'center', padding: '0 20px 20px', flexDirection: 'column', alignItems: 'center'}}>
                        <h3 style={{
                            margin: 0, 
                            padding: 0,
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                        
                            display: 'flex',
                            alignItems: 'center'
                            }}>{translate('notification.noaccessLive')}</h3>
                        <span style={{
                                marginTop: 12,
                                borderRadius: '50px',
                                padding: '5px 60px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FF005C',
                                color: '#FFFFFF',
                                cursor: 'pointer'
                            }}
                                onClick={() => setShowModal(false)}
                            >{translate('common.ok')}</span>
                    </div>
        
                </div>
            </Dialog>
        </div>
    )
}

export default SlotGameMobile;