import React from 'react';
import Logo from '../../../../../@casino/img/logo.png';
// import {ReactComponent as Logo} from '../../../../../@casino/img/logo.svg';
import { useRouter } from '../../../../../@casino/hook/useRouter';
// import {useStyles} from './helper';
import ModalAuth from '../../components/modalAuth';
import { useSelector } from 'react-redux';
import Confirmation from '../../components/confirmation';
import { configNagiation } from '../../../../main/config';
import Btn from '../../../../../@casino/ui/btn';
import BlockAuth from '../../components/blockAuth';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';
import PropTypes from 'prop-types';
import SelectUnstyled, { selectUnstyledClasses } from '@mui/base/SelectUnstyled';
import OptionUnstyled, { optionUnstyledClasses } from '@mui/base/OptionUnstyled';
import { styled } from '@mui/system';
import { PopperUnstyled } from '@mui/base';
import { countries } from '../../../../../config/countries.config';

const StyledButton = styled('button')`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 80px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0.75em;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: #000;

  &.${selectUnstyledClasses.focusVisible} {
    outline: 4px solid rgba(100, 100, 100, 0.3);
  }

  &.${selectUnstyledClasses.expanded} {
    border-radius: 0.75em 0.75em 0 0;

    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }

  & img {
    margin-right: 10px;
  }
`;

const StyledListbox = styled('ul')`
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: #fff;
  min-width: 80px;
  border: 1px solid #ccc;
  border-top: none;
  color: #000;
  max-height: 480px;
  overflow: auto;
`;

const StyledOption = styled(OptionUnstyled)`
  list-style: none;
  padding: 4px 10px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.disabled} {
    color: #888;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: rgba(25, 118, 210, 0.08);
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #16d;
    color: #fff;
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #05e;
    color: #fff;
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #39e;
  }

  & img {
    margin-right: 10px;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

const CustomSelect = React.forwardRef(function CustomSelect(props, ref) {
    const components = {
        Root: StyledButton,
        Listbox: StyledListbox,
        Popper: StyledPopper,
        ...props.components,
    };

    return <SelectUnstyled {...props} ref={ref} components={components} />;
});

CustomSelect.propTypes = {
    /**
     * The components used for each slot inside the Select.
     * Either a string to use a HTML element or a component.
     * @default {}
     */
    components: PropTypes.shape({
        Listbox: PropTypes.elementType,
        Popper: PropTypes.elementType,
        Root: PropTypes.elementType,
    }),
};


const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 41,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1140,
        margin: '0 auto',
    },
    nav_item: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        marginRight: 28,
        cursor: 'pointer',
        transition: 'color .2s ease',
        userSelect: 'none',
        '&:hover': {
            color: "#FF005C!important",
            transition: 'color .2s ease',
        }
    },
    btn: {
        padding: '10px 40px',
        background: '#FF005C',
        borderRadius: 50,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        userSelect: 'none',
        color: '#fff',
        transition: 'background .2s ease',
        cursor: 'pointer',
        '&:hover': {
            background: "#ED074C",
            transition: 'background .2s ease',
        }
    },
    blockItem: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        cursor: 'pointer',
        userSelect: 'none',
        padding: '5px 10px',
        '&:hover': {
            background: 'rgba(255,255,255,0.1)'
        }
    },
    flexbox: {
        display: 'flex',
        alignItems: 'center'
    },
    navigation: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    navigationRight: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    headers: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            background: '#142A8F',
            height: 64,
            position: 'absolute',
            top: 0,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
        }
    },
    logo: {
        display: 'block',
        width: 230,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    wrapRootNavigation: {
        position: 'absolute',
        zIndex: 100,
        right: -20
    },
    rootNavigation: {
        display: 'flex',
        flexDirection: 'column',
        width: 320,
        padding: '22px 17px 12px 17px',
        background: '#002062',
        borderRadius: 3
    },
    wrapItemNavigation: {
        padding: '10px 0 10px 30px',
        background: '#142A8F',
        borderRadius: 3,
        marginBottom: 10,
        cursor: 'pointer',
        transition: 'background .3s ease',
        '&:hover': {
            background: '#7B61FF',
            transition: 'background .3s ease',
        }
    },
    itemNavigation: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
    },
    activeItemNavigation: {
        position: 'relative',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
        '&::after': {
            position: 'absolute',
            top: -5,
            left: -20,
            content: '""',
            display: 'block',
            height: 30,
            width: 3,
            background: '#FF3156'
        }
    },
    activeWrapItemNavigation: {
        padding: '10px 0 10px 30px',
        background: 'linear-gradient(270deg, rgba(20, 42, 143, 0.5) 0%, rgba(0, 74, 236, 0.5) 100%), #142A8F',
        borderRadius: 3,
        marginBottom: 10,
    }
}))

const Header = ({ auth }) => {
    const classes = useStyles();
    const router = useRouter();
    const { pathname } = router;

    let isShow = false;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        isShow = false
    } else if (pathname === '/auth') {
        isShow = true
    }

    const [showModal, setShowModal] = React.useState(isShow);
    const [showModalConfirm, setShowModalConfirm] = React.useState('');
    const [login, setLogin] = React.useState('');
    const [nav, setNav] = React.useState(false);
    const profile = useSelector(state => state.profile.view);

    let balance = 0;

    if (profile && profile.balance) {
        balance = new Intl.NumberFormat('ru-RU').format(Math.floor((profile.balance) * 100) / 100);
    }

    return (
        <div className={classes.root} style={{ position: 'relative' }}>
            <img
                src={Logo}
                alt="logo"
                onClick={() => router.history.push('/')}
                style={{ cursor: 'pointer' }}
                className={classes.logo} />

            <nav className={classes.navigation}>
                {
                    configNagiation.map((item, key) => (
                        <span
                            key={key}
                            className={classes.nav_item}
                            style={{ color: pathname === item.path && '#FF005C' }}

                            onClick={() => router.history.push(item.path)}
                        >
                            {item.label}
                        </span>
                    ))
                }
            </nav>
            <div className={classes.headers}>
                <img src={Logo} alt="logo" onClick={() => router.history.push('/')} style={{ cursor: 'pointer', height: 50, marginTop: 8 }} />
                <div style={{ display: 'flex', position: 'relative' }}>
                    <div
                        style={{ display: 'flex', flexDirection: 'column', padding: '12px 9px', background: '#FF005C', borderRadius: '3px', marginRight: 40 }}
                        onClick={() => setNav(!nav)}
                    >
                        <span style={{ width: 22, height: 3, background: '#fff', marginBottom: 4 }}></span>
                        <span style={{ width: 22, height: 3, background: '#fff', marginBottom: 4 }}></span>
                        <span style={{ width: 22, height: 3, background: '#fff' }}></span>
                    </div>
                    <div className={classes.wrapRootNavigation} style={{ top: nav ? 80 : -1180, transition: 'top .5s ease' }}>
                        <div className={classes.rootNavigation}>
                            <div
                                className={pathname === '/' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                onClick={() => {
                                    router.history.push('/');
                                    setNav(false)
                                }}
                            >
                                <span
                                    className={pathname === '/' ? classes.activeItemNavigation : classes.itemNavigation}
                                >{translate('navigation.main')}</span>
                            </div>
                            <div
                                onClick={() => {
                                    router.history.push('/bet')
                                    setNav(false)
                                }}
                                className={pathname === '/lotteries' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}>
                                <span
                                    className={pathname === '/lotteries' ? classes.activeItemNavigation : classes.itemNavigation}
                                >{translate('navigation.lotteries')}</span>
                            </div>
                            {/* <div
                                className={pathname === '/tournament' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                onClick={() => {
                                    router.history.push('/mobile/tournament');
                                    setNav(false);
                                }}
                            >
                                <span
                                    className={pathname === '/tournament' ? classes.activeItemNavigation : classes.itemNavigation}
                                >{translate('navigation.tournament')}</span>
                            </div> */}
                            {
                                window.localStorage.getItem('token') &&
                                <div
                                    className={pathname.indexOf('/profile') > -1 ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                    onClick={() => {
                                        router.history.push('/profile/account');
                                        setNav(false);
                                    }}
                                >
                                    <span
                                        className={pathname.indexOf('/profile') > -1 ? classes.activeItemNavigation : classes.itemNavigation}
                                    >{translate('navigation.profile')}</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>


            </div>
            <div className={classes.navigationRight}>
                {
                    !auth && <Btn title={translate('common.signin')} event={() => setShowModal(true)} />
                }
                {
                    auth &&
                    <BlockAuth profile={profile} balance={balance} />
                }

            </div>
            <ModalAuth setShowModal={setShowModal} showModal={showModal} setShowModalConfirm={setShowModalConfirm} setLogin={setLogin} showModalConfirm={showModalConfirm} />
            <div style={{ position: 'absolute', top: 5, right: - 20 }}>
                <CustomSelect value={localStorage.getItem('language') || 'TR'} onChange={(v) => {
                    localStorage.setItem('language', v);
                    window.location.replace(window.location.origin)
                }}>
                    {countries.map((c, key) => (
                        <StyledOption key={c.code} value={c.code} key={key}>
                            <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${c.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${c.code.toLowerCase()}.png 2x`}
                                alt={`Flag of ${c.label}`}
                            />
                            {c.code}
                        </StyledOption>
                    ))}
                </CustomSelect>
            </div>
        </div>
    )
}

export default Header;