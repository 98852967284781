import React from 'react';
import {useStyles} from './helper';

const Label = ({title}) => {
    const classes = useStyles();
    
    return (
        <h3
            className={classes.title}
        >
            {title}
        </h3>
    )
}

export default Label;