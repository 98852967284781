import React from 'react';
import BgTournament from '../../../../../@casino/img/bgtournament.svg';
import {useStyles} from './helper';
import { translate } from '../../../../../dictionaries';
import { useRouter } from '../../../../../@casino/hook/useRouter';

const BannerTournament = ({tournament}) => {
    const classes = useStyles();
    const router = useRouter();
    return (
        <div 
            style={{
                background: `url(${BgTournament}`, 
                backgroundColor: '#1d2e7d'
            }}
            className={classes.root}>
        <div className={classes.wrapLabel}>
            <span className={classes.label}>
                {translate('tournament.title')}
            </span>
        </div>
        <div className={classes.wrapBody}>
                <span className={classes.tournamentName}>
                    {tournament.tournament.name}
                </span>
                <span className={classes.fundTitle}>
                    {translate('tournament.prizefond')}
                </span>
                <span className={classes.fundAmount}>
                    +{new Intl.NumberFormat('ru-RU').format(tournament.tournament.fund ? tournament.tournament.fund : 0)}
                </span>
                <span className={classes.btn} onClick={() => {
                    if (localStorage.getItem('token')) {
                        router.history.push('/')
                    } else {
                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) { 
                            window.location.replace(window.location.origin + '/auth/mobile');
                        } else {
                            router.history.push('/auth') 
                        }
                        
                    }
                }}>
                    {translate('tournament.startgame')}
                </span>
            </div>
        </div> 
    )
}

export default BannerTournament;