import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    btn: {
        padding: '10px 40px',
        background: '#FF005C',
        borderRadius: 50,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        userSelect: 'none',
        color: '#fff',
        transition: 'background .2s ease',
        cursor: 'pointer',
        '&:hover': {
            background: "#ED074C",
            transition: 'background .2s ease',
        }
    },
}))


const Btn = ({title, event}) => {
    const classes = useStyles();

    return (
        <span className={classes.btn} onClick={() => event()}>{title}</span>
    )
}

export default Btn;