import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { translate } from '../../../../dictionaries';
import ResponsibleTR from './blocks/tr';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 1140,
        margin: '120px auto',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            padding: '0 20px'
        }
    }
}))

const Responsible = () => {
    document.title = translate('title.responsibleGaming')

    const classes = useStyles();
    
        return (
            <div className={classes.root}>
                <ResponsibleTR />
            </div>
        )
    
}

export default Responsible;