import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetListLastWin } from '../../store/thunk/setGetListLastWin';
import WinTable from '../../../../../@casino/ui/WinTable';
import {getRows, getRowsMobile, columns, columns2, columnsMobile} from './helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';
import { setGetListLastWithdrawals } from '../../store/thunk/setGetListLastWithdrawals';

const useStyles = makeStyles((theme) => ({
    wrapTitle: {
        width: '100%',
        maxWidth: 1140,
        margin: '0 auto',
        marginTop: 109,
        marginBottom: 140
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        color: '#fff',
        textAlign: 'center',
        marginBottom: 41
    },
    wrapTableDesktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    wrapTableMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    }
}))

const ListLastWin = () => {
    const dispatch = useDispatch(); 
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetListLastWin());
        dispatch(setGetListLastWithdrawals());
    }, []);
  
    const list_last = useSelector(state => state.games.listlast);
    const list_withdrawals = useSelector(state => state.games.withdrawals);
    
    const rows = React.useMemo(() => getRows(list_last), [list_last]);
    const rows2 = React.useMemo(() => getRows(list_withdrawals), [list_withdrawals]);

    return (
        <div>
            <div className={classes.wrapTitle} style={{display: 'flex', width: '100%', justifyContent: 'space-between', columnGap: 12}}>
                <div style={{width: '50%'}}>
                    <h1 className={classes.title}>{translate('common.lastWinners')}</h1>
                    <div className={classes.wrapTableDesktop}>
                        <WinTable
                            columns={columns}
                            rows={rows}
                            pageCount={0}
                            labelNotFound={translate("common.lastWinnersInformation")}
                        /> 
                    </div>
                </div>
                <div style={{width: '50%'}}>
                    <h1 className={classes.title}>{translate('common.lastWithdrawal')}</h1>
                    <WinTable
                        columns={columns2}
                        rows={rows2}
                        pageCount={0}
                        labelNotFound={translate('common.infoWithdrawal')}
                    /> 
                </div>
            </div>            
        </div>
        
    )
}

export default ListLastWin;