import {types} from './types';

const initialState = {
    loading: false,
}

export function loadingReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LOADING:
            return { ...state, loading: action.payload};
        default:
            return state
    }
}