import JackpotGold from '../../../../../../@casino/img/gold.svg';
import JackpotPlatinum from '../../../../../../@casino/img/platinum.svg';
import JackpotSilver from '../../../../../../@casino/img/silver.svg';
import JackpotBronze from '../../../../../../@casino/img/bronze.svg';
import makeStyles from '@material-ui/styles/makeStyles';
import { translate } from '../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        cursor: 'pointer', 
        width: 150, 
        height: 47, 
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain !important',
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        transition: 'left .5s ease',
        
    },
    labelJackpot: {
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color .2s ease', 
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        padding: '5px 10px',
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: 20,
        userSelect: 'none'
    },
    amountJackpot: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '16px',
        color: '#fff',
        userSelect: 'none'
    }
}))


const getLabel = (value) => {
    switch (value) {
        case 'bonus-registration': return translate('bonus.registration');
        case 'bonus-referral': return translate('bonus.referral');
        case 'bonus-everyday': return translate('bonus.everyday');
        case 'bonus-promocode': return translate('bonus.promocode');
        case 'bonus-weekly': return translate('bonus.weekly');
        case 'bonus-cashback': return translate('bonus.cashback');
        case 'bonus-first-deposit': return translate('bonus.first-deposit');
        case 'bonus-next-deposit': return translate('bonus.next-deposit');
        case 'bonus-personal': return translate('bonus.personal');
        case 'bonus-tournament': return translate('bonus.tournament');
        case 'bonus-loterry': return translate('bonus.loterry');
        default: return translate('common.default');
    }
}

const getLabelJackpot = (val) => {
    switch(val) {
        case 'bronze': return translate('jackpot.bronze');
        case 'silver': return translate('jackpot.silver');
        case 'gold': return translate('jackpot.gold');
        case 'platinum': return translate('jackpot.platinum');
        default: return translate('common.default');
    }
}

const getBackground = (val) => {
    switch(val) {
        case 'bronze': return JackpotBronze;
        case 'silver': return JackpotSilver;
        case 'gold': return JackpotGold;
        case 'platinum': return JackpotPlatinum;
        default: return translate('common.default');
    }
}

export {
    getLabel,
    getLabelJackpot,
    getBackground,
    useStyles,
}