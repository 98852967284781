import {types} from './types';

const initialState = {
    list: {
        data: [],
        page: 1,
    },
    view: {},
    win: [],
    result: [],
}

export function loterryReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LIST_LOTERRY:
            return { ...state, list: action.payload};
        case types.SET_VIEW_LOTERRY:
            return { ...state, view: action.payload};
        case types.SET_WIN_LOTERRY:
            return { ...state, win: action.payload};
        case types.SET_RESULT_LOTERRY:
            return { ...state, result: action.payload};
        default:
            return state
    }
}