import {types} from './types';

export const setProfileAction = (payload) => {
  return {
      type: types.SET_PROFILE,
      payload,
    }
}

export const setCountryAction = (payload) => {
  return {
      type: types.SET_COUNTRY,
      payload,
    }
}
