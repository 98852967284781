import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AuthMobileDialog from './block/authModal';
import { useSelector } from 'react-redux';
import {ReactComponent as Face} from '../../../../../../../@casino/img/face.svg';
import { Tooltip } from '@material-ui/core';
import {ReactComponent as Info} from '../../../../../../../@casino/img/info.svg';
import { getLogin } from '../../../../../../template/header/components/blockAuth/helper';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        padding: '10px', 
        display: 'flex', 
        gap: 10, 
        background: 'rgba(2,0,36,.8)'
    },
    signin: {
        background: '#7B61FF',
        height: 48,
        borderRadius: 4,
        color: '#fff',
        fontFamily: 'Montserrat, sans-serif',
        width: '50%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    registration: {
        background: '#FF005C',
        height: 48,
        borderRadius: 4,
        color: '#fff',
        fontFamily: 'Montserrat, sans-serif',
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    login: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        marginBottom: 6,
        userSelect: 'none'
    },
    balance: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',  
        userSelect: 'none',
    },
}))

const AuthMobile = () => {
    const classes = useStyles();
    const [isShowAuthModal, setIsShowAuthModal] = React.useState();

    const router = useRouter();
    const auth = localStorage.getItem('token')
    const profile = useSelector(state => state.profile.view)

    const getCurrency = (currency) => {
        switch (currency) {
            case 'RUB': return '₽';
            case 'USD': return '$';
            case 'EUR': return '€';
            default: return currency
        }
    }

    return (
        <div className={classes.root} style={{minHeight: 60}}>
            {
                !auth && 
                <>
                    <span className={classes.signin} onClick={() => setIsShowAuthModal('signIn')}>{translate('common.signin')}</span>
                    <span className={classes.registration} onClick={() => {
                        setIsShowAuthModal('signUp')
                    }}>{translate('reg.title')}</span>
                    <AuthMobileDialog isShowAuthModal={isShowAuthModal} setIsShowAuthModal={setIsShowAuthModal}/>
                </>
            }
            {
                auth && profile && 
                <div style={{width: '100%'}}>
                    {
                        profile && profile.connection_groups === 'false' && 
                        <div onClick={() => router.history.push('/profile/replenishment')} style={{display: 'flex', width: '100%', background: 'rgb(255, 0, 92)', justifyContent: 'center', color: '#fff', padding: '10px 0', borderRadius: 4, marginBottom: 12}}>
                            <span>{translate('common.replenishments')}</span>
                        </div>
                    }
                <div style={{padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', borderBottom: '2px solid rgba(255,255,255,.1', paddingBottom: 10}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Face 
                            onClick={() => router.history.push('/profile/account')}
                            style={{
                                width: 58, 
                                height: 58, 
                                marginRight: 13, 
                                cursor: 'pointer',
                                fill: '#fff'
                            }}
                        />
                        <div>
                            <span className={classes.login}>{translate('common.login')}: {profile?.login === '79653339666' ? '79********6' : profile.type_registrations !== 'phone' ? profile && profile.login : getLogin(profile && profile.login)}</span>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span className={classes.balance}>{translate('common.balance')}: {new Intl.NumberFormat('ru-RU').format(profile.balance.toFixed(2))} {getCurrency(profile.currency)}</span>
                                <span className={classes.balance}>{translate('common.balanceSport')}: {new Intl.NumberFormat('ru-RU').format(profile.balance_sport.toFixed(2))} {getCurrency(profile.currency)}</span>

                                {
                                    !!profile.wager && profile.connection_groups === 'true' && <span className={classes.balance} style={{color: 'rgb(255, 0, 92)'}}>{translate('common.noaccessWithdrawals')} ({new Intl.NumberFormat('ru-RU').format(+profile.wager)})</span>
                                }
                            </div>
                            
                        </div>
                    </div>
                   
                </div>
                </div>
            }
        </div>
    )
}

export default AuthMobile;