import React from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {setProfile} from './store/authorization/thunk/setProfile';
import { Route } from 'react-router';
import CallbackEmail from './components/template/callback';
import {useRouter} from './@casino/hook/useRouter';
import Layout from './components/template/layout';
import { useDispatch } from 'react-redux';
import GameItem from './components/main/home/components/gameItem';
import queryString from 'query-string';
import SuccessPayment from './components/main/success';
import { v4 as uuidv4 } from 'uuid';

const App = () => {
    const referral = queryString.parse(window.location.search);
    if (!localStorage.getItem('referral') || localStorage.getItem('referral') == 'undefined') {
        window.localStorage.setItem('referral', referral.referral);
        // setTimeout(() => window.history.pushState(null, null, '/'), 0);
    }
    const router = useRouter();
    const authLanding = router.query?.token;
    if (authLanding) {
        localStorage.setItem('token', `Bearer ${authLanding}`);
        window.history.pushState(null, document.title, '/');
    }
    const lang = router.query?.lang;
    const utm_source = router.query?.utm_source;
    const utm_medium = router.query?.utm_medium;
    const utm_campaign = router.query?.utm_campaign;
    const utm_content = router.query?.utm_content;
    const utm_term = router.query?.utm_term;
    if (utm_source) {
        localStorage.setItem('utm_source', utm_source)
    }
    if (utm_term) {
        localStorage.setItem('utm_term', utm_term)
    }
    if (utm_medium) {
        localStorage.setItem('utm_medium', utm_medium)
    }
    if (utm_campaign) {
        localStorage.setItem('utm_campaign', utm_campaign)
    }
    if (utm_content) {
        localStorage.setItem('utm_content', utm_content)
        window.history.pushState(null, document.title, '/');
    }
    if (lang) {
        localStorage.setItem('language', lang.toString().toUpperCase())
        window.history.pushState(null, document.title, '/');
    }
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    axios.defaults.headers['Authorization'] = token;
    axios.defaults.headers['Language'] = localStorage.getItem('language') || 'UZ';
    axios.defaults.headers['Organization'] = 1;
    axios.defaults.headers['VisualViewPortHeight'] = window?.visualViewport?.height;
    axios.defaults.headers['VisualViewPortWidth'] = window?.visualViewport?.width;
    axios.defaults.headers['VisualViewPortScale'] = window?.visualViewport?.scale;
    axios.defaults.headers['ClientInformationAppCodeName'] = window?.clientInformation?.appCodeName;
    axios.defaults.headers['ClientInformationAppName'] = window?.clientInformation?.appName;
    axios.defaults.headers['ClientInformationAppVersion'] = window?.clientInformation?.appVersion;
    axios.defaults.headers['ClientInformationLanguage'] = window?.clientInformation?.language;
    axios.defaults.headers['ClientInformationUserAgent'] = window?.clientInformation?.userAgent;
    axios.defaults.headers['DeviceWidth'] = window?.screen?.width;
    axios.defaults.headers['DeviceHeight'] = window?.screen?.height;
    axios.defaults.headers['DevicePlatform'] = window?.navigator?.platform;
    const cookie = readCookie('win777');

    if (!localStorage.getItem('language')) {
        localStorage.setItem('language', 'UZ')
    }

    if (!cookie) {
        const date = new Date;
        date.setDate(date.getDate() + 360);
        const uuid = uuidv4();
        document.cookie = `win777=${uuid}; path=/; expires=${date.toUTCString()}`;
        axios.defaults.headers['SessionConnect'] = uuid;
    } else {
        axios.defaults.headers['SessionConnect'] = cookie;
    }

    function readCookie(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    if (window.location.protocol.indexOf('https') === -1 && window.location.origin !== 'http://localhost:3001') {
        window.location.replace('https://' + window.location.host)
    }

    React.useEffect(() => {
        if (!!token) {
            dispatch(setProfile());
        }
    }, []);

    const profile = useSelector(state => state.profile.view);   

    const auth = !!token && !!profile;

    const callback = router.pathname.indexOf('callback/email');
    const games = router.pathname.indexOf('/games');
    const success = router.pathname.indexOf('/success');
    
    return (
        <div style={{
            height: '100%',
            overflow: 'hidden',
            position: 'relative'
        }}>
            {
                callback && <Route path="/callback/email/:id" component={() => <CallbackEmail />} />
            }
            {
                callback === -1 && games === -1 && success === -1 &&
                <>
                    <Layout auth={auth}/>
                </>
            }
            {
                games > -1 && <Route path="/games/:game_uuid" component={() => <GameItem profile={profile}/>} />
            } 
            {
                success > -1 && <Route path="/success" render={() => <SuccessPayment />} />
            } 
        </div>
    )
    
}

export default App;