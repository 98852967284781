import React from 'react';
import Girl from '../../../../../../../@casino/img/girld6.jpeg';
import Girl2 from '../../../../../../../@casino/img/girl7.jpeg';
import Girl3 from '../../../../../../../@casino/img/girl8.jpeg';
import Girl4 from '../../../../../../../@casino/img/girl9.jpeg';
import Girl5 from '../../../../../../../@casino/img/girl10.jpeg';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const LotteryMobile = ({item}) => {
    const {label, fund, status, date_to} = item;
    const now = new Date().getTime();
    const router = useRouter();

    const getDate = (val) => {
        const now = new Date().getTime();
        const diff = +val - now;
    
        var hours = (diff / 1000) / 60 / 60;
        var minutes = (diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60  ;
        var seconds = (diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;
        
        const day = Math.floor(hours) / 24;
        const hour = Math.floor(hours) - Math.floor(day) * 24;

        return `${Math.floor(day)} ${translate('common.day')} ${hour} ${translate('common.hours')} ${Math.floor(minutes) < 10 ? '0' + +Math.floor(minutes) : Math.floor(minutes)} ${translate('common.min')}`//`${Math.floor(hours) < 10 ? '0' + Math.floor(hours) : Math.floor(hours)} : ${Math.floor(minutes) < 10 ? '0' +Math.floor(minutes) : Math.floor(minutes)} : ${Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds)}`;

    }

    const girls = [Girl, Girl2, Girl3, Girl4 , Girl5, Girl, Girl2, Girl3, Girl4 , Girl5,Girl, Girl2, Girl3, Girl4 , Girl5, Girl, Girl2, Girl3, Girl4 , Girl5];

    return (
        <div style={{marginTop: 12, padding: '0 10px', marginBottom: 12, position: 'relative'}}>
            <div style={{background: 'rgba(2,0,4,.4)', width: '100%', height: 186, borderRadius: 4, position: 'relative'}}>
                <div style={{padding: 10, display: 'flex'}}>
                    <div>
                        <img src={girls[item.id - 1]} alt="" style={{height: 170, width: 128, objectFit: 'cover',borderRadius: 4}}/>
                    </div>
                    <div style={{fontFamily: 'Monserrat, sans-serif', color: '#fff', fontSize: 14, display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 12}}>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 12}}> <span>{translate('lottery.title')}</span> </div>
                        <div style={{marginBottom: 8}}><span style={{color: 'rgba(255,255,255,0.6)'}}>{translate('common.name')}: </span><span>{label}</span></div>
                        <div style={{marginBottom: 8}}><span style={{color: 'rgba(255,255,255,0.6)'}}>{translate('tournament.prizefond')}: </span><span>{new Intl.NumberFormat('ru-RU').format(fund)}</span></div>
                        {
                            status && date_to > now &&
                            <>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 8 }}><span style={{color: 'rgba(255,255,255,0.6)'}}>{translate('lottery.end')} </span></div>
                                <div style={{display: 'flex', justifyContent: 'center'}}><span style={{color: '#fff'}}>{getDate(date_to)}</span></div>
                            </>
                        }
                        {
                            (!status || date_to < now) && 
                            <>  
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 8 }}>
                                    <span style={{color: 'rgba(255,255,255,0.6)', fontFamily: 'Monserrat, sans-serif'}}>{translate('lottery.endof')}</span>
                                </div>
                            </>
                        }
                        
                      
                        <div style={{marginTop: 12}}>
                            <span 
                            onClick={() => router.history.push(`/mobile/lotteries/view/${item.id}`)}
                            style={{
                                background: 'rgb(123, 97, 255)',
                                height: '35px',
                                borderRadius: '4px',
                                color: 'rgb(255, 255, 255)',
                                fontFamily: 'Montserrat, sans-serif',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>{status && date_to > now ? translate('lottery.participate') : translate('lottery.checkResult')}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LotteryMobile;