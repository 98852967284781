import React from 'react';
import BonusMask from '../../../../../@casino/img/bonuses1.jpg';
import BonusMask2 from '../../../../../@casino/img/bonuses2.jpg';
import {ReactComponent as IconBonus1} from '../../../../../@casino/img/iconBonus1.svg';
import {ReactComponent as Coin} from '../../../../../@casino/img/coin.svg';
import BonusComponent from '../../../../../@casino/ui/bonus';
import Tournament from '../../../../../@casino/ui/tournament';
import { useDispatch, useSelector } from 'react-redux';
import { setGetLastTournament } from '../../store/thunk/setGetLastTournament';
import {getLabel} from './helper';
import {useStyles} from './helper';
import {translate} from '../../../../../dictionaries';

const Bonuses = ({bonuses, main}) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const icon = [IconBonus1, Coin];
    const mask = [BonusMask, BonusMask2];

    React.useEffect(() => {
        dispatch(setGetLastTournament())
    }, [])

    const tournament = useSelector(state => state.games.lastTournament);
    let count = -1

    return (
        <div className={classes.root}>
            <h1 className={classes.title}>{translate('common.bonusesTournament')}</h1>
            <div className={classes.flexBox}>
                <div className={classes.wrapBonuses} style={{display: 'flex', flexDirection: 'row', columnGap: 12}}>
                    {
                        bonuses.map((item, key) => {
                            if (count < 1 && item.label !== 'bonus-referral' && item.label !== 'bonus-promocode' && item.label !== 'bonus-personal') {
                                ++count;
                                
                                return (
                                    <BonusComponent
                                        key={key}
                                        title={getLabel(item.label)}
                                        description="" 
                                        Icon={icon[count]} 
                                        background={mask[count]}
                                        mb={!count && 19}
                                        main={main}
                                    />    
                                )
                            }                               
                        })
                    }
                </div>
                <div className={classes.wrapTournament}>
                {/* {tournament && tournament.id && <Tournament home={true} name={tournament.name} fund={tournament.fund} users={tournament.users || []} date_to={tournament.status ? tournament.date_to : 0} date_from={tournament.date_from} id={tournament.id}/> } */}
                {/* {tournament && tournament.id && <Tournament name="" fund={tournament.fund} users={tournament.users || []} date_to={tournament.status ? tournament.date_to : 0} date_from={tournament.date_from} id={tournament.id}/> } */}
                </div>
                
            </div>
        </div>
    )
}

export default Bonuses;