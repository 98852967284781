import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useRouter} from '../../../../@casino/hook/useRouter';
import { setGetPaymentStatus } from '../../store/thunk/setGetStatusPayment';
import {translate} from '../../../../dictionaries';

const ViewPayment = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const {billid} = router.match.params;

    React.useEffect(() => {
        dispatch(setGetPaymentStatus({billid}));
    }, [])
    const status = useSelector(state => state.payment.status);
    const cals = window.screen.height - 485;

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: cals}}>
            <h3 style={{
                color: '#D2D8E3',
                display: 'flex',
                fontSize: '18px',
                fontStyle: 'normal',
                alignItems: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                lineHeight: '20px',
                userSelect: 'none',
                marginBottom: '6px',
                textTransform: 'uppercase',
                margin: 0, 
                padding: 0
            }}>
                {status === 'transaction already' && translate('transaction.ended')}
                {status === 'success' && translate('transaction.successEnd')}
                {status === 'error' && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <span>{translate('transaction.errorEnd')}</span>
                <span>{translate('transaction.supportIdTransaction')}: </span>
                <span style={{color: 'red', userSelect: 'all'}}>{billid}</span>
                </div>
                }
            </h3>
            <h5 style={{
                color: '#D2D8E3',
                display: 'flex',
                fontSize: '13px',
                fontStyle: 'normal',
                alignItems: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                lineHeight: '20px',
                userSelect: 'none',
                marginBottom: '6px',
                textDecoration: 'underline',
                margin: 0, 
                padding: 0,
                cursor: 'pointer'
            }}
                onClick={() => window.location.replace(window.location.origin)}
            >{translate('transaction.backToMain')}</h5> 
        </div>
    )
}

export default ViewPayment;