import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../dictionaries';
import RulesTR from './blocks/tr';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 1140,
        margin: '120px auto',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            padding: '0 20px'
        }
    },
}))

const Regilations = () => {
    document.title = translate('title.siteRules');
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <RulesTR />
        </div>
    )

}

export default Regilations;