import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import BgRegistration from '../../../../../@casino/img/bgregistration.svg';
import {ReactComponent as Cross} from '../../../../../@casino/img/cross.svg';
import ActiveBonus from '../activeBonus';
import * as Yup from 'yup';
import {useFormik, FormikContext} from "formik";
import {useStyles} from './helper';
import { useDispatch } from 'react-redux';
import {setPostAuthorization} from '../../store/thunk/setPostAuthorization';
import {setPostRegistration} from '../../store/thunk/setPostRegistration';
import MethodUse from './block/methoduse';
import { translate } from '../../../../../dictionaries';
import { setPostConfirmationStepRegistration } from '../../store/thunk/setPostConfirmationStepRegistration';
import Confirmation from '../confirmation';

const ModalAuth = ({showModal, setShowModal, setShowModalConfirm, setLogin, showModalConfirm}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [error, setError] = React.useState(false);
    const [errorRegistration, setErrorRegistration] = React.useState(false);

    const initialValues = {
        email: '',
        email2: '',
        phone: '',
        password: '',
        active: 'auth',
        check: true,
        promocode: localStorage.getItem('referral') == 'undefined' ? '' : localStorage.getItem('referral'),
        method: 'phone',
        currency: 'UZS',
        code: '998',
        length: 9,
        country: 'UZS',
        activeBonus: '',
    }

    const onSubmit = (store) => {
        if (store.active === 'auth') {
            const tmp = {
                login: store.method === 'phone' ? store.code+''+store.phone : store.email2,
                password: store.password
            }
            dispatch(setPostAuthorization(tmp, setError));
        } else if (store.active === 'registration') {
            const tmp = {
                login: store.method === 'phone' ? store.phone : store.email,
                password: store.password,
                type: store.method,
                promocode: store.promocode,
                currency: store.currency,
                code: store.code,
                activeBonus: store.activeBonus,
                country: store.country
            }
            setLogin({
                login: store.method === 'phone' ? store.code + '' + store.phone : store.email,
                password: store.password
            });
      
            dispatch(setPostRegistration(tmp, setShowModalConfirm, setErrorRegistration))
            

        }
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email')
    });
    
    const formik = useFormik({onSubmit, initialValues, validationSchema});
    
    return (
        <Dialog
                open={showModal}
                onClose={() => {
                    setShowModal(false);
                    formik.setFieldValue('email', '');
                    formik.setFieldValue('phone', '');
                    formik.setFieldValue('promocode', '');
                    formik.setFieldValue('password', '');
                    formik.setFieldValue('active', 'auth');
                    formik.setFieldValue('method', 'phone');
                    formik.setFieldValue('check', true);
                }}
                maxWidth={1000}
            >
                <FormikContext.Provider value={formik}>
                    <form onSubmit={formik.handleSubmit} style={{overflow: 'hidden auto'}}>
                        <div style={{
                            backgroundColor: '#001E5B', 
                        }}
                            className={classes.root}    
                        >
                            <Cross className={classes.cross} onClick={() => setShowModal(false)}/>
                            <div className={classes.wrapTitle} style={{marginTop: 30}}>
                                <h3 className={classes.title}>{translate('common.information')}</h3>
                            </div>
                            <div className={classes.wrapRootBody}>
                                <MethodUse 
                                    formik={formik} 
                                    setError={setError} 
                                    setErrorRegistration={setErrorRegistration} 
                                    error={error} 
                                    errorRegistration={errorRegistration}
                                />
                            </div>
                        </div>
                        <Confirmation showModalConfirm={showModalConfirm} setShowModalConfirm={setShowModalConfirm} initial={formik.values}/> 
                    </form>
                </FormikContext.Provider>
            </Dialog> 
    )
}

export default ModalAuth;