import React from 'react';

const ResponsibleTR = () => {
    return (
        <div class="page-content rules">
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>ACTIV AGUA ALIANZA LIMITADA</h2>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>SORUMLULUK OYUNU NASIL SAĞLAYACAĞIZ?</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Çevrimiçi kumarhane kumarına katılım her zaman eğlence amaçlı olmalıdır. Bununla birlikte, kumar sırasında kendi kontrolünü kaybetme eğiliminde olan belirli bir insan yüzdesi vardır. Kumar oynamaya başlamadan önce, kumarın asla bir gelir kaynağı veya borçları ödeme aracı olarak görülmemesi gerektiğini anlamak önemlidir. Online casinolarda geçirilen süreyi ve orada harcanan para miktarını günlük olarak takip etmeniz önerilir.
            Ödeyebileceğinizden daha fazla para harcamaya başladığınızı fark ederseniz veya oyun günlük aktivitelerinizi engellemeye başlarsa, kendi oyun aktivitelerinizde Kişisel Limitler belirlemek gibi yardımcı olabilecek bazı önlemlere dikkat etmenizi şiddetle tavsiye ederiz. , oyundan Kendini Dışlama talebinde bulunmak ve güvenilir üçüncü taraf kuruluşlardan yardım ve destek istemek.
            Kumar bağımlılığı belirtileri için test edilebilirsiniz.
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>HARCAMALARI VEYA KAYIPLARI NASIL SINIRLAYABİLİRİM?</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
              Sorumlu oyun politikamızın bir parçası olarak Kişisel Limitler belirleme seçeneği sunuyoruz. Hesabınıza yatırabileceğiniz, kaybedebileceğiniz, bahis oynayabileceğiniz, oyun sırasında harcayabileceğiniz veya oyun etkinliği sonucunda kazanabileceğiniz miktara limit koyabilirsiniz. Bu ayarlara hesabınızın içindeki "Kişisel Kısıtlamalar" bölümünden erişebilirsiniz.
            Kısıtlamalar herhangi bir zamanda değiştirilebilir. Azaltma limitleri hemen yürürlüğe girerken, limitleri artırmak sadece e-posta ile onaylandıktan sonra ve sadece aynı türden bir önceki limit sona erdikten sonra, aceleci kararları önlemek için mümkün olacaktır. Kişisel Limitlerin ayarlanmasıyla ilgili daha fazla bilgiye veya yardıma ihtiyacınız varsa, lütfen destek ekibimizle iletişime geçin.
            Mevduat miktarını sınırlamak. Günlük, haftalık veya aylık mevduat miktarını sınırlamak.
            Kayıpların Sınırlandırılması. Kumarhanede günlük, haftalık veya aylık kayıp limiti. Lütfen kayıp miktarının, sonuç olarak alınan kazançlar değil, ilk depozito miktarı tarafından belirlendiğini unutmayın. Örneğin, hesabınıza 50€ yatırırsanız, 10€'luk bir Zarar Limiti belirlerseniz ve ardından 1.000€ kazanırsanız, limit orijinal depozitoya bağlı olduğundan, 1.000€ bakiyenizde yine de 10€'yu aşan kayıplara maruz kalabilirsiniz. , kazanç değil.
            Bahis Tutarı Limiti. Günlük, haftalık veya aylık bahis miktarını sınırlamak.
            Oyun Oturum Süresi Sınırı. Oyuna katılım süresinin dakika cinsinden sınırlandırılması.
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>KAHVALTI MI İHTİYACINIZ VAR?</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Soğutma dönemi. Soğutma Süresini 1 hafta, 1 ay, 3 ay veya 6 ay olarak ayarlayabilirsiniz. Aktif Bekleme Süresi boyunca, para yatırma işlemi yapamayacaksınız ve tüm promosyon tekliflerinden hariç tutulacaksınız, ancak mevcut fonları çekebileceksiniz. Hesabın Bekleme süresi hemen yürürlüğe girer. Bekleme Süresi sona erdiğinde, hesap otomatik olarak etkinleştirilecektir.
                Oyundan kendini dışlama dönemi. 6 ay, 9 ay veya 1 yıl olarak bir kendini dışlama süresi belirleyebilirsiniz. Bunun hemen ardından Oyun Hesabınız devre dışı bırakılacak ve belirli bir süre boyunca tüm promosyon tekliflerinden dışlanacaksınız. Oyundan aktif olarak çıkarılma süresi boyunca, para yatırma veya çekme işlemi yapamazsınız.Sürekli bir süre için kumarhaneden kendi kendinizi çıkarmayı seçerseniz, bakiyede kalan fonların ödeneceğini lütfen unutmayın. kumarhane sınırlarına uygun olarak dışarı. Belirli bir süre için kendi kendini dışlamaya karar verirseniz, bakiyede kalan fonlar kumarhanenin sınırlarına uygun olarak ancak kendi kendini dışlama süresinin sona ermesinden sonra ödenecektir.
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>OYUNDAN KENDİNİ DIŞLAMA NEDİR?</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Oyundan kendi kendini dışlama süresi, hesabınıza erişiminizi engellemek ve promosyon malzemeleri göndermeyi durdurmak için tüm önlemleri almamızı bildirir. Ayrıca, destek ekibimizle iletişime geçebilir ve Web Sitesinde belirli bir süre veya kalıcı olarak kumar oynamayı bırakma kararınızı bize bildirebilirsiniz.
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>MEVCUT TANIMLAMA</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Kumarhane, reşit olmayanların Web Sitemize kaydolmaya ve oynamaya çalışmasını önlemek için mevcut tüm yöntemleri kullanarak yalnızca 18 yaşından büyük oyuncuları kabul eder. Casino, bir kimlik belgesi ibrazını talep etme hakkını saklı tutar; Oyuncunun yasal yaşını doldurmaması durumunda, Web Sitesine erişimden derhal mahrum bırakılacaktır. Ancak, İnternet'in doğası ve geniş kullanılabilirliği nedeniyle, reşit olmayanların çevrimiçi kumarhanelere kaydolma ve oynama fırsatına sahip olduğunu anlıyoruz. Bu nedenle, ebeveynleri çocuklarını oyun web sitelerine ücretsiz erişimden korumak için işbirliği yapmaya şiddetle teşvik ediyoruz. Özel yazılımların kullanılması bu konuda yardımcı olabilir. Daha fazla bilgi için aşağıdaki web sitelerini ziyaret edebilir ve tavsiye ve destek için aşağıdaki kuruluşlardan herhangi biriyle iletişime geçebilirsiniz:
            </p>
            <ul style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
            <li>CyberPatrol</li>
            <li>GamBlock®</li>
            <li>Solid Oak Software</li>
            <li>Net Nanny</li>
            </ul>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>GERÇEKLİK İNCELEME NEDİR?</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Keyifli bir eğlence sırasında oyun için aşırı tutku mümkündür. Bu nedenle, oyun sırasında her saat başı size Casino'da ne kadar zaman geçirdiğinizi hatırlatacak bildirimler göndereceğiz. Aynı anda kaç oyun oynuyor olursanız olun, bir mesaj size mevcut durumu bildirecektir. Bu politika, bahislerinizi değerlendirmeniz ve oyunu bir süreliğine duraklatma ihtiyacını değerlendirmeniz için size fırsat ve zaman vermeyi amaçlamaktadır.
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>DIŞ YARDIM MI İHTİYACINIZ VAR?</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Ayrıca tavsiye ve destek için aşağıdaki kuruluşlardan herhangi biriyle iletişime geçebilirsiniz:
            </p>
            <ul  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
            <li>Gamblers Anonymous</li>
            <li>GamCare</li>
            <li>Gambling Therapy</li>
            </ul>
        </div>
    )
}

export default ResponsibleTR;