
import {getPaymentStatus} from '../api/getPaymentStatus';
import {setGetPaymentStatusAction} from '../action';

export const setGetPaymentStatus = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getPaymentStatus(params);
            
            dispatch(setGetPaymentStatusAction(data.status));
            
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}