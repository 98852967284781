import React from 'react';
import { useDispatch } from 'react-redux';
import { setGetJackpotList } from '../../store/thunk/setGetJackpotList';
import Game from './block/game';
import Form from './block/form';
import { setGetBonusesNotification } from '../../store/thunk/setGetBonusesNotification';
import { translate } from '../../../../../dictionaries';

const GameId = ({profile, setLoad}) => {
    document.title = translate('title.game');
    const dispatch = useDispatch();

    window.addEventListener("message", (e, key) => {
        if (e.data === 'GAME_MODE:CLOSED' || e.data === 'closeFrame') {
            window.location.replace(window.location.origin)
        }    

        if (e.data === 'GAME_MODE:READY') {
            if (profile) {
                dispatch(setGetJackpotList({
                    group: profile.groups_cashiers 
                }));
                dispatch(setGetBonusesNotification())
            }
        }
    }, false);

    return (
        <div style={{height: '100vh', overflow: 'hidden', position: 'relative'}}>  
            <Game />                         
            <Form profile={profile}/>
        </div>
    )
}

export default GameId;