import Calendar from '../../../../@casino/img/calendar.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    field: {
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#FFFFFF',
        background: 'inherit',
        padding: '15px 19px',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },

    calendar: {
        width: 414,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)',
        },
        '&::-webkit-calendar-picker-indicator': {
            color: 'rgba(0, 0, 0, 0)',
            opacity: 1,
            display: 'block',
            background: `url(${Calendar}) no-repeat`,
            width: 18,
            height: 18,
            cursor: 'pointer'
        }  
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',                    
        marginBottom: 35
    },
    wrapTitle: {
        display: 'flex', 
        justifyContent: 'center', 
        borderBottom: '1px solid rgba(255,255,255,0.2)',
    },
    description: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        marginRight: 28
    },
    wrapDescription: {
        display: 'flex', 
        alignItems: 'center',
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        }
    },
    labelHelper3: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        userSelect: 'none',

    },
    labelHelper2: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 240,
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        }
    },
    field2: {
        width: 414,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    savebtn: {
        padding: '10px 40px', 
        background: '#FF005C', 
        borderRadius: '50px',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        userSelect: 'none',
        cursor: 'pointer'
    }
}))

const getDate = (value) => {
    const date = new Date(+value);
    const day = date.getDate() < 10  ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1)  : date.getMonth() + 1;
    const year  = date.getFullYear();

    return `${year}-${month}-${day}`
}

export {
    useStyles,
    getDate
}