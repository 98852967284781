import {types} from './types';

export const setGetListTournamentAction = (payload) => {
  return {
      type: types.SET_LIST_TOURNAMENT,
      payload,
    }
}

export const setGetViewTournamentAction = (payload) => {
  return {
      type: types.SET_VIEW_TORUNAMENTX,
      payload,
    }
}
