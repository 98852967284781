import React from 'react';
import Tournament from '../../../../../../../@casino/ui/tournament';
import { useDispatch, useSelector } from 'react-redux';
import {useStyles} from './helper';
import { setGetListTournament } from '../../../../../tournament/store/thunk/setGetListTournament';
import TournamentMobile from '../../components/tournamentMobile';
import { translate } from '../../../../../../../dictionaries';

const PromotionsList = ({auth}) => {
    document.title = translate('title.tournament')
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = React.useState(1);

    React.useEffect(() => {
        dispatch(setGetListTournament({
            page: 1,
            rowsPerPage: 4
        }));
    }, [])

    const tournament = useSelector(state => state.tournament.list);
    const cals = window.screen.height - 930;

    return (
        <div style={{background: 'rgba(2, 0, 4, 0.55)', marginBottom: 12}}>
            {
                tournament.tournaments.length === 0 && 
                <div className={classes.info} style={{height: cals}}>
                    <span className={classes.infoTitle} >
                        {translate('lottery.infoNotFound')}
                    </span>
                </div>
            }
            <div className={classes.wrapTournament}>
                {
                    tournament.tournaments.map((item, key) => <TournamentMobile item={item} key={key} />) 
                }
            </div>
            
            {/* <div className={classes.wrapDots} >
                <Dots 
                    nextPage={() => {
                        dispatch(setGetListTournament({
                            page: page + 1,
                            rowsPerPage: 4
                        }));
                        setPage(page + 1);
                    }} 
                    prevPage={() => {
                        dispatch(setGetListTournament({
                            page: page - 1,
                            rowsPerPage: 4
                        }));
                        setPage(page - 1);
                    }}
                    countPage={tournament.countPage}
                    page={page}
                />
            </div> */}
        </div>
    )
}

export default PromotionsList;