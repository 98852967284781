import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        width: 1140, 
        height: 500, 
        backgroundColor: '#142A8F', 
        marginBottom: 113, 
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
        borderRadius: '3px',
    },
    wrapLabel: {
        display: 'flex', justifyContent: 'center', paddingTop: 101
    },
    label: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    wrapBody: {
        display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 17
    },
    tournamentName: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#7B61FF',
    },
    fundTitle: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FF005C',
        marginTop: 35
    },
    fundAmount: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '80px',
        lineHeight: '98px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.7)',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '0px 0px',
        padding: '20px 80px',
        backgroundColor: '#FF005C',
        boxShadow: '0px 4px 10px #D3193A',
        borderRadius: '50px',
        cursor: 'pointer'
    }
}))

export {
    useStyles,
}