import React from 'react';
import Label from '../../../../../@casino/ui/label';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import {translate} from '../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    wrapLabel: {
        display: 'flex',
        justifyContent: 'center',
    },
    gamegame: {
        position: 'absolute', 
        top: 0, 
        width: 210, 
        height: 141, 
        background: 'rgba(0,0,0,.5)', 
        zIndex: 100, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity .3s ease',
        '&:hover': {
            opacity: 1,
            transition: 'opacity .3s ease',
        }
    },
    wrapGame: {
        paddingBottom: 60, 
        display: 'flex', 
        justifyContent: 'flex-start', 
        flexWrap: 'wrap',  
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            padding: '0 10px',
            marginBottom: 70
        }
    },
    image: {
        width: 210, 
        height: 141, 
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            width: 154,
            height: 104
        }
    },
    game: {
        width: 210, 
        height: 141,
        borderRadius: 3, 
        position: 'relative', 
        transition: 'all .3s ease',
        [theme.breakpoints.down('sm')]: {
            width: 154,
            height: 104
        }
    }
}))

const Game = ({tournament}) => {
    const classes = useStyles();
    const router = useRouter();
   
    return (
        <>
            <div className={classes.wrapLabel}>
                <Label title={translate('tournament.game')} />
            </div>
            <div className={classes.wrapGame}>
                {
                    tournament.tournament.games && tournament.tournament.games.map((item, key) => (
                        <div
                            key={key}
                            className={classes.game}>
              
                            <div className={classes.gamegame}>
                                <span 
                                    onClick={() => window.location.replace(window.location.origin + `/games/${item.uuid}`)}
                                    style={{ cursor: 'pointer', userSelect: 'none', marginTop: 5, background: '#FF005C', padding: '10px 40px', borderRadius: '50px', color: '#fff', fontFamily: 'Montserrat', fontWeight: 500}}>
                                   {translate('common.gaming')}
                                </span>
                            </div>
                           
                            <img src={item.image}
                            className={classes.image} alt=""/>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default Game;