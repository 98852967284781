import React from 'react';
// import {useStyles} from './helper';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as ArrowDown} from '../../img/arrowDown.svg';

const useStyles = makeStyles(() => ({
    wrapHelperPhone: {
        display: 'flex', 
        position: 'relative', 
        width: '100%'
    },
    helper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        userSelect: 'none',
        color: '#FFFFFF',
    },
    field: {
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#FFFFFF',
        background: 'inherit',
        padding: '5px 17px 5px',
        marginLeft: 12,
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        width: '70%', 
        '&::placeholder': {
            color: '#8e9ec2', 
        }
    },
    labelField: {
        display: 'flex', 
        flexDirection: 'column',
        marginBottom: 15,
    },
    mb10: {
        marginBottom: 10
    },
    helperPhone: {
        position: 'absolute',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#7B61FF',
        top: 15,
        left: 17,
        userSelect: 'none',
    },
    wrapPhone: {
        overflowY: 'scroll', 
        display: 'flex', 
        position: 'absolute', 
        // padding: 10, 
        top: 0, 
        left: 0, 
        background: '#0034A5', 
        zIndex: 100, 
        width: 100, 
        height: 150, 
        display: 'flex', 
        flexDirection: 'column'
    },
    codePhone: {
        color: '#fff', 
        fontSize: 14, 
        padding: '5px 10px',
        lineHeight: '20px',
        width: '100%',
        cursor: 'pointer',
        zIndex: 10,
        // background: 'rgba(0,52,165, 1)',
        '&:hover': {
            background: 'rgba(0, 30, 91)'
        }
    }
}))



const TextFieldPhone = ({name, value, title, onChange, placeholder, formik, helper}) => {
    const classes = useStyles();
    const [view, setView] = React.useState(false);
    const [code, setCode] = React.useState({code: '998',   label: '+998 (UZB)',    length: 9,      country: 'UZB'},);

    const handleChangeCode = (val) => {
        setCode(val);
        formik.setFieldValue('code', val.code);
        formik.setFieldValue('country', val.country);
        formik.setFieldValue('length', val.length);
        formik.setFieldValue('activeCode', JSON.stringify(val))
        setView(false)
    }

    const arrayCodePhone = [
        {code: '998',   label: '+998 (UZB)',    length: 9,      country: 'UZB'},
    ]

    return (
        <label htmlFor={name} className={classes.labelField}>
            <span 
                className={clsx(classes.helper, classes.mb10)} 
            >{title} <br/><span style={{fontSize: 12, color: 'red'}}>{helper}</span></span>
            <div className={classes.wrapHelperPhone}  style={{position: 'relative'}}>
                <div>
                    <select 
                        onChange={(v) => handleChangeCode(JSON.parse(v.target.value))} 
                        value={formik.values.activeCode} 
                        style={{width: '100%', height: 40, border: '1px solid #7B61FF', borderRadius: 4, outline: 'none', background: 'rgba(0, 30, 91)', color: '#fff', paddingLeft: 10}}
                    >
                        
                        {arrayCodePhone.map((item, key) => <option key={key} value={JSON.stringify(item)}>{item.label}</option>)}
                    </select>
                </div>
                <input 
                    type="text" 
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={(v) => onChange(v, code)}
                    className={classes.field}
                />
            </div>    
        </label>
    )
}

export default TextFieldPhone;