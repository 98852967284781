import React from 'react';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import MethodReplenishment from './block/method';
import ReplenishmentBlock from './block/replen_block';
import {useFormik, FormikContext} from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { setReplenishmentCreate } from './store/thunk/setReplenishmentCreate';
import { setGetListPayment } from './store/thunk/setGetListPayment';
import {useRouter} from '../../../@casino/hook/useRouter';
import { translate } from '../../../dictionaries';

const Replenishment = () => {
    document.title = translate('title.replenishment')
    const dispatch = useDispatch();
    const router = useRouter();
    const [selected, setSelected] = React.useState(false);
    const payment = useSelector(state => state.replenishment.list);

    const initialValues = {
        amount: 0,
        method: 'visa-qiwi',
        cardType: '',
        min: 0
    }

    React.useEffect(() => {
        dispatch(setGetListPayment());
    }, [])

    const onSubmit = (store) => {
        dispatch(setReplenishmentCreate(store))
    }
   
    const formik = useFormik({onSubmit, initialValues});
    const groups = useSelector(state => state.profile.view);

    return (
        <WinPageBlock 
            title={translate('common.paymentReplenishment')}
        >
            <div style={{marginTop: 41}}>
                <FormikContext.Provider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                        {
                            groups.groups_cashiers === 'all' && !selected &&
                            <MethodReplenishment setSelected={setSelected} formik={formik} currency={groups.currency} payment={payment}/>
                        }   
                        {
                            (!!!payment.length ||  groups.groups_cashiers !== 'all') && 
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300}}>
                                <span style={{
                                    color: '#D2D8E3', 
                                    fontFamily: 'Montserrat, sans-serif', 
                                    fontSize: 18, 
                                }}>{translate('common.notPayment')}</span>
                            </div>
                        }
                        {
                            selected && groups.groups_cashiers === 'all' &&
                            <ReplenishmentBlock formik={formik} currency={groups.currency} setSelected={setSelected}/>
                        }
                  </form>
                </FormikContext.Provider>
            </div>
        </WinPageBlock>
    )
}

export default Replenishment;