import React from 'react';
import {useStyles} from './helper';
import clsx from 'clsx';

const TextFieldEmail = ({title, name, value, placeholder, onChange, errors}) => {
    const classes = useStyles();

    return (
        <label htmlFor={name} className={classes.labelField}>
            <span 
                className={clsx(classes.helper, classes.mb10)} 
            >{title}</span>
            <input 
                type="text" 
                name={name} 
                required
                pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                id={name}
                placeholder={placeholder}
                value={value}
                onChange={(v) => onChange(v)}
                className={classes.field}
                style={{
                    borderColor: errors && errors.email === 'Invalid email' && 'red'
                }}
            />
        </label>
    )
}

export default TextFieldEmail;