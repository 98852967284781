import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetBonusesList } from '../layout/store/thunk/setGetBonusesList';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import WinTable from '../../../@casino/ui/WinTable';
import { getRows, columns, getRowsMobile, columnsMobile } from './helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../dictionaries';
import { ReactComponent as AllDeposit } from './img/100percentofdeposit.svg';
import clsx from 'clsx';
import FS30 from './img/30fs.png';
import FS50 from './img/fs.png';
import Percent from './img/percent.png';
import { Button, Typography } from '@material-ui/core';
import { setPostBonusActivate } from '../../../store/bonus/thunk/setPostBonusActivate';
import { setPostCreateFreespin } from '../../../store/freespin/thunk/setPostCreateFreespin';
import Modal from '../../freespin/Modal';
import {useFormik, FormikContext} from "formik";
import { setPostPinsale } from '../../../store/bonus/thunk/setPostPinsale';
import FS from './img/fs3.png';
import BonusesRegistration from './bonus_registration';
import BonusesFreespin from './freespin';
import { useRouter } from '../../../@casino/hook/useRouter';
import FreespinWin from './win_freespin';
import { setPostCreateFreespinPlayed } from '../../../store/freespin/thunk/setPostCreateFreespinPlayed';
import { setPostBonusConfirm } from '../../../store/freespin/thunk/setPostBonusConfirm';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 109,
    },
    wrapTitle: {
        marginBottom: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        userSelect: 'none'
    },
    bonus: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#001137',
        borderRadius: '3px',
        width: 300,
        height: 120,
        marginRight: 20
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '51%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        userSelect: 'none',
        paddingRight: 20
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    },
    iconBonus: {
        width: 117,
    },
    wrapBonusDesktop: {
        display: 'flex', 
        alignItems: 'center',
        marginBottom: 31,
        [theme.breakpoints.down('sm')]: {
            display: 'flex', 
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center', 
            marginBottom: 40
        }
    },
    field2: {
        width: 300,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        }
    },
}))

const Bonuses = () => {
    document.title = translate('title.bonuses')
    const dispatch = useDispatch();
    const classes = useStyles();
    const router = useRouter();
    
    const profile = useSelector(state => state.profile.view);
    const [loading, setLoading] = React.useState(false);

    return (
        <div>
            <WinPageBlock
                title={translate('navigation.bonuses')}
            >
                {
                    profile?.connection_groups !== "true" && !profile?.active_bonuses_registration && !profile?.dublicate && (
                        <div className={classes.wrapTitle}>
                            <h3 className={classes.title}>{translate('common.selectBonus1')}</h3>
                        </div>
                    )
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.registration) + 864000000 > new Date().getTime()) && !profile?.dublicate && !Boolean(profile?.active_bonuses_registration) && <BonusesRegistration profile={profile} />
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs?.freespin_id) + 172800000 > new Date().getTime()) && Boolean(profile?.fs?.freespin_id && profile?.fs?.quantity > 0) && <BonusesFreespin profile={profile} btn={translate('common.gameBtn1')} onSubmit={() => router.history.push(`/games/${profile?.fs?.game_uuid}`)} date={profile?.fs?.freespin_id} max_date={172800000} loading={loading}/>
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs?.date_from) + 172800000 > new Date().getTime()) && Boolean(!profile?.fs?.freespin_id && profile?.fs?.status) && <BonusesFreespin profile={profile} btn={translate('common.activate')} onSubmit={() => dispatch(setPostCreateFreespin({fs_id: profile?.fs?.id}, setLoading))} date={profile?.fs?.date_from} max_date={172800000} loading={loading} />
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs?.freespin_id) + 172800000 > new Date().getTime()) && Boolean(profile?.fs?.freespin_id && Number(profile?.fs?.quantity) === 0 && profile?.fs?.status && profile?.fs?.win_amount) && <FreespinWin profile={profile} btn={translate('common.activate')} onSubmit={() => {setLoading(true); if (!loading) {dispatch(setPostCreateFreespinPlayed({ fs_id: profile?.fs?.id} ))}}} date={profile?.fs?.freespin_id} max_date={172800000}  wager={profile?.fs?.wager_amount} amount={profile?.fs?.win_amount}/>
                }
                {
                    profile?.connection_groups !== "true" && (Number(profile?.fs_played?.date_from) + 172800000 > new Date().getTime()) && Boolean(profile?.fs_played?.status) && <FreespinWin profile={profile} onSubmit={() => {
                        setLoading(true)
                        if (!loading) {
                            dispatch(setPostBonusConfirm({fs_played_id: profile?.fs_played?.id}))
                        }
                    }} date={profile?.fs_played?.date_from} max_date={172800000} wager={profile?.fs_played?.wager_count} wager_amount={profile?.fs_played?.wager} wager_played={profile?.fs_played?.wager_played > profile?.fs_played?.wager ? profile?.fs_played?.wager : profile?.fs_played?.wager_played} amount={profile?.fs_played?.amount}/>
                }
                {
                    profile?.connection_groups !== "true" && !profile?.active_bonuses_registration && !profile?.dublicate && (
                        <>
                            <div style={{marginTop: 20}}>
                                {
                                    !profile?.count_deposit && (
                                        <>
                                            <div className={classes.wrapBonusDesktop}>
                                                <div className={clsx(classes.bonus, classes.activeBonus)} >
                                                    <img src={Percent} className={classes.iconBonus} alt="fs30" />
                                                    <span className={clsx(classes.textBonus, classes.colorTextActiveBonus)}>100% {translate('common.deposit')}</span>
                                                </div>
                                                <div>
                                                    <Button color="secondary" variant="contained" onClick={() => dispatch(setPostBonusActivate({ bonuses: 'alldeposit' }))}>{translate('common.activateBonus')}</Button>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </>
                    )
                }
            </WinPageBlock>
        </div>
    )
}

export default Bonuses;