import EN from './en.json';
import UZB from './uz.json';

export const translate = (values) => {
    const language = localStorage.getItem('language');
    
    switch (language) {
        case 'UZ': {
            if (!UZB[values]) {
                return values
            }
            return UZB[values];
        }
        case 'US': {
            if (!EN[values]) {
                return 'ERROR_TEXT_TRANSALATE'
            }
            return values; 
        }
    }
}

export const i18n = (replaceObject, dictionary) => {
    let textTranslate = translate(dictionary);
    for (let key in replaceObject) {
        textTranslate = textTranslate.replace(`{${key}}`, replaceObject[key]);
    }
    return textTranslate;
}