import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 53
        }
    },
    wrapTitle: {
        display: 'flex', 
        justifyContent: 'center', 
        borderBottom: '1px solid rgba(255,255,255,0.2)'
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',                    
        marginBottom: 35
    },
    mt50: {
        marginTop: 50,
        [theme.breakpoints.down('sm')]: {
            marginTop: 20
        }
    },
    description: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    mt26: {
        marginTop: 26
    },
    wrapDescription: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center', 
        }
    }
}))

const WinPageBlock = ({children, description, title, Icon}) => {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <div className={classes.wrapTitle}>
                <h3 className={classes.title}>{title}</h3>
            </div>
            
            <div className={classes.mt50}>
                {
                    !!description &&
                    <div className={classes.wrapDescription}>
                        <h3 className={classes.description}>{description} </h3>
                        {
                            Icon && <Icon />
                        }
                    </div>
                }
                
                <div className={classes.mt26}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default WinPageBlock;