import React from 'react';
import BonusComponent from '../../../../../@casino/ui/bonus';
import { useDispatch, useSelector } from 'react-redux';
import { setGetListBonuses } from '../../store/thunk/setGetListBonuses';
import MainPageBlock from '../../../../../@casino/ui/mainPageBlock';
import {icon, mask, getLabel} from './helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', 
        justifyContent: 'space-between', 
        flexWrap: 'wrap', 
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
}))

const BonusesList = ({auth}) => {
    document.title = translate('title.bonuses')
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetListBonuses());
    }, [])

    const bonuses = useSelector(state => state.bonuses.listBonuses);
    const cals = window.screen.height - 780;

    return (
        <MainPageBlock
            title={translate('navigation.bonuses')}
        >
            <div style={{
                minHeight: cals, }}
                className={classes.root}>
                {
                    bonuses.map((item, key) => {
                        if (item.label !== 'bonus-referral' && item.label !== 'bonus-promocode' && item.label !== 'bonus-personal') {
                            return (
                                <BonusComponent
                                    key={key}
                                    title={getLabel(item.label)}
                                    description={item.description}
                                    Icon={icon[key%6]} 
                                    background={mask[key%6]}
                                    mb={19}
                                />
                            )
                        }
                    })
                }
            </div>
        </MainPageBlock>
    )
}

export default BonusesList;