
import {getViewTournament} from '../api/getViewTournament';
import {setGetViewTournamentAction} from '../action';

export const setGetViewTournament = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getViewTournament(params);
            if (data.status === 'success') {
                dispatch(setGetViewTournamentAction(data.data));
            }
          
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}