import React from 'react';
import {ReactComponent as GoldJackpot} from '../../../../../@casino/img/gold.svg';
import {ReactComponent as PlatinumJackpot} from '../../../../../@casino/img/platinum.svg';
import {ReactComponent as SilverJackpot} from '../../../../../@casino/img/silver.svg';
import {ReactComponent as BronzeJackpot} from '../../../../../@casino/img/bronze.svg';
import JackpotGold from '../../../../../@casino/img/gold.svg';
import JackpotPlatinum from '../../../../../@casino/img/platinum.svg';
import JackpotSilver from '../../../../../@casino/img/silver.svg';
import JackpotBronze from '../../../../../@casino/img/bronze.svg';
import { useSelector } from 'react-redux';
import { translate } from '../../../../../dictionaries';

const BlockJackpot = () => {
    const [count, setCount] = React.useState(0);
    
    const jackpot = useSelector(state => state.games.list_jackpot);

    React.useEffect(() => {
        const interval = setTimeout(() => {
            if (count < jackpot.length-1) {
                setCount(count+1)
            } else {
                setCount(0)
            }
        }, 2500);
        
        return () => clearTimeout(interval);
    }, [count, jackpot]);

    const getJackpot = (val) => {
        switch (val) {
            case 'gold': return JackpotGold;
            case 'silver': return JackpotSilver;
            case 'platinum': return JackpotPlatinum;
            case 'bronze': return JackpotBronze;
        }
    }
    const width = jackpot.length * 100;

    return (
        <div style={{background: 'rgba(2,0,36,.8)', padding: '10px 0 10px'}}>
            <div  style={{marginBottom: 12, display: 'flex', justifyContent: 'center'}}>
            <span style={{textTransform: 'uppercase', color: '#fff', fontFamily: 'Montserrat, sans-serif'}}>{jackpot.length > 1 ? translate('navigation.jackpot') : translate('navigation.jackpots') }</span>
            </div>
            
            <div style={{display: 'flex', alignItems: 'center', width: `${width}%`, justifyContent: 'center'}}>
                {
                    jackpot && jackpot.map((item, key) => {
                        return (
                            <div key={key} style={{display: 'flex', justifyContent: 'center', width: '100%', right: count*window.screen.width, position: 'relative', transition: '.3s ease'}}>
                                {/* <span style={{fontFamily: 14, textTransform: 'uppercase', color: 'gold'}}>{translate('navigation.jackpot')}</span> */}
                                {/* <PlatinumJackpot style={{height: 60}} /> */}
                                <div style={{height: 62, width: 200, background: `url(${getJackpot(item.jackpot)})`, backgroundSize: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <span style={{fontFamily: 'Montserrat, sans-serif', color: '#fff', fontSize: 30}}>{new Intl.NumberFormat('ru-RU').format(item.primary_amount.toFixed(2))}</span>
                                </div>
                            </div>
                        )
                    })
                }
                
            </div>
        </div>
    )
}

export default BlockJackpot;