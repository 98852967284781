import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 560, 
        height: 288, 
        filter: 'drop-shadow(0px 10px 20px rgba(0, 11, 36, 0.5))', 
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 560,
        }
    },
    wrapTitleBlock: {
        padding: '32px 37px 0px 40px', 
        width: 320,
        minHeight: 155,
        marginBottom: 30
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        margin: 0,
        padding: 0,
        color: "#fff",
        textShadow: '2px 2px 2px #000000'
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: '20px',
        color: '#fff',
        marginTop: 20,
        marginBottom: 7,
        textShadow: '2px 2px 2px #000000'
    },
    view: {
        textDecoration: 'underline', 
        color: '#D2D8E3', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 12, 
        cursor: 'pointer', 
        userSelect: 'none'
    },
    wrapBtn: {
        paddingLeft: 40, 
        paddingRight: 30, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
    },
    btn: {
        padding: '10px 26px', 
        background: '#FF005C', 
        borderRadius: '50px', 
        color: '#fff', 
        fontFamily: 'Montserrat, sans-serif', 
        cursor: 'pointer', 
        userSelect: 'none',
        transition: 'background .3s ease',
        '&:hover': {
            background: '#ED074C',
            transition: 'background .3s ease'
        }
    },
    icon: {
        width: 159, 
        height: 150, 
        position: 'absolute', 
        top: 69, 
        right: 30,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))

export {
    useStyles,
}