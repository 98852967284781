import {getListVerification} from '../api/getListVerification';
import { setVerificationListAction } from '../action';

export const setGetListVerification = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getListVerification(params);

            if (data.status === 'success') {
                dispatch(setVerificationListAction(data.data))
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}