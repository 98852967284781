import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import WithBlock from './block/with_block';
import MethodWithdrawal from './block/method';
import {useFormik, FormikContext} from "formik";
import { setPostWithdrawalCreate } from './store/thunk/setPostWithdrawalCreate';
import Dialog from '@material-ui/core/Dialog';
import { setGetListPayment } from '../replenishment/store/thunk/setGetListPayment';
import {useRouter} from '../../../@casino/hook/useRouter';
import { translate } from '../../../dictionaries';

const Withdrawal = () => {
    document.title = translate('title.withdrawal');
    const dispatch = useDispatch();
    const groups = useSelector(state => state.profile.view);
    const router = useRouter();
    const [selected, setSelected] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorLimit, setErrorLimit] = React.useState(false);

    const payment = useSelector(state => state.replenishment.list);

    React.useEffect(() => {
        dispatch(setGetListPayment());
    }, [])   

    const initialValues = {
        amount: 0,
        method: 'visa-qiwi',
        card_number: '',
        type_operation: 'visa-rus'
    }

    const onSubmit = (store) => {
        dispatch(setPostWithdrawalCreate(store, setSuccess, setError));
    }
   
    const formik = useFormik({onSubmit, initialValues});
    
    React.useEffect(() => {
        console.log(formik.values.type_operation);
        if (formik.values.type_operation !== 'usdt-trc' && formik.values.type_operation !== 'ethereum' && formik.values.type_operation !== 'litecoin') {
            formik.setFieldValue('card_number', groups.card_number);
        } else if (formik.values.type_operation === 'usdt-trc' || formik.values.type_operation === 'ethereum' || formik.values.type_operation === 'litecoin') {
            formik.setFieldValue('card_number', "");
        }
    }, [groups.card_number, formik.values.type_operation]);

    return (
        <WinPageBlock 
            title={translate('navigation.withdrawal')}
        >
            <FormikContext.Provider value={formik}>
                <form onSubmit={formik.handleSubmit} >
                    {        
                        !selected && groups.groups_cashiers === 'all' &&
                        <MethodWithdrawal setSelected={setSelected} formik={formik}/>
                    }
                    {
                        !!!payment.length && groups.groups_cashiers !== 'all' &&
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 300}}>
                            <span style={{
                                color: '#D2D8E3', 
                                fontFamily: 'Montserrat, sans-serif', 
                                fontSize: 18, 
                            }}>{translate('common.notPayment')}</span>
                        </div>
                    }
                    {
                        selected &&
                        <WithBlock formik={formik} />
                    }
                </form>
                <Dialog
                    open={success}
                    onClose={() => {setSuccess(false); window.location.replace(window.location.origin + '/profile/account')}}
                >
                    <div style={{background: '#7B61FF', padding: 20}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FFFFFF'
                        }}>{translate('common.reportWithdrawal')}</span>     
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{
                                marginTop: 12,
                                borderRadius: '50px',
                                padding: '5px 60px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FF005C',
                                color: '#FFFFFF',
                                cursor: 'pointer'
                            }}
                                onClick={() => {setSuccess(false);        window.location.replace(window.location.origin + '/profile/account')}}
                            >{translate('common.ok')}</span>
                        </div>   
                        
                    </div>
                </Dialog>
                <Dialog
                    open={error}
                    onClose={() => setError(false)}
                >
                    <div style={{background: '#7B61FF', padding: 20}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FFFFFF'
                        }}>{translate('common.errorWithdrawal')}.</span>     
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{
                                marginTop: 12,
                                borderRadius: '50px',
                                padding: '5px 60px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FF005C',
                                color: '#FFFFFF',
                                cursor: 'pointer'
                            }}
                                onClick={() => setError(false)}
                            >{translate('common.ok')}</span>
                        </div>   
                        
                    </div>
                </Dialog>
                <Dialog
                    open={errorLimit}
                    onClose={() => setErrorLimit(false)}
                >
                    <div style={{background: '#7B61FF', padding: 20}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FFFFFF'
                        }}>{translate('common.minmaxamount')}</span>     
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <span style={{
                                marginTop: 12,
                                borderRadius: '50px',
                                padding: '5px 60px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                background: '#FF005C',
                                color: '#FFFFFF',
                                cursor: 'pointer'
                            }}
                                onClick={() => setErrorLimit(false)}
                            >{translate('common.ok')}</span>
                        </div>   
                        
                    </div>
                </Dialog>
            </FormikContext.Provider>
        </WinPageBlock>
    )
}

export default Withdrawal;