import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles(() => ({
    root: {
        width: 400, 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: 720, 
        position: 'relative'
    },
    cross: {
        position: 'absolute', 
        top: 32, 
        right: 32, 
        cursor: 'pointer'
    },
    wrapTitle: {
        display: 'flex', 
        justifyContent: 'center'
    },
    title: {
        textAlign: 'center', 
        padding: 0, 
        margin: 0, 
        paddingTop: 40,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        width: 610,
        userSelect: 'none'
    },
    wrapRootBody: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'center',
    },
    wrapBody: {
        display: 'flex', 
        flexDirection: 'column', 
        marginTop: 30,
        width: 359,
        minHeight: 550
    },
    activeTabOne: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        border: '1px solid #FF005C',
        borderRadius: 3,
        padding: '19px 30px',
        color: '#FFF',
        cursor: 'pointer',
        userSelect: 'none'
    },
    noActiveTabOne: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        color: '#8E9EC2',
        background: '#0034A5',
        borderRadius: 3,
        padding: '19px 24px',
        cursor: 'pointer',
        userSelect: 'none'
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        userSelect: 'none',
        color: '#8E9EC2'
    },
    method: {
        display: 'flex', 
        width: '100%', 
        alignItems: 'center'
    },
    activeTabTwo: {
        display: 'flex', 
        alignItems: 'center', 
        width: '50%', 
        borderRadius: 3, 
        border: '1px solid #FF005C',
        cursor: 'pointer',
        color: '#fff',
        userSelect: 'none',
    },
    noActiveTabTwo: {
        display: 'flex', 
        alignItems: 'center', 
        width: '50%', 
        background: '#0034A5', 
        borderRadius: 3, 
        cursor: 'pointer',
        userSelect: 'none'
    },
    label: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    helper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        userSelect: 'none',
        color: '#FFFFFF',
    },
    helperPassword: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        textDecoration: 'underline',
        color: '#8E9EC2',
        cursor: 'pointer',
        userSelect: 'none'
    },
    field: {
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#FFFFFF',
        background: 'inherit',
        padding: '17px 17px 16px',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: '#8e9ec2', 
        }
    },
    labelField: {
        display: 'flex', 
        flexDirection: 'column'
    },
    wrapHelperPassword: {
        marginBottom: 10, 
        display: 'flex', 
        justifyContent: 'space-between'
    },
    flex: {
        display: 'flex'
    },
    mt25: {
        marginTop: 25
    },
    mt20: {
        marginTop: 20
    },
    mb12: {
        marginBottom: 12
    },
    mr18: {
        marginRight: 18
    },
    mb10: {
        marginBottom: 10
    },
    mt52: {
        marginTop: 52,
    },
    btn: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        alignItems: 'center',
        color: '#FFFFFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.7)',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '0px 0px',
        background: '#FF005C',
        boxShadow: '0px 4px 10px #D3193A',
        borderRadius: '50px',
        padding: '20px 0',
        cursor: 'pointer',
        transition: 'background, box-shadow .3s ease',
        userSelect: 'none',
        '&:hover': {
            background: '#ED074C',
            boxShadow: 'none',
            transition: 'background, box-shadow .3s ease',
        }
    },
    btn2: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        alignItems: 'center',
        color: '#FFFFFF',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '0',
        border: '1px solid #8E9EC2',
        borderRadius: '50px',
        padding: '20px 0',
        cursor: 'default',
        transition: 'background, box-shadow .3s ease',
        userSelect: 'none',

    }
}))

const getValidation = (val) => {
    if (val.method === 'phone' && val.phone && val.password && val.phone.length === val.length) {
        return true
    }
    if (val.method === 'email' && val.email && val.password) {
        return true
    }

    return false
}

export {
    useStyles,
    getValidation
}