import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import JackpotBanner from '../../../../../../@casino/img/jackpot-banner.jpeg';
import {translate} from '../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    bannerTitle: {
        color: 'gold',
        fontSize: 16,
        textTransform: 'uppercase',
        position: 'relative',
        left: 10,
        '&::after': {
            content: "''",
            position: 'absolute',
            display: 'block',
            top: 0,
            left: -10,
            width: 2,
            height: 16,
            background: '#FF005C'
        }
    }
}))

const BannerJackpot = () => {
    const classes = useStyles();
    
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            background: `url(${JackpotBanner})`,
            backgroundSize: 'cover',
            backgroundPositionY: -40
        }}>
            <div style={{
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                background: 'linear-gradient(0deg, rgba(2,0,36,.8) 5%,rgba(255,255,255,.05) 100%)'
            }}>

            </div>
            <div style={{position: 'absolute', bottom: 5, left: 10}}>
                <span className={classes.bannerTitle}>{translate('banner.jackpots')}</span>
            </div>
            
        </div>
    )
}

export default BannerJackpot;