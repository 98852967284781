
import {getJackpotList} from '../api/getJackpotList';
import {setJackpotListAction} from '../action';

export const setGetJackpotList = (params) => {
    return async (dispatch, getState) => {
        try {

            const {data} = await getJackpotList(params);

            if (data.status === 'success') {
                dispatch(setJackpotListAction(data.data))
            }

        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}