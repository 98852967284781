import BonusMask from '../../../../../../@casino/img/mask3.svg';
import BonusMask2 from '../../../../../../@casino/img/mask4.svg';
import BonusMask3 from '../../../../../../@casino/img/mask5.svg';
import BonusMask4 from '../../../../../../@casino/img/mask1.svg';
import BonusMask5 from '../../../../../../@casino/img/mask6.svg';
import BonusMask6 from '../../../../../../@casino/img/mask2.svg';
import {ReactComponent as IconBonus1} from '../../../../../../@casino/img/iconBonus1.svg';
import {ReactComponent as Coin} from '../../../../../../@casino/img/coin.svg';
import {ReactComponent as Dollar} from '../../../../../../@casino/img/dollar.svg';
import {ReactComponent as Suit} from '../../../../../../@casino/img/suit.svg';
import {ReactComponent as Seven} from '../../../../../../@casino/img/777.svg';
import {ReactComponent as Star} from '../../../../../../@casino/img/star.svg';
import { translate } from '../../../../../../dictionaries';

const mask = [BonusMask, BonusMask2, BonusMask3, BonusMask4, BonusMask5, BonusMask6];
const icon = [Dollar, Coin, IconBonus1, Suit, Seven, Star];

const getLabel = (value) => {

    switch (value) {
        case 'bonus-registration': return translate('bonus.registration');
        case 'bonus-referral': return translate('bonus.referral');
        case 'bonus-everyday': return translate('bonus.everyday');
        case 'bonus-promocode': return translate('bonus.promocode');
        case 'bonus-weekly': return translate('bonus.weekly');
        case 'bonus-cashback': return translate('bonus.cashback');
        case 'bonus-first-deposit': return translate('bonus.first-deposit');
        case 'bonus-next-deposit': return translate('bonus.next-deposit');
        case 'bonus-personal': return translate('bonus.personal');
        case 'bonus-tournament': return translate('bonus.tournament');
        case 'bonus-loterry': return translate('bonus.loterry');
        default: return translate('common.default');
    }
}

export {
    mask,
    icon,
    getLabel
}