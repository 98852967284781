import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    mt41: {
        marginTop: 41,
    },
    wrapLabelMethod: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
        }
    },
    helper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    label: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
        lineHeight: '16px'
    },
    wrapLabel: {
        width: 'calc(100% - 38px)',
        maxWidth: 374,
        padding: '15px 19px',
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        display: 'flex', 
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        
    },
    arrowbottom: {
        position: 'absolute', 
        top: 20, 
        right: 20
    },
    wrapAmount: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginTop: 11,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
        }
    },
    wrapInputAmount: {
        width: '100%',
        maxWidth: 413,
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        position: 'relative'
    },
    input: {
        width: '180px',
        padding: '14px 19px 14px 19px',
        outline: 'none',
        border: 'none',
        display: 'flex', 
        alignItems: 'center',
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF'
    },
    input2: {
        width: 'calc(100% - 38px)',
        maxWidth: '375px',
        border: '1px solid #8E9EC2',
        padding: '14px 19px 14px 19px',
        outline: 'none',
        display: 'flex', 
        alignItems: 'center',
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        borderRadius: 3
    },
    wrapAccess: {
        display: 'flex',
        position: 'absolute',
        top: 0, right: 0,
        height: 48,
        alignItems: 'center',
        width: 161,
        justifyContent: 'space-between',
        background: '#002062',
        padding: '0 18px 0 21px',
        borderRadius: 3,
    },
    access: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    summ: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FF005C',
    },
    wrapBtn: {
        display: 'flex',
        marginTop: 40, 
        marginLeft: 171,
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
            justifyContent: 'center'
        }
    },
    wrapImage: {
        marginTop: 43,
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        padding: '10px 40px',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        borderRadius: '50px',
        cursor: 'pointer',
    },
    wrapPaymentNotAvailable: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: 400
    },
    paymentNotAvailable: {
        color: '#D2D8E3', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 18, 
    }
}))

export {
    useStyles
}