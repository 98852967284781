import React from 'react';
import Girl from '../../../../../../../../@casino/img/girl.jpeg';
import Girl2 from '../../../../../../../../@casino/img/girl2.jpeg';
import Girl3 from '../../../../../../../../@casino/img/girl3.jpeg';
import Girl4 from '../../../../../../../../@casino/img/girl4.jpeg';
import Girl5 from '../../../../../../../../@casino/img/girl5.jpeg';
import {useRouter} from '../../../../../../../../@casino/hook/useRouter';
import { useDispatch, useSelector } from 'react-redux';
import { setGetViewTournament } from '../../../../../../tournament/store/thunk/setGetViewTournament';
import { getArray } from '../../../../../../../../@casino/lib/getArray';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    wrapPlacesTableMobile: {
        display: 'flex',
        flexDirection: 'column'
    },
    wrapRow: {
        display: 'flex', 
        alignItems: 'center', 
        paddingBottom: 16, 
        paddingTop: 14, 
        paddingLeft: 20,
        width: '94%',
        justifyContent: 'space-between'    
    },
    placesSize: {
        padding: 10,
        backgroundColor: '#001137', 
        borderRadius: 3, 
        color: '#fff',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
    },
    login: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        paddingLeft: 20,
        width: '80%'
        
    },
    points: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#D2D8E3',
        width: '80%'
    }
}))

const TournamentMobileView = () => {
    const classes = useStyles()
    const now = new Date().getTime();
    const router = useRouter();
    const dispatch = useDispatch();

    const getDate = (val) => {
        const now = new Date().getTime();
        const diff = +val - now;
    
        var hours = (diff / 1000) / 60 / 60;
        var minutes = (diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60  ;
        var seconds = (diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;
        
        const day = Math.floor(hours) / 24;
        const hour = Math.floor(hours) - Math.floor(day) * 24;

        return `${Math.floor(day)} ${translate('common.day')} ${hour} ${translate('common.hours')} ${Math.floor(minutes) < 10 ? '0' + +Math.floor(minutes) : Math.floor(minutes)} ${translate('common.min')}`//`${Math.floor(hours) < 10 ? '0' + Math.floor(hours) : Math.floor(hours)} : ${Math.floor(minutes) < 10 ? '0' +Math.floor(minutes) : Math.floor(minutes)} : ${Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds)}`;

    }

    const tempId = router.pathname;
    const id = tempId.slice(router.pathname.lastIndexOf('/')+1);

    React.useEffect(() => {
        dispatch(setGetViewTournament({
            id
        }));
    }, [id]);

    const item = useSelector(state => state.tournament.view);

    const {name, fund, status, date_to, description, count_winners, count_spin, min_bet, games,top_places, date_from} = item.tournament;
    const {users} = item;
    const places_table = getArray(users, Math.ceil(users.length / 1));
    let placesSizeMobile = 0;
    const girls = [Girl, Girl2, Girl3, Girl4 , Girl5, Girl, Girl2, Girl3, Girl4 , Girl5,Girl, Girl2, Girl3, Girl4 , Girl5, Girl, Girl2, Girl3, Girl4 , Girl5];
    const profile = useSelector(state => state.profile.view);
    
    return (
        <div style={{padding: '12px 10px', marginBottom: 12, position: 'relative',background: 'rgba(2, 0, 4, 0.55)'}}>
            <div style={{background: 'rgba(2,0,4,.4)', width: '100%', minHeight: 186, borderRadius: 4, position: 'relative'}}>
                <div style={{padding: 10, display: 'flex'}}>
                    <div>
                        <img src={girls[id - 1]} alt="" style={{height: 170, width: 128, objectFit: 'cover', borderRadius: 4}}/>
                    </div>
                    <div style={{fontFamily: 'Monserrat, sans-serif', color: '#fff', fontSize: 14, display: 'flex', flexDirection: 'column', width: '100%', paddingLeft: 12}}>
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: 12}}> <span>{translate('tournament.titles')}</span> </div>
                        <div style={{marginBottom: 8}}><span style={{color: 'rgba(255,255,255,0.6)'}}>{translate('common.name')}: </span><span>{name}</span></div>
                        <div style={{marginBottom: 8}}><span style={{color: 'rgba(255,255,255,0.6)'}}>{translate('tournament.prizefond')}: </span><span>{new Intl.NumberFormat('ru-RU').format(fund)}</span></div>
                        {
                            status && date_to > now &&
                            <>
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 8 }}><span style={{color: 'rgba(255,255,255,0.6)'}}>{translate('common.start')} </span></div>
                                <div style={{display: 'flex', justifyContent: 'center'}}><span style={{color: '#fff'}}>{getDate(date_from)}</span></div>
                            </>
                        }
                        {
                            (!status || date_to < now) &&
                            <>  
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                    <span style={{color: 'rgba(255,255,255,1)', fontFamily: 'Monserrat, sans-serif', fontWeight: 700, fontSize: 20}}>{translate('tornament.end')}</span>
                                </div>
                            </>
                        } 
                    </div>
                </div>
                <div style={{paddingBottom: 12}}>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 12}}>
                        <span style={{color: 'rgba(255,255,255,1)', fontFamily: 'Monserrat, sans-serif', fontSize: 16}}>{translate('tournament.description')}</span>
                    </div>
                    <div style={{padding: '0 15px 12px', textAlign: 'justify'}}>
                        <span style={{color: 'rgba(255,255,255,.8)', fontFamily: 'Monserrat, sans-serif', fontSize: 16}}>{description}</span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 12}}>
                        <span style={{color: 'rgba(255,255,255,1)', fontFamily: 'Monserrat, sans-serif', fontSize: 16}}>{translate('tournament.howToWin')}</span>
                    </div>
                    <div style={{padding: '0 15px 12px'}}>
                        <span style={{color: 'rgba(255,255,255,.8)', fontFamily: 'Monserrat, sans-serif', fontSize: 16, display: 'flex', flexDirection: 'column'}}>
                            <span>- {translate('tournament.openRandomGame')}</span>
                            <span>- {translate('tournament.quafilication')} {count_spin} {translate('tournament.spinToBet')} {min_bet}</span>
                            <span>- {translate('tournament.qualifcationBefore')} {min_bet} {translate('tournament.qualifcationBeforeTo')}</span>
                            <span>- {translate('tournament.prizer')} {count_winners} {translate('tournament.gamer')}.</span>
                        </span>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 12}}>
                        <span style={{color: 'rgba(255,255,255,1)', fontFamily: 'Monserrat, sans-serif', fontSize: 16}}>{translate("lottery.prizePlace")}</span>
                    </div>
                    <div style={{padding: '0 15px 12px', display: 'flex', flexDirection: 'column'}}>
                        {/* {
                            top_places.map((item, key) => {
                                return (
                                    <span key={key} style={{color: 'rgba(255,255,255,.8)', fontFamily: 'Monserrat, sans-serif', fontSize: 18, display: 'flex', flexDirection: 'column'}}>{key + 1} {translate('tournament.place')}: {item ? new Intl.NumberFormat('ru-RU').format(item) : key === 0 ? 'iPad pro 12.9(128ГБ)' : 'iPhone 14 pro(128ГБ)'}</span>
                                )
                            })
                        } */}
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 12}}>
                        <span style={{color: 'rgba(255,255,255,1)', fontFamily: 'Monserrat, sans-serif', fontSize: 16}}>{translate('tournament.game')}</span>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', padding: '0 15px 12px', columnGap: 10, rowGap: 10}}>
                        {
                            games && games.map((item, key) => {
                                return (
                                    <img key={key} onClick={() => window.location.replace(window.location.origin + `/games/${item.uuid}`)} src={item.image}  style={{width: '48%'}} />
                                )
                            }) 
                        }
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 12}}>
                        <span style={{color: 'rgba(255,255,255,1)', fontFamily: 'Monserrat, sans-serif', fontSize: 16}}>{translate('tournament.table')}</span>
                    </div>
                    <div>
                        {
                            places_table && places_table.length === 0 &&
                            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                                <span style={{color: 'rgba(255,255,255,.8)', fontFamily: 'Monserrat, sans-serif', fontSize: 16, display: 'flex', flexDirection: 'column'}}>
                                    {translate('lottery.infoNotFound')}
                                </span>
                            </div>
                        }
                        <div style={{marginBottom: 12, padding: '0 20px'}}>
                            <span style={{fontFamily: 'Montserrat, sans-serif', color: 'red'}}>{translate('tournament.position')}</span>
                        </div>
                        <div className={classes.wrapPlacesTableMobile}>
                            {
                                places_table.map((item, key) => {
                                    return (
                                        <div key={key}>
                                        {
                                            item.map((data, key) => {
                                                placesSizeMobile += 1;
                                                
                                                return (
                                                    <div key={key} className={classes.wrapRow} style={{borderTop: key > 0 && '1px solid #eee', background: data.id == profile?.id && 'gold'}}>
                                                        <span className={classes.placesSize}>{placesSizeMobile}</span>
                                                        <span className={classes.login}  style={{color: data.id == profile?.id && '#000'}}>
                                                            {data.id}
                                                        </span>
                                                        <span className={classes.points}  style={{color: data.id == profile?.id && '#000'}}>
                                                            {+data.points - 10}
                                                        </span>
                                                    </div>
                                                )
                                            })
                                        }
                    
                                        </div>
                            
                                )})
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TournamentMobileView;