import React from 'react';
import BannerJackpot from '../banner1';
import BannerTournament from '../banner2';
import BannerCashback from '../banner7';
import BannerBet from '../banner8';
import BannerReferral from '../banner4';
import BannerTournaments from '../banner5';
import NewProvider from '../banner6';
import BannerVesna from '../banner9';

const BuildBanner = () => {
    // const [count, setCount] = React.useState(0);
    
    // React.useEffect(() => {
    //     const interval = setTimeout(() => {
    //         if (count < 6) {
    //             setCount(count+1)
    //         } else {
    //             setCount(0)
    //         }
    //     }, 10000);

    //     return () => clearTimeout(interval);
    // }, [count]);
    
    return (
        <div style={{height: 180, display: 'flex', width: '100%'}}>
            {/* <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <BannerTournaments />
            </div>  */}
            {/* <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <BannerVesna />
            </div> */}
            <div style={{width: '100%', height: '100%', position: 'relative',  transition: '.3s ease'}}>
                <BannerBet />
            </div>
            {/* <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <NewProvider />
            </div>
            <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <BannerCashback />
            </div>
            <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <BannerJackpot />
            </div>
            <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <BannerTournament />
            </div>
            <div style={{width: '100%', height: '100%', position: 'relative', right: count*window.screen.width, transition: '.3s ease'}}>
                <BannerReferral />
            </div> */}
        </div>
    )
}

export default BuildBanner;