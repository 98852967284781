
import {postAuthorization} from '../api/postAuthorization';
import {setProfileAction} from '../action';

export const setPostAuthorization = (params, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postAuthorization(params);
            
            if (data.status === 'success') {
                localStorage.setItem('token', data.token);
                dispatch(setProfileAction(data.profile));
                window.location.replace(window.location.origin)
            } else {
                window.location.replace(window.location.origin)
            }
          
        } catch(error) {
            if (error?.response?.data?.message === 'wrong login or password') {
                setError(true)
            }
            if (error?.response?.data?.status) {
                setError(true)
            }
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}