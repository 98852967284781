import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    rootDesktop: {
        width: '100%',
        maxWidth: 1140, 
        margin: '120px auto 0',
        [theme.breakpoints.down('sm')]: {
            margin: '53px auto 0',
        }
    },
    banner: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    bannerMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            padding: '0 10px',
            marginBottom: 50
        }
    },
    descriptionDesktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    descriptionMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '0 10px',
            display: 'block',
            marginBottom: 70
        }
    }
}))

export {
    useStyles
}