import React from 'react';
import { translate } from '../../../../dictionaries';

const getDateTime = (value) => {
    const date = new Date(+value);

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    
    return `${hours}:${min}  ${day}.${month}.${year}`
}

const getLabelBonuses = (val) => {
    switch (val) {
        case 'bonus-registration': return translate('bonus.registration');
        case 'bonus-referral': return translate('bonus.referral');
        case 'bonus-everyday': return translate('bonus.everyday');
        case 'bonus-promocode': return translate('bonus.promocode');
        case 'bonus-weekly': return translate('bonus.weekly');
        case 'bonus-cashback': return translate('bonus.cashback');
        case 'bonus-first-deposit': return translate('bonus.first-deposit');
        case 'bonus-next-deposit': return translate('bonus.next-deposit');
        case 'bonus-personal': return translate('bonus.personal');
        case 'bonus-tournament': return translate('bonus.tournament');
        case 'bonus-loterry': return translate('bonus.loterry');
        default: return translate('common.default');
    }
}  

const columns = [
    {id: 'name', label: translate('common.name'), width: '40%'},
    {id: 'summ', label: translate('common.amountSumm'), width: '15%'},
    {id: 'roleplaying', label: translate('common.replay'), width: '15%'},
    {id: 'date', label: translate('common.data'), width: '30%'},
];

const columnsMobile = [
    {id: 'name', label: translate('common.name'), width: '40%'},
    {id: 'summ', label: translate('common.amountSumm'), width: '20%'},
    {id: 'date', label: translate('common.data'), width: '40%'},
]


const getRows = (values) => {
    return values.map((item, key) => {
        return {
            name: getLabelBonuses(item.bonus_label),
            summ: item.amount ? new Intl.NumberFormat('ru-RU').format(item.amount) : 0,
            roleplaying: new Intl.NumberFormat('ru-RU').format(item.wager),
            date: getDateTime(item.date_event),
        }
    })
}

const getRowsMobile = (values) => {
    return values.map((item, key) => {
        return {
            name:   <div>
                        {getLabelBonuses(item.bonus_label)}
                    </div>,
            summ: item.amount ? new Intl.NumberFormat('ru-RU').format(item.amount) : 0,
            date: getDateTime(item.date_event),
        }
    })
}

export {
    getDateTime,
    getLabelBonuses,
    columns,
    columnsMobile,
    getRowsMobile,
    getRows
}