import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessanger } from '../../store/thunk/setGetMessanger';
import { ReactComponent as Telegram } from '../../../../../../../@casino/img/telegram.svg';
import { ReactComponent as WhatsApp } from '../../../../../../../@casino/img/whatsapp.svg';
import { translate } from '../../../../../../../dictionaries';

const Messanger = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(setMessanger())
    }, []);

    const messanger = useSelector(state => state.mobile_games.messanger);
    return (
        <div>
            {
                messanger.map((item, key) => {
                    if (item.type === 'telegram' && item.website === window.location.origin) {
                        return (
                            <div
                                key={key}
                                onClick={() => window.open(item.link, '_blank')}
                                style={{ display: 'flex', width: '100%', height: 50, background: '#26A6E6', padding: 5, justifyContent: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '75%' }}>
                                    <span
                                        style={{
                                            width: '100%',
                                            color: '#fff',
                                            fontFamily: 'Montserrat, sans-serif'
                                        }}
                                    >
                                        {translate('common.telegram')}
                                    </span>
                                </div>
                                <div style={{ width: '25%' }}>
                                    <Telegram style={{ width: 50, height: 50 }} />
                                </div>
                            </div>
                        )
                    }
                })
            }
        </div>
    )
}

export default Messanger;