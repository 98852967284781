import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import {translate} from '../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    wrapTitle: {
        width: 1140,
        margin: '0 auto',
        marginTop: 109,
        marginBottom: 140
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        color: '#fff',
        textAlign: 'center',
        marginBottom: 41
    }
}))

const getTime = (value) => {
    const date = new Date(+value);
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

    return `${hours}:${min}`
}


const columns = [
    {id: 'login', label: translate('common.winners'), width: '19%'},
    {id: 'country', label: translate('common.country'), width: '19%'},
    {id: 'date_event', label: translate('common.time'), width: '19%'},
    {id: 'amount', label: translate('common.win'), width: '19%'},
];

const columns2 = [
    {id: 'login', label: translate('common.user'), width: '19%'},
    {id: 'country', label: translate('common.country'), width: '19%'},
    {id: 'date_event', label: translate('common.time'), width: '19%'},
    {id: 'amount', label: translate('common.amountSumm'), width: '19%'},
];

const columnsMobile = [
    {id: 'name', label: translate('common.game'), width: '19%'},
    {id: 'login', label: translate('common.winners'), width: '39%'},
    {id: 'amount', label: translate('common.win'), width: '39%'},
]

const getRows = (values) => {
    return values.map((item, key) => {
        return {
            login: item.id,
            country: <img src={`https://flagcdn.com/w20/tr.png`} alt="country"/>,
            date_event: item?.date_event ? getTime(item.date_event) : getTime(item.event_date),
            amount: <span style={{color: '#FF005C', fontWeight: 600}}>{new Intl.NumberFormat('ru-RU').format(item.amount || item.summ)} UZB</span>,
        }
    })
}

const getRowsMobile = (values) => {
    return values.map((item, key) => {
        return {
            name:  
            <div style={{
                width: '100%',
                display: 'flex', justifyContent: 'center'
            }}>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                    <img src={item.image} style={{width: 45, height: 45, background: '#eee', borderRadius: '50%', display: 'block'}} alt=""/>
                </div>
            </div>,
            login: item.id,
            amount: <span style={{color: '#FF005C', fontWeight: 600}}>{new Intl.NumberFormat('ru-RU').format(item.amount)}</span>,
        }
    })
}

export {
    getTime,
    columns,
    columns2,
    getRows,
    useStyles,
    getRowsMobile,
    columnsMobile,
}