import { setLoadingAction } from '../../../../loader/store/action';
import { postUpdateVerification } from '../api/postUpdateVerification';
import { setGetListVerification } from './setGetListVerification';


export const setPostUpdateVerification = (params) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoadingAction({loading: true}))
            await postUpdateVerification(params);
            await dispatch(setGetListVerification());
    
            dispatch(setLoadingAction({loading: false}))
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}