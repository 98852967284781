import React from 'react';
import {ReactComponent as LiteCoin} from '../../../../../../@casino/img/litecoin.svg';
import { useSelector } from 'react-redux';

const LiteCoinWallet = ({ setSelected, formik, currency }) => {
    const profile = useSelector(state => state.profile.view);

    return (
        <div
            onClick={() => {
                setSelected(true);
                formik.setFieldValue('cardType', 25);
                if (currency === 'RUB') {
                    formik.setFieldValue('min', profile?.balance < 1000 ? 1000 : profile?.balance);
                } else {
                    formik.setFieldValue('min', profile?.balance < 10 ? 10 : profile?.balance);
                }
            }}
            style={{overflow: 'hidden', cursor: 'pointer', width: 110, height: 100, background: '#fff', borderRadius: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LiteCoin style={{ width: 110, height: 100 }} />
            <div style={{ background: '#D2D8E3', width: 90, height: 1, marginBottom: 4 }}></div>
            <span style={{
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '17px',
                color: '#8E9EC2',
                paddingBottom: 5
            }}>LiteCoin</span>
        </div>
    )
}

export default LiteCoinWallet;