
import {getGamesList} from '../api/getGamesList';
import {setGamesLiveAction} from '../action';

export const setGamesListLive = (page) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getGamesList(page);
            if (data.status === 'success') {
                dispatch(setGamesLiveAction({
                    list: data.data?.list,
                    page: data.data?.page
                }));
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}