import React from 'react';
import Btn from '../../../../../@casino/ui/btn';
import {ReactComponent as Face} from '../../../../../@casino/img/face.svg';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import {useStyles, getLogin} from './helper';
import {ReactComponent as Info} from '../../../../../@casino/img/info.svg';
import Tooltip from '@material-ui/core/Tooltip';
import {translate} from '../../../../../dictionaries';

const BlockAuth = ({profile, balance}) => {
    const router = useRouter();
    const classes = useStyles();
    const [view, setView] = React.useState(false);
    const {wager} = profile;
    const SelectedItem = ({title, event}) => {
        return (
            <span
                onClick={() => {
                    event()
                }}
                className={classes.blockItem}
            >
                {title}
            </span>
        )
    }
    const getCurrency = (currency) => {
        switch (currency) {
            case 'RUB': return '₽';
            case 'USD': return '$';
            case 'EUR': return '€';
            default: return currency
        }
    }

    return (
        <div className={classes.root}>  
            <div className={classes.wrap}>
                <div>
                    <Face 
                        className={classes.face}
                        onClick={() => {
                            setView(!view)
                        }}
                    />
                    {
                        view &&
                        <div className={classes.wrapProfile} style={{zIndex: 99999999}}>
                            <div>
                                <SelectedItem 
                                    title={translate('navigation.profile')}
                                    event={() => {
                                        router.history.push('/profile/account')
                                        setView(false);
                                    }}
                                />
                            </div>
                            <div>
                            <SelectedItem 
                                title={translate('common.exit')}
                                event={() => {
                                    localStorage.removeItem('token'); 
                                    window.location.replace(window.location.origin);
                                    setView(false);
                                }}
                            />
                            </div>    
                        </div>
                    }
                </div>
                <div>
                    <span className={classes.login}>{profile?.login === '79653339666' ? '79********6' : profile.type_registrations !== 'phone' ? profile.login : getLogin(profile.login)}</span>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <span className={classes.balance}>{translate('common.slot1')}: {balance} {getCurrency(profile?.currency)}</span>
                            <span className={classes.balance}>{translate('common.sport')}: {profile.balance_sport} {getCurrency(profile?.currency)}</span>
                        </div>
                        
                        {
                            !!wager && profile.connection_groups === 'true' &&
                            <Tooltip title={`${translate('common.wager')}: ` + wager + ` ${getCurrency(profile?.currency)}`}>
                                <span style={{marginLeft: 8}}>
                                    <Info />
                                </span>
                            </Tooltip>
                        }
                    </div>
                    
                </div>
            </div>
            <Btn 
                title={translate('common.replenishments')}
                event={() => {
                    router.history.push('/profile/replenishment')
                }}
            />
        </div>
    )
}

export default BlockAuth;