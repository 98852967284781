import { setLoadingAction } from '../../../../loader/store/action';
import { postCreateVerification } from '../api/postCreateVerification';
import { setGetListVerification } from './setGetListVerification';

export const setPostCreateVerification = (params) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setLoadingAction({loading: true}))
            const formData = new FormData();
            formData.append("images", params.file);
            formData.append("type", params.type);
            formData.append("cardNumber", params.cardNumber);
            
            const {data} = await postCreateVerification(formData);
            dispatch(setGetListVerification());
            if (data.status === 'success') {
                dispatch(setLoadingAction({loading: false}))
            }
 
     
           
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}