import React from 'react';
import {useStyles} from './helper';
import {useRouter} from '../../../@casino/hook/useRouter';
import { translate } from '../../../dictionaries';

const BonusComponent = ({title, description, Icon, background, mb, main}) => {
    const classes = useStyles();
    const router = useRouter();

    return (
        <div className={classes.root} style={{marginBottom: mb, background: `url(${background}) top right`}}>
            <div className={classes.wrapTitleBlock}>
                <h3 className={classes.title}>{title}</h3>
                <p className={classes.description}>{description}</p>
            </div>
            {
                router.pathname === '/' &&
                <div className={classes.wrapBtn}>
                    <span 
                        className={classes.btn}
                        onClick={() => {
                            if (router.pathname === '/') {
                                router.history.push('/bonuses')
                            }
                        }}>
                        {translate('common.view')}
                    </span>
                </div>
            }
            {!main && <Icon className={classes.icon}/>}
        </div>
    )
}

export default BonusComponent;