
import {postGameDemo} from '../api/postGameDemo';
import {setGameIdAction} from '../action';

export const setGameDemo = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postGameDemo(params);
            if (data.status === 'success') {
                dispatch(setGameIdAction(data.data.url));
            }
        } catch(error) {
           
            // if (error.response && error.response.status === 401) {
            //     localStorage.removeItem('token');
            //     window.location.replace(window.location.origin);
            // }
        }
    }
}