import React from 'react';

const PoliticalCanselPaymentTR = () => {
    return (
        <div class="page-content rules">
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>İptal politikası (ACTIV AGUA ALIANZA LIMITADA)</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Şüpheli veya hileli hesaba para yatırma durumunda, Şirket bu tür bir hesabı bloke etme, ödemeleri iptal etme ve kazançları tahsil etme hakkına sahiptir. Hileli ödeme işlemlerini ve yasa dışı faaliyetleri uygun makamlara bildirme hakkına sahibiz. Ödemeleri geri almak için tahsilat acentelerinin hizmetlerini kullanma hakkına sahibiz. Kredi kartlarının izinsiz kullanılmasından çalıntı ihbarı alınmış olsun ya da olmasın Şirket sorumlu değildir.
            </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Hesabınızın bir banka hesabı olmadığını tamamen anlıyor ve kabul ediyorsunuz. Bu nedenle, mevduat sigortası sisteminden ve diğer sigorta sistemlerinden sigorta, garanti, ikmal ve diğer korunma yöntemleri uygulanmaz. Hesabınızdaki paralara faiz tahakkuk ettirilemez.
            </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Aşağıdakilerin olması koşuluyla, istediğiniz zaman hesabınızdan para çekme talebinde bulunabilirsiniz:
            </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                *hesaba aktarılan tüm ödemeler doğrulanmıştır ve hiçbiri iptal edilmemiştir veya iptal edilmemiştir;
            </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                *Şartlar ve Koşullar'ın 6. bölümünde tartışılan doğrulama işlemleri düzgün bir şekilde gerçekleştirildi.
            </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Para çekme için talep edilen tutarın 1000 doları aşması durumunda kimlik tespiti yapılması zorunludur. Bu, oyuncunun pasaport veya kimlik kartı gibi kimlik belgesinin bir kopyasını veya dijital fotoğrafını göndererek yapılır. Belgenin resminde pasaportun numarasını ve serisini boyayabilirsiniz. Şirket ayrıca başka ek belgeler talep etme hakkına da sahiptir.
            </p>
        </div>
    )
}

export default PoliticalCanselPaymentTR;