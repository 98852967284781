import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {translate} from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    activeTabOne: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        border: '1px solid #FF005C',
        borderRadius: 3,
        padding: '19px 30px',
        color: '#FFF',
        cursor: 'pointer',
        userSelect: 'none'
    },
    noActiveTabOne: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        color: '#8E9EC2',
        background: '#0034A5',
        borderRadius: 3,
        padding: '19px 24px',
        cursor: 'pointer',
        userSelect: 'none'
    },
    flex: {
        display: 'flex',
        justifyContent: 'space-between',
    }
}))

const Tabs = ({activeTab, setActiveTab, setMethod, formik}) => {
    const classes = useStyles();

    return (
        <div className={classes.flex}>
            <span
                className={activeTab === 'auth' ? classes.activeTabOne : classes.noActiveTabOne}
                style={{marginRight: 9}}
                onClick={() => {
                    setActiveTab('auth');
                    setMethod('');
                    setMethod('phone');
                    formik.setFieldValue('email', '');
                    formik.setFieldValue('phone', '');
                    formik.setFieldValue('password', '');
                }}
            >
                {translate('common.signin')}
            </span>
            <span 
                className={activeTab === 'registration' ? classes.activeTabOne : classes.noActiveTabOne}
                onClick={() => {
                    setActiveTab('registration');
                    setMethod('phone');
                    formik.setFieldValue('email', '');
                    formik.setFieldValue('phone', '');
                    formik.setFieldValue('password', '');
                }}    
            >
                {translate('common.signup')}
            </span>
        </div>
    )
}

export default Tabs;