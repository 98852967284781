import React from 'react';
import { translate } from '../../../../../dictionaries';
import FreekassaVisaRub from '../../components/fk/visa_rub';
import FreekassaMasterCardRUB from '../../components/fk/mastercard_rub';
import OnlineBank from '../../components/fk/onlinebank';
import FreekassaFKWalletUsd from '../../components/fk/fk_wallet_usd';
import FreekassaFKWalletRub from '../../components/fk/fk_wallet_rub';
import PerfectMoneyUsd from '../../components/fk/perfect_money_usd';
import SteamPay from '../../components/fk/steam_pay';
import UstdERC20 from '../../components/fk/usdt_erc20';
import UstdTRC20 from '../../components/fk/usdt_trc20';
import Ethereum from '../../components/fk/ethereum';
import LiteCoinWallet from '../../components/fk/litecoint';
import BitcoinWallet from '../../components/fk/bitcoin';
import TrxWallet from '../../components/fk/trx';
import RippleWallet from '../../components/fk/ripple';
import DogecoinWallet from '../../components/fk/dogecoin';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as BallTwo } from './ball3.svg';
import { ReactComponent as Slot } from './slot.svg';
import { ReactComponent as Slot2 } from './slot2.svg';
import FortunaVisaPay from '../../components/fortuna/visa_rub';
import FortunaMasterCard from '../../components/fortuna/mastercard';
import FortunaWorldRub from '../../components/fortuna/world_rub';
import Alfakit from '../../components/alfakit/rub/visa_rub';
import AlfakitRubMC from '../../components/alfakit/rub/mastercard_rub';
import AlfakitWorldRub from '../../components/alfakit/rub/world_rub';
import AlfakitVisaEUR from '../../components/alfakit/eur/visa';
import AlfakitEURMC from '../../components/alfakit/eur/mastercard';
import AlfakitVisaKZT from '../../components/alfakit/kzt/visa';
import AlfakitKZTMC from '../../components/alfakit/kzt/mastercard';
import AlfakitVisaTRY from '../../components/alfakit/try/visa';
import AlfakitTRYMC from '../../components/alfakit/try/mastercard';
import AlfakitUZSMC from '../../components/alfakit/uzs/mastercard';
import AlfakitVisaUZS from '../../components/alfakit/uzs/visa';
import AlfakitVisaINR from '../../components/alfakit/inr/visa';
import AlfakitINRMC from '../../components/alfakit/inr/mastercard';

const useStyles = makeStyles(() => ({
    balance: {
        padding: 20,
        border: '1px solid rgba(255,255,255,0.2)',
        borderRadius: 8,
        width: '40%',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'border .3s ease',
        display: 'flex',
        alignItems: 'center',
        columnGap: 8,
        '&:hover': {
            borderColor: 'rgb(255, 0, 92)',
            transition: 'border .3s ease'
        }
    }
}))

const MethodReplenishment = ({ setSelected, formik, currency, payment }) => {
    const [balanceSelected, setBalanceSelected] = React.useState();
    const classes = useStyles();

    if (!balanceSelected) {
        return (
            <div style={{
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#FFFFFF',
                margin: 0,
                padding: 0,
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <span>{translate('common.typeBalance')}</span>
                </div>
                <div style={{ display: 'flex', marginTop: 20, columnGap: 12 }}>
                    <div
                        className={classes.balance}
                        onClick={() => {
                            setBalanceSelected('slot');
                            formik.setFieldValue('balanceSelected', 'slot');
                        }}
                    >
                        <span>
                            <Slot2 style={{ width: 30, height: 30, fill: '#fff' }} />
                        </span>
                        <span>
                            {translate('common.slotBalance')}
                        </span>
                    </div>
                    <div
                        className={classes.balance}
                        onClick={() => {
                            setBalanceSelected('sport');
                            formik.setFieldValue('balanceSelected', 'sport');
                        }}
                    >
                        <span>
                            <BallTwo style={{ width: 30, height: 30, fill: '#fff' }} />
                        </span>
                        <span>
                            {translate('common.sportBalance')}
                        </span>

                    </div>
                </div>

                <div style={{ marginTop: 30, display: 'flex', flexDirection: 'column', fontSize: 12 }}>
                    <span>{translate('common.typeBalance.description1')}</span>
                    <span>{translate('common.typeBalance.description2')}</span>
                    <span>{translate('common.typeBalance.description3')}</span>
                </div>
            </div>
        )
    }

    return (
        <div style={{ marginBottom: 50 }}>
            <div>
                <h3 style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                    margin: 0,
                    padding: 0,
                }}>{translate('common.plasticCard')}</h3>

                <div style={{ marginTop: 25, display: 'flex', columnGap: 12, flexWrap: 'wrap', rowGap: 12 }}>
                    {
                        payment.map((item, key) => {
                            if (item.label === 'FreekassaVisaRub' && currency === 'RUB' && item.status) {
                                return <FreekassaVisaRub key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'FreekassaMastercardRub' && currency === 'RUB' && item.status) {
                                return <FreekassaMasterCardRUB key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'OnlineBank' && currency === 'RUB' && item.status) {
                                return <OnlineBank key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'FreekassaFKWalletRub' && currency === 'RUB' && item.status) {
                                return <FreekassaFKWalletRub key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'SteamPay' && currency === 'RUB' && item.status) {
                                return <SteamPay key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'FreekassaFKWalletUsd' && currency === 'USD' && item.status) {
                                return <FreekassaFKWalletUsd key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'PerfectMoneyUsd' && currency === 'USD' && item.status) {
                                return <PerfectMoneyUsd key={key} setSelected={setSelected} formik={formik} />
                            }
                            if (item.label === 'FortunaPay' && currency === 'RUB' && item.status) {
                                return (
                                    <>
                                        <FortunaVisaPay key={key} setSelected={setSelected} formik={formik} />
                                        <FortunaMasterCard key={key} setSelected={setSelected} formik={formik} />
                                        <FortunaWorldRub key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                            if (item.label === 'AlfakitRUB' && currency === 'RUB' && item.status) {
                                return (
                                    <>
                                        <Alfakit key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitRubMC key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitWorldRub key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                            if (item.label === 'AlfakitEUR' && currency === 'EUR' && item.status) {
                                return (
                                    <>
                                        <AlfakitVisaEUR key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitEURMC key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                            if (item.label === 'AlfakitKZT' && currency === 'KZT' && item.status) {
                                return (
                                    <>
                                        <AlfakitVisaKZT key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitKZTMC key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                            if (item.label === 'AlfakitTRY' && currency === 'TRY' && item.status) {
                                return (
                                    <>
                                        <AlfakitVisaTRY key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitTRYMC key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                            if (item.label === 'AlfakitUZS' && currency === 'UZS' && item.status) {
                                return (
                                    <>
                                        <AlfakitVisaUZS key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitUZSMC key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                            if (item.label === 'AlfakitINR' && currency === 'INR' && item.status) {
                                return (
                                    <>
                                        <AlfakitVisaINR key={key} setSelected={setSelected} formik={formik} />
                                        <AlfakitINRMC key={key} setSelected={setSelected} formik={formik} />
                                    </>
                                )
                            }
                        })
                    }
                </div>

            </div>
            <div style={{ marginTop: 20 }}>
                <h3 style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: '#FFFFFF',
                    margin: 0,
                    padding: 0,
                }}>{translate('common.crypto')}</h3>

                <div style={{ marginTop: 25, display: 'flex', columnGap: 12, flexWrap: 'wrap', rowGap: 12 }}>
                    <UstdERC20 setSelected={setSelected} formik={formik} currency={currency} />
                    <UstdTRC20 setSelected={setSelected} formik={formik} currency={currency} />
                    <Ethereum setSelected={setSelected} formik={formik} currency={currency} />
                    <LiteCoinWallet setSelected={setSelected} formik={formik} currency={currency} />
                    <BitcoinWallet setSelected={setSelected} formik={formik} currency={currency} />
                    <TrxWallet setSelected={setSelected} formik={formik} currency={currency} />
                    <RippleWallet setSelected={setSelected} formik={formik} currency={currency} />
                    <DogecoinWallet setSelected={setSelected} formik={formik} currency={currency} />
                </div>
            </div>

        </div>
    )
}

export default MethodReplenishment;