const getArray = (val, size) => {
    const tempArray = [...val];
    const arrays = [];
    while (tempArray.length > 0) {
        arrays.push(tempArray.splice(0,size));
    }
   
    return arrays
}

export {
    getArray
}