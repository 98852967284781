import makeStyles from '@material-ui/core/styles/makeStyles';


const useStyles = makeStyles(() => ({
    helper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        userSelect: 'none',
        color: '#FFFFFF',
    },
    field: {
        border: '1px solid #FFFFFF',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#FFFFFF',
        background: 'inherit',
        padding: '17px 17px 16px',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: '#8e9ec2', 
            fontFamily: 'Montserrat, sans-serif',
        }
    },
    fieldPromocode: {
        border: '1px solid #7B61FF',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#7B61FF',
        background: 'inherit',
        padding: '10.5px 17px 11px',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: '#7B61FF', 
            fontFamily: 'Montserrat, sans-serif',
        }
    },
    labelField: {
        display: 'flex', 
        flexDirection: 'column'
    },
    mb10: {
        marginBottom: 10
    },
}))

export {
    useStyles
}