import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as Success} from '../../../../@casino/img/sucessVerification.svg';
import {ReactComponent as Error} from '../../../../@casino/img/errorVerification.svg';
import React from 'react';
import {ReactComponent as SuccessVerification} from '../../../../@casino/img/sucessVerification.svg';
import {ReactComponent as ErrorVerification} from '../../../../@casino/img/errorVerification.svg';
import {translate} from '../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    mt50: {
        marginTop: 50,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    wrapPromocode: {
        marginTop: 26, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    titlePromocode: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        color: '#FFFFFF',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 25
        }
    },
    labelPromocode: {
        padding: '15px 0',
        background: '#7B61FF',
        borderRadius: 3,
        userSelect: 'text',
        color: '#fff',
        width: '75%',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    wrapBlockInformation: {
        display: 'flex', 
        flexDirection: 'column', 
        marginTop: 25, 
        paddingBottom: 48,
        borderBottom: '1px solid rgba(255,255,255,.2)',
    },
    information: {
        fontFamily: 'Montserrat, sans-serif',
        textAlign: 'justify',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16,
        lineHeight: '20px',
        color: '#8E9EC2',
        marginBottom: 26
    },
    wrapProfit: {
        display: 'flex', 
        alignItems: 'center'
    },
    profitLabel: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    profit: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFBF39',
        marginLeft: 42,
    },
    wrapTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 54,
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFFFFF',
        marginBottom: 17
    },
    titleCreateLabel: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        color: '#FFFFFF',
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            marginBottom: 25
        }
    },
    disabledBlock: {
        marginTop: 26,
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 0
        }
    },
    inputPromocode: {
        padding: '15px 10px', 
        marginRight: '12px',
        border: '1px solid #7B61FF',
        background: 'inherit',
        outline: 'none',
        borderRadius: 3,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',    
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            width: '85%',
            marginBottom: 10 
        }
    },
    createBtn: {
        cursor: 'pointer',
        background: '#FF005C',
        borderRadius: '50px',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        padding: '15px 40px',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
            justifyContent: 'center'
        }
    },
    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
}))

const getDateTime = (value) => {
    const date = new Date(+value);

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    
    return `${hours}:${min}  ${day}.${month}.${year}`
}

const columns = [
    {id: 'login', label: 'login', width: '20%'},
    {id: 'deposit', label: translate('common.deposits'), width: '25%'},
    {id: 'withdrawals', label: translate('common.conclusions'), width: '25%'},
    {id: 'dohod', label: <div style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: translate('common.income')}} />, width: '30%'},
]

const columnsMobile = [
    {id: 'login', label: 'login', width: '20%'},
    {id: 'deposit', label: 'BET', width: '25%'},
    {id: 'withdrawals', label: 'WIN', width: '25%'},
    {id: 'dohod', label: translate('common.income'), width: '30%'},
]

const getRows = (values, percent) => {
    return values.map((item, key) => {
        return {
            login: item.login,
            data: getDateTime(item.registration),
            deposit: item.sum_deposit,
            withdrawals: item.sum_withdrawals,
            dohod: ((+item.sum_deposit - +item.sum_withdrawals)/100*percent).toFixed(2)
        }
    })
}

const getRowsMobile = (values, percent) => {
    return values.map((item, key) => {
        return {
            replenishment: item.amount_referral ? <SuccessVerification /> : <ErrorVerification />,
            login: item.login,
            deposit: item.sum_deposit,
            withdrawals: item.sum_withdrawals,
            dohod: ((+item.sum_deposit - +item.sum_withdrawals)/100*percent).toFixed(2)
        }
    })
}

export {
    columns,
    columnsMobile,
    getRowsMobile,
    getRows,
    useStyles
}