import React from 'react';
import BannerFive from '../../components/banner6';
import BannerSix from '../../components/banner10';
import BannerVesna from '../../components/banner11';
import BannerSeven from '../../components/banner7';
import BannerEight from '../../components/banner8';
import BannerNine from '../../components/banner9';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const BannerList = ({dots, setDots, allDots}) => {
    return (
        <div>
            <Carousel
                autoPlay={true}
                infiniteLoop
                // showIndicators={false}
                showStatus={false}
                showThumbs={false}
                showArrows={false}
                emulateTouch
                interval={10000}
            >
                {/* <BannerFive /> */}
                {/* <BannerVesna /> */}
                <BannerSix />
                {/* <BannerSeven /> */}
                {/* <BannerEight /> */}
                {/* <BannerNine /> */}
            </Carousel>
        </div>
    )
}

export default BannerList;