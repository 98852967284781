import React from 'react';
import {ReactComponent as AllDeposit} from '../img/100percentofdeposit.svg';
import FS30 from '../img/30fs.png';
import {ReactComponent as FS50} from '../img/50fs.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 30
    },
    wrapTitle: {
        marginBottom: 24
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        userSelect: 'none'
    },
    bonus: {
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'space-between', 
        justifyContent: 'center',
        background: '#001137',
        borderRadius: '3px',
        cursor: 'pointer',
        width: 100,
        height: 160
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '100%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '29px',
        textAlign: 'center',
        userSelect: 'none',
     
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    }
}))
const ActiveBonus = ({formik}) => {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <div className={classes.wrapTitle}>
                <h3 className={classes.title}>{translate('common.selectBonus')}</h3>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className={formik.values.activeBonus === 'alldeposit' ? clsx(classes.bonus, classes.activeBonus) : classes.bonus} onClick={() => formik.setFieldValue('activeBonus','alldeposit')}>
                    <AllDeposit style={{height: 96, width: 96}}/>
                    <span className={clsx(classes.textBonus, formik.values.activeBonus === 'alldeposit' ? classes.colorTextActiveBonus : classes.colorTextBonus)}>100% {translate('common.deposit')}</span>
                </div>
                <div className={formik.values.activeBonus === 'fs30' ? clsx(classes.bonus, classes.activeBonus) : classes.bonus} onClick={() => formik.setFieldValue('activeBonus','fs30')}>
                    <img src={FS30} style={{height: 105, width: 105}} alt="fs30"/>
                    <span className={clsx(classes.textBonus, formik.values.activeBonus === 'fs30' ? classes.colorTextActiveBonus : classes.colorTextBonus)}>50% {translate('common.deposit')} + 75FS</span>
                </div>
                <div className={formik.values.activeBonus === 'fs50' ? clsx(classes.bonus, classes.activeBonus) : classes.bonus} onClick={() => formik.setFieldValue('activeBonus', 'fs50')}>
                    <FS50 style={{height: 96, width: 96}} />
                    <span className={clsx(classes.textBonus, formik.values.activeBonus === 'fs50' ? classes.colorTextActiveBonus : classes.colorTextBonus)}>150FS</span>
                </div> 
            </div>
        </div>
    )
}

export default ActiveBonus;