import JackpotGold from '../../../../../../../@casino/img/gold.svg';
import JackpotPlatinum from '../../../../../../../@casino/img/platinum.svg';
import JackpotSilver from '../../../../../../../@casino/img/silver.svg';
import JackpotBronze from '../../../../../../../@casino/img/bronze.svg';
import { translate } from '../../../../../../../dictionaries';

const getLabel = (val) => {
    switch(val) {
        case 'bronze': return translate('jackpot.bronze');
        case 'silver': return translate('jackpot.silver');
        case 'gold': return translate('jackpot.gold');
        case 'platinum': return translate('jackpot.platinum');
        default: return translate('common.default');
    }
}

const getBackground = (val) => {
    switch(val) {
        case 'bronze': return JackpotBronze;
        case 'silver': return JackpotSilver;
        case 'gold': return JackpotGold;
        case 'platinum': return JackpotPlatinum;
        default: return translate('common.default');
    }
}

export {
    getLabel,
    getBackground
}