import React from 'react';
import VisaWithdrawal from './Visa';
import USDTWithdrawal from './Usdt';
import LitecoinWithdrawal from './Litecoin';
import EthereumWithdrawal from './Ethereum';

const MethodWithdrawal = ({setSelected, formik}) => {
    return (
        <div style={{marginBottom: 50}}>
            <VisaWithdrawal formik={formik} setSelected={setSelected} />
            {/* <div style={{display: 'flex', columnGap: 12}}>
                <USDTWithdrawal formik={formik} setSelected={setSelected} />
                <LitecoinWithdrawal formik={formik} setSelected={setSelected} /> 
                <EthereumWithdrawal formik={formik} setSelected={setSelected} /> 
            </div> */}
        </div>
    )
}

export default MethodWithdrawal;