import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%', 
        maxWidth: 1140, 
        margin: '0 auto', 
        display: 'flex', 
        flexDirection: 'column', 
        marginBottom: 109,
    },
    item: {
        height: 20,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        paddingLeft: 11,
        userSelect: 'none',
    },
    p15: {
        padding: 15,
        cursor: 'pointer'
    },
    gamegame: {
        position: 'absolute', 
        top: 0, 
        width: 210, 
        height: 141, 
        background: 'rgba(0,0,0,.5)', 
        zIndex: 100, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        opacity: 0,
        transition: 'opacity .3s ease',
        
        '&:hover': {
            opacity: 1,
            transition: 'opacity .3s ease',
        },
        [theme.breakpoints.down('sm')]: {
            width: 154,
            height: 104
        }
    },
    wrapLabel: {
        display: 'flex', 
        justifyContent: 'center', 
        marginTop: 66   
    },
    wrapGame: {
        width: 210, 
        height: 141, 
        borderRadius: 3, 
        position: 'relative', 
        transition: 'all .3s ease',
        [theme.breakpoints.down('sm')]: {
            width: 154,
            height: 104
        }
    },
    btnGame: {
        cursor: 'pointer', 
        userSelect: 'none', 
        marginTop: 5, 
        background: '#FF005C', 
        padding: '10px 40px', 
        borderRadius: '50px', 
        color: '#fff', 
        fontFamily: 'Montserrat', 
        fontWeight: 500
    },
    image: {
        width: 210, 
        height: 141, 
        borderRadius: 3,
        [theme.breakpoints.down('sm')]: {
            width: 154,
            height: 104
        }
    },
    wrapGames: {
        display: 'flex', 
        width: '100%', 
        justifyContent: 'flex-start', 
        flexWrap: 'wrap', 
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center', 
            gap: 15,
        }
    }
}))

export {
    useStyles
}