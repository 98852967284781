import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1140, 
        margin: '120px auto 0'
    },
    wrapLabel: {
        display: 'flex',
        justifyContent: 'center',
    },
    wrapBody: {
        display: 'grid', 
        gridTemplateColumns: '234px 234px 234px 234px 234px', 
        marginBottom: '115px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    wrapBodyMobile: {
        display: 'none', 
        
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            width: '100%',
            marginBottom: '115px',
        }
    },
    wrapRows: {
        width: '100%',
    },
    wrapwrap: {
        padding: '0 10px'
    },
    wrapRow: {
        display: 'flex', 
        alignItems: 'center', 
        paddingBottom: 16, 
        paddingTop: 14, 
        borderBottom: '1px solid rgba(0, 17, 55, 0.3)',
        [theme.breakpoints.down('sm')]: { 
            width: '100%',
            maxWidth: 234
        }
    },
    fundSize: {
        padding: 10,
        borderRadius: 3, 
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    data: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '24px',
        paddingLeft: 24,
        userSelect: 'none'
    }
}))

export {
    useStyles
}