import React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import {translate} from '../../../dictionaries';

const Modal = () => {
    const [finishTime] = React.useState(new Date().getTime() + 11000);
    const [[diffDays, diffH, diffM, diffS], setDiff] = React.useState([0, 0, 0, 0]);
    const [tick, setTick] = React.useState(false);

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff < 0) return 
        setDiff([
            Math.floor(diff / 86400), 
            Math.floor((diff / 3600) % 24),
            Math.floor((diff / 60) % 60),
            Math.floor(diff % 60)
        ])
    }, [tick, finishTime])

    React.useEffect(() => {
        const timerID = setInterval(() => setTick(!tick), 1000);
        return () => clearInterval(timerID);
    }, [tick]);

    return (
        <div>
            <Dialog open={true}>
                <div style={{padding: 15, textAlign: 'center'}}>
                    <span>{translate('information.fs.one')}</span> <br />
                    <span>{translate('information.fs.two')}</span><br />
                    <span style={{fontSize: 18, fontWeight: 700}}>{translate('information.fs.three')}</span><br />
                    <span>{translate('information.fs.four')} {diffS} {translate('information.fs.five')}</span>
                </div>
            </Dialog>
        </div>
    )
}

export default Modal;