import React from 'react';
import BgTournament from '../../../../../@casino/img/bgtournament.svg';
import {useStyles} from './helper';
import { translate } from '../../../../../dictionaries';

const BannerTournament = ({loterry}) => {
    const classes = useStyles();
    
    return (
        <div 
            style={{
                background: `url(${BgTournament}`, 
                backgroundColor: '#1d2e7d'
            }}
            className={classes.root}>
        <div className={classes.wrapLabel}>
            <span className={classes.label}>
                {translate('lottery.title')}
            </span>
        </div>
        <div className={classes.wrapBody}>
                <span className={classes.tournamentName}>
                    {loterry.label}
                </span>
                <span className={classes.fundTitle}>
                    {translate('tournament.prizefond')}
                </span>
                <span className={classes.fundAmount}>
                    +{new Intl.NumberFormat('ru-RU').format(loterry.fund ? loterry.fund : 0)}
                </span>
                <span className={classes.btn}>
                    {translate('tournament.startgame')}
                </span>
            </div>
        </div> 
    )
}

export default BannerTournament;