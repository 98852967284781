import React from 'react';
import { translate } from '../../../../../../../dictionaries';
import {ReactComponent as Mastercard} from '../../../../../../../@casino/img/mastercard.svg';
import { useSelector } from 'react-redux';

const AlfakitINRMC = ({setSelected, formik}) => {
    const profile = useSelector(state => state.profile.view);

    return (
        <div
            onClick={() => {
                setSelected(true);
                formik.setFieldValue('cardType', 1700);
                formik.setFieldValue('min', profile?.balance < 500 ? 500 : profile?.balance);
            }}
            style={{overflow: 'hidden', cursor: 'pointer', width: 110, height: 100, background: '#fff', borderRadius: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Mastercard />
            <div style={{ background: '#D2D8E3', width: 90, height: 1, marginBottom: 4 }}></div>
            <span style={{
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '17px',
                color: '#8E9EC2',
                paddingBottom: 5
            }}>{translate('common.comission')} 0%</span>
        </div>
    )
}

export default AlfakitINRMC;