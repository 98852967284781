import React from 'react';
import {useStyles} from './helper';

const TextareaField = ({value, onChange, placeholder}) => {
    const classes = useStyles();

    return (
        <textarea 
            value={value}
            onChange={(v) => onChange(v)}
            placeholder={placeholder}
            className={classes.textarea}
        >
        </textarea>
    )
}

export default TextareaField;