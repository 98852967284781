import {types} from './types';

const initialState = {
    list: {
        tournaments: [],
        countPage: 1
    },
    view: {
        tournament: {
            top_places: []
        },
        users: [],
        users_all: []
    }
}

export function tournamentReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LIST_TOURNAMENT:
            return { ...state, list: action.payload};
        case types.SET_VIEW_TORUNAMENTX:
            return { ...state, view: action.payload};
        default:
            return state
    }
}