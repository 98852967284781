
import { postCreateFreespinPlayed } from '../api/postCreateFreespinPlayed';
import { setProfile } from '../../authorization/thunk/setProfile';
import { translate } from '../../../dictionaries';

export const setPostCreateFreespinPlayed = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postCreateFreespinPlayed(params);
            dispatch(setProfile())
        } catch(error) {
            alert(translate('error.fs'))
            console.log(error);
        }
    }
}