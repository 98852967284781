import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 1140, 
        margin: '0 auto', 
        marginTop: 80, 
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            width: 'auto'
        }
    },
    wrapTextBanner: {
        display: 'flex', 
        flexDirection: 'column', 
        paddingTop: 40, 
        alignItems: 'flex-start'
    },
    title: {
        
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '56px',
        lineHeight: '68px',
        textTransform: 'uppercase',
        userSelect: 'none',
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
            marginTop: 15,
            fontSize: 40,
            lineHeight: '40px'
        }
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '39px',
        lineHeight: '68px',
        textTransform: 'uppercase',
        userSelect: 'none',
        color: '#FF005C',
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
            lineHeight: '60px'
        }
    },
    prompt: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '29px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#fff',
        userSelect: 'none',
    },
    beginGame: {
        marginTop: 30,
        padding: '20px 80px',
        background: '#FF005C',
        borderRadius: '50px',
        boxShadow: '0px 4px 10px #D3193A',
        color: '#fff',
        cursor: 'pointer',
        fontFamily: 'Montserrat, sans-serif',
        transition: 'box-shadow .3s ease',
        userSelect: 'none',
        '&:hover': {
            boxShadow: 'none',
            transition: 'box-shadow .3s ease'
        }
    },
    wrapIcon: {
        [theme.breakpoints.down('sm')]: {
           display: 'none'
        }
    }
}))

export {
    useStyles,
}