import React from 'react';
import {translate} from '../../../../dictionaries';

const getDateTime = (value) => {
    const date = new Date(+value);

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    
    return `${hours}:${min}  ${day}.${month}.${year}`
}

const columns = [
    {id: 'recent', label: translate('common.recentGames'), width: '25%'},
    {id: 'win', label: translate('common.win'), width: '25%'},
    {id: 'result', label: translate('common.result'), width: '25%'},
    {id: 'date', label: translate('common.data'), width: '25%'},
]

const getRows = (values) => {
    return values.map((item, key) => {
        return {
            recent: <span style={{textTransform: 'capitalize'}}>{item.game_name}</span>,
            win: <span style={{color: '#08bf39', fontWeight: 700}}>{new Intl.NumberFormat('ru-RU').format(item.amount)}</span>,
            result: item.action,
            date: getDateTime(item.date_event),
        }
    })
}

const columnsMobile = [
    {id: 'recent', label: translate('common.game'), width: '20%'},
    {id: 'win', label:  translate('common.win'), width: '35%'},
    {id: 'date', label: translate('common.data'), width: '45%'},
]

const getRowsMobile = (values) => {
    return values.map((item, key) => {
        return {
            recent: 
            <div style={{
                width: '100%',
                display: 'flex', justifyContent: 'center'
            }}>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                    <img src={item.image} style={{width: 45, height: 45, background: '#eee', borderRadius: '50%', display: 'block'}} alt=""/>
                </div>
            </div>,
            win: <span style={{color: '#08bf39', fontWeight: 700}}>{new Intl.NumberFormat('ru-RU').format(item.amount)}</span>,
            date: getDateTime(item.date_event),
        }
    })
}

export {
    columns,
    columnsMobile,
    getRowsMobile,
    getRows
}