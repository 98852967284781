import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as Phone} from '../../../@casino/img/phone2.svg';
import {ReactComponent as Email} from '../../../@casino/img/email2.svg';
import TextFieldPassword from '../../../@casino/ui/TextFieldPassword';
import {useFormik, FormikContext} from "formik";
import TextFieldEmail from '../../../@casino/ui/TextFieldEmail';
import ActiveBonuses from './components/active_bonuses';
import TextField from '../../../@casino/ui/TextField';
import { useDispatch } from 'react-redux';
import { setPostAuthorization } from '../header/store/thunk/setPostAuthorization';
import { setPostRegistration } from '../header/store/thunk/setPostRegistration';
import {ReactComponent as ArrowDown} from '../../../@casino/img/arrowDown.svg';
import { translate } from '../../../dictionaries';
import Confirmation from '../header/components/confirmation';

const useStyles = makeStyles(() => ({
    paper: {
        minWidth: 360,
    },
    root: {
        width: 320, 
        background: '#001E5B', 
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)', 
        padding: '0 20px'
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    wrapTab: {
        display: 'flex', 
        justifyContent: 'space-between', 
        columnGap: 9,
        marginBottom: 17
    },
    tab: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
        width: '210px',
        height: '60px',
        background: '#00236E',
        borderRadius: 3,
        display: 'flex', 
        justifyContent: 'center',
        alignItems: 'center'
    },
    activeTab: {
        border: '1px solid #FF005C',
        borderRadius: 3,
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        padding: '20px 24px'
    },
    field: {
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: 3,
        color: '#FFFFFF',
        background: 'inherit',
        padding: '15px 19px',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    signin_label: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    rootWrapTab2: {
        marginTop: 12, marginBottom: 28, display: 'flex', justifyContent: 'space-between'
    },
    wrapPhone: {
        width: 111, 
        height: 60, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        border: '1px solid #FF005C',
        borderRadius: 3,
        padding: '0 17px'
    },
    phoneIcon: {
        fill: '#ffffff', 
        marginRight: 16
    },
    phoneLabel: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    wrapEmail: {
        width: 111, 
        height: 60, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        borderRadius: 3,
        padding: '0 17px',
        background: '#00236E'
    },
    emailIcon: {
        fill: '#8E9EC2', 
        marginRight: 16
    },
    emailLabel: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    btn: {
        width: '100%', 
        padding: '20px 0', 
        background: '#FF005C', 
        textAlign: 'center',
        boxShadow: '0px 4px 10px #D3193A',
        borderRadius: '50px',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '22px',
        cursor: 'pointer',
        color: '#FFFFFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.7)',
        order: 0,
        flexGrow: 0,
        margin: '0px 0px',
    },
    wrapBtn: {
        width: '100%', 
        display: 'flex'
    },
    helperPhone: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
    },
    mb15: {
        marginBottom: 15
    },
    mb10: {
        marginBottom: 10
    },
    wrapInputPhone: {
        display: 'flex', 
        position: 'relative', 
        width: '100%'
    },
    codeCountry: {
        position: 'absolute',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#7B61FF',
        top: 15,
        left: 17,
        userSelect: 'none'
    },
    mb22: {
        marginBottom: 22
    },
    wrapPhones: {
        overflowY: 'scroll', 
        display: 'flex', 
        position: 'absolute', 
        // padding: 10, 
        top: 0, 
        left: 0, 
        background: '#0034A5', 
        zIndex: 100, 
        width: 100, 
        height: 150, 
        display: 'flex', 
        flexDirection: 'column'
    },
    codePhone: {
        color: '#fff', 
        fontSize: 14, 
        padding: '5px 10px',
        lineHeight: '20px',
        width: '100%',
        cursor: 'pointer',
        zIndex: 10,
        // background: 'rgba(0,52,165, 1)',
        '&:hover': {
            background: 'rgba(0, 52, 165, .9)'
        }
    }
}))

const AuthMobile = ({authModal, setAuthModal, setShowModalConfirm, setLogin, showModalConfirm}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues = {
        email: '',
        phone: '',
        password: '',
        active: 'auth',
        check: true,
        promocode:  localStorage.getItem('referral') == 'undefined' ? '' : localStorage.getItem('referral'),
        method: 'phone',
        currency: 'UZS',
        code: '998',
        length: 9,
        country: 'UZS',
        activeBonus: ''
    }
   
    const onSubmit = (store) => {
        if (store.active === 'auth') {
            const tmp = {
                login: store.method === 'phone' ? store.code + '' + store.phone : store.email,
                password: store.password
            }
            dispatch(setPostAuthorization(tmp, setError));
        } else if (store.active === 'registration') {
            const tmp = {
                login: store.method === 'phone' ? store.phone : store.email,
                password: store.password,
                type: store.method,
                promocode: store.promocode,
                currency: store.currency,
                code: store.code,
                activeBonus: store.activeBonus
            }
            
            setLogin({
                login: store.method === 'phone' ? store.code + '' + store.phone : store.email,
                password: store.password
            });

            dispatch(setPostRegistration(tmp, setShowModalConfirm, setErrorRegistration))
        }
    }

    const enableReinitialize = true;
   
    const formik = useFormik({onSubmit, initialValues, enableReinitialize});

    const [error, setError] = React.useState(false);
    const [errorRegistration, setErrorRegistration] = React.useState(false);
    const [view, setView] = React.useState(false);
    const [code, setCode] = React.useState({code: '998',   label: '+998 (UZB)',    length: 9,      country: 'UZB'},);

    const handleChangeCode = (val) => {
        setCode(val);
        formik.setFieldValue('code', val.code);
        formik.setFieldValue('country', val.country);
        formik.setFieldValue('length', val.length);
        setView(false)
    }

    const arrayCodePhone = [
        {code: '998',   label: '+998 (UZB)',    length: 9,      country: 'UZB'},
    ]
    
    return (
        <Dialog
            open={authModal}
            classes={{
                paper: classes.paper
            }}
            onClose={() => {
                setAuthModal(false);
                formik.setFieldValue('email', '');
                formik.setFieldValue('phone', '');
                formik.setFieldValue('promocode', '');
                formik.setFieldValue('password', '');
                formik.setFieldValue('active', 'auth');
                formik.setFieldValue('method', 'phone');
                formik.setFieldValue('check', true);
            }}
        >
            <FormikContext.Provider value={formik}>
                <form onSubmit={formik.handleSubmit} className={classes.root}>
                    <div>
                        <div >
                            <h3 className={classes.title}>{translate('reg.helper')}</h3>
                        </div>
                        <div className={classes.wrapTab}>
                            <span 
                                className={formik.values.active === 'auth' ? classes.activeTab : classes.tab}
                                onClick={() => formik.setFieldValue('active', 'auth')}
                            >
                                {translate('common.signin')}
                            </span>
                            <span 
                                className={formik.values.active === 'registration' ? classes.activeTab : classes.tab}
                                onClick={() => formik.setFieldValue('active', 'registration')}
                            >
                                {translate('common.signup')}
                            </span>
                        </div>
                        <div>
                            <span className={classes.signin_label}>{translate('common.methodUsed')}</span>
                            <div className={classes.rootWrapTab2}>
                                <div 
                                    className={formik.values.method === 'phone' ? classes.wrapPhone : classes.wrapEmail}
                                    onClick={() => formik.setFieldValue('method', 'phone')}
                                >
                                    <Phone 
                                        className={formik.values.method === 'phone' ? classes.phoneIcon : classes.emailIcon}
                                    />
                                    <span 
                                        className={formik.values.method === 'phone' ? classes.phoneLabel : classes.emailLabel}
                                    >{translate('common.phoneUpper')}</span>
                                </div>
                                <div 
                                    className={formik.values.method === 'email' ? classes.wrapPhone : classes.wrapEmail}
                                    style={{
                                        width: 131
                                    }}
                                    onClick={() => formik.setFieldValue('method', 'email')}    
                                >
                                    <Email 
                                        className={formik.values.method === 'email' ? classes.phoneIcon : classes.emailIcon}
                                    />
                                    <span 
                                        className={formik.values.method === 'email' ? classes.phoneLabel : classes.emailLabel}
                                    >
                                        {translate('common.emailOrLogin')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            {
                                formik.values.method === 'phone' && 
                                <div className={classes.mb15}>
                                    <label htmlFor="phone" >
                                        <div className={classes.mb10}>
                                            <span className={classes.helperPhone}>
                                                {translate('common.numberPhone')}
                                            </span>
                                        </div>
                                        
                                        <div className={classes.wrapInputPhone} style={{position: 'relative'}}>
                                           <div>   
                                                <span className={classes.codeCountry} style={{cursor: 'pointer'}} onClick={() => setView(true)}><span><ArrowDown style={{fill: '#fff', width: 10, height: 10}} /></span> {code.label}</span>
                                            </div>
                                            {
                                                view &&
                                                <div className={classes.wrapPhones} style={{overflowX: 'hidden'}}>
                                                    {arrayCodePhone.map((item, key) => <span key={key} className={classes.codePhone} onClick={() => handleChangeCode(item)}>{item.label}</span>)}
                                                </div>
                                            }
                                            <input 
                                                type="text" 
                                                name="phone" 
                                                id="phone"
                                                placeholder={translate('common.fieldPhone')}
                                                value={formik.values.phone}

                                                onChange={(v) => {
                                                    if (!isNaN(+v.target.value) && v.target.value.length <= formik.values.length) {
                                                        formik.setFieldValue('phone', v.target.value)
                                                    }
                                                }}
                                                className={classes.field}
                                                style={{width: '100%', paddingLeft: 125}}    
                                            />
                                        </div>
                                    </label>
                                </div>
                            }
                            {
                                formik.values.method === 'email' && 
                                <TextFieldEmail
                                    name="email" 
                                    value={formik.values.email} 
                                    placeholder={translate('common.fieldEmail')}
                                    title="E-mail"
                                    onChange={(v) => {
                                        formik.setFieldValue('email', v.target.value)
                                        setError(false);
                                        setErrorRegistration(false)
                                    }}
                                />
                            }
                            <div>
                                <TextFieldPassword 
                                    name="password"
                                    value={formik.values.password}
                                    active={formik.values.active}
                                    placeholder={translate('common.fieldPassword')}
                                    title={translate('common.passwordUpper')}
                                    style={{marginBottom: 0}}
                                    onChange={(v) => {
                                        formik.setFieldValue('password', v.target.value)
                                        setError(false);
                                        setErrorRegistration(false);
                                    }}
                                />
                            </div>
                            <div>
                            {
                                formik.values.active === 'registration' && 
                                <TextField 
                                    name="promocode"
                                    value={formik.values.promocode}
                                    placeholder={translate('common.field')}
                                    onChange={(v) => {
                                    formik.setFieldValue('promocode', v.target.value)
                                    }}
                                />
                            }
                            </div>
                        </div>
                        <div style={{height: 13, marginBottom: 22}}>
                            {
                                error && 
                                <span style={{
                                    color: 'red',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: 11,
                                }}>{translate('common.badLoginOrPassword')}</span>
                            }
                            {
                                errorRegistration &&
                                <span style={{
                                    color: 'red',
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontSize: 11,
                                }}>{translate('common.userCreatedAfter')}</span>
                            }
                        </div>
                        <div className={classes.wrapBtn} style={{marginBottom: formik.values.active === 'auth' ? 35 : 12}} onClick={() => formik.submitForm()}>
                            <span className={classes.btn}>{formik.values.active === 'auth' ? translate('auth.signin') : translate('reg.signup')}</span>
                        </div>
                        {
                            formik.values.active === 'registration' && 
                            <div style={{marginBottom: 35}}>
                                <label htmlFor="check" >
                                    <input 
                                        type="checkbox" 
                                        name="check" 
                                        id="check"
                                        checked={formik.values.check}
                                        onChange={(v) => formik.setFieldValue('check', v.target.checked)}
                                    />
                                    <span style={{
                                        fontFamily: 'Montserrat, sans-serif',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        fontSize: '10px',
                                        lineHeight: '12px',
                                        color: '#8E9EC2',
                                        userSelect: 'none',
                                    }}>
                                        {translate('common.18yearBr')} <span style={{textDecoration: 'underline', cursor: 'pointer'}}>{translate('common.regulationsCheck')}</span>, <span style={{textDecoration: 'underline', cursor: 'pointer'}}>{translate('common.regulationsGame')}</span>, <span style={{textDecoration: 'underline', cursor: 'pointer'}}>{translate('common.regulationsCancelPays')}</span>
                                    </span>
                                </label>
                            </div>
                        }
                        <Confirmation showModalConfirm={showModalConfirm} setShowModalConfirm={setShowModalConfirm} initial={formik.values}/> 
                    </div>
                </form>
            </FormikContext.Provider>
        </Dialog>
    )
}

export default AuthMobile;