import React from 'react';
import {ReactComponent as ArrowBottom} from '../../../../../@casino/img/arrowBottom.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {translate} from '../../../../../dictionaries';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 41
    },
    wrapLabelMethod: {
        
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
        }
    },
    labelMethod: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    wrapVisa: {
        width: 'calc(100% - 38px)',
        maxWidth: 374,
        padding: '15px 19px',
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        display: 'flex', 
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer'
    },
    visa: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
        lineHeight: '16px'
    },
    arrow: {
        position: 'absolute',
        top: 20, 
        right: 20
    },
    wrapInput: {
        width: '100%',
        maxWidth: 414,
        border: '1px solid #8E9EC2',
        borderRadius: 3,
        position: 'relative'
    },
    inp: {
        width: '180px',
        padding: '14px 19px 14px 19px',
        outline: 'none',
        border: 'none',
        display: 'flex', 
        alignItems: 'center',
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF'
    },
    wrapBtn: {
        display: 'flex', 
        marginTop: 40, 
        marginLeft: 171,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            marginLeft: 0
        }
    },
    btn: {
        fontFamily: 'Montserrat, sans-serif',
        padding: '10px 40px',
        fontStyle: 'normal' ,
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        background: '#7B61FF',
        borderRadius: '50px',
        cursor: 'pointer',
    }
}))

const ReplenishmentBlock = ({formik, currency, setSelected}) => {
    const classes = useStyles();
    const [visible, setVisible] = React.useState(false);

    const getMethod = (cardType) => {
        switch (cardType) {
            case 1: return 'FKWallet';
            case 2: return 'FKWallet';
            case 4: return 'Visa';
            case 8: return 'MasterCard';
            case 13: return 'QIWI';
            case 14: return 'USDT(ERC20)';
            case 15: return 'USDT(TRC20)';
            case 26: return 'Ethereum';
            case 27: return 'SteamPay';
            case 25: return 'LiteCoin';
            case 24: return 'Bitcoin';
            case 33: return 'Perfect Money';
            case 39: return 'TRX';
            case 23: return 'Ripple';
            case 19: return 'Dogecoin';
            case 1001: return 'VISA/MASTERCARD';
            case 1002: return 'VISA/MASTERCARD';
            case 1100: return 'VISA/MASTERCARD';
            case 1300: return 'VISA/MASTERCARD';
            case 1400: return 'VISA/MASTERCARD';
            case 1500: return 'VISA/MASTERCARD';
            case 1600: return 'VISA/MASTERCARD';
            case 1700: return 'VISA/MASTERCARD';
            default: return 0
        }
    }

    return (
        <div className={classes.root}>
            <label htmlFor="method" className={classes.wrapLabelMethod}>
                <span className={classes.labelMethod}>{translate('common.method')}</span>
                <div className={classes.wrapVisa}>
                    <span className={classes.visa}>{getMethod(formik.values.cardType)}</span>
                    <ArrowBottom className={classes.arrow}/>
                </div>
            </label>
            
            <label htmlFor="" className={classes.wrapLabelMethod} style={{marginTop: 11, position: 'relative'}}>
                <span className={classes.labelMethod}>{translate('common.amount')}</span>
                <div className={classes.wrapInput}>
                    <input type="text" 
                        onChange={(v) => {
                            if (!isNaN(+v.target.value)) {
                                formik.setFieldValue('amount', +v.target.value)
                            }
                        }}
                        value={formik.values.amount}
                        className={classes.inp}
                    />
                    
                </div>
                
            </label>
            <span style={{color: 'red'}}>{translate('common.minimumReplenishemnt')} {formik.values.min} {currency}</span>
            
                    <div className={classes.wrapBtn}>
                        {
                            !visible && (
                                <span className={classes.btn} style={{background: formik.values.amount < formik.values.min && 'inherit', border: +formik.values.amount < +formik.values.min && '1px solid #7B61FF'}}
                                onClick={() => {
                                    if (+formik.values.amount >= +formik.values.min) {
                                        formik.submitForm();
                                        setVisible(true)
                                    }
                                }}
                                >
                                {translate('common.replenishments')}
                                </span>
                            )
                        }
                        {
                            visible && (
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                <CircularProgress />
                                </div>
                            )
                        }
                    </div>
            
            <div style={{marginTop: 80}}>
                <span style={{color: '#fff', fontFamily: 'Montserrat, sans-serif', fontSize: 14, cursor: 'pointer'}} 
                    onClick={() => {
                        setSelected('')
                    }}>{translate('common.back')}</span>
            </div>
        </div>
    )
}

export default ReplenishmentBlock