import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Banner from './Banner1.png';
import {translate} from '../../../../../../dictionaries';
import {ReactComponent as Timer} from '../../../../../../@casino/img/timer.svg';
import {useRouter} from '../../../../../../@casino/hook/useRouter';


const useStyles = makeStyles(() => ({
    bannerTitle: {
        color: 'gold',
        fontSize: 16,
        textTransform: 'uppercase',
        position: 'relative',
        left: 10,
        '&::after': {
            content: "''",
            position: 'absolute',
            display: 'block',
            top: 0,
            left: -10,
            width: 2,
            height: 16,
            background: '#FF005C'
        }
    }
}))

const BannerHappy = () => {
    const classes = useStyles();
    const router = useRouter();
    const color = ['#CCCCFF', '#00FF99', '#33FFFF', '#FFFFFF', '#0099FF', '#FF00CC', '#FF0066', '#FFFF33', '#990000'];
    const [colors, setColors] = React.useState('pink');
    const [count, setCount] = React.useState(0);
    const [colorText, setColorsText] = React.useState('#CCCCFF');

    React.useEffect(() => {
        const interval = setTimeout(() => {
            setColors(color[(Math.random(0) * 5).toFixed(0)])
            setColorsText(color[(Math.random(0) * 5).toFixed(0)]);
            setCount(count+1)
        }, 200);
        
        return () => clearTimeout(interval);
    }, [count]);
    
    return (
        <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            background: `url(${Banner})`,
            backgroundSize: 'cover',
            backgroundPositionY: -15,
            backgroundPositionX: -10
        }}>
            <div style={{
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                background: 'linear-gradient(0deg, rgba(2,0,36,.8) 5%,rgba(255,255,255,.05) 100%)'
            }}>

            </div>
            <div style={{border: `5px dotted ${colors}`,width: '80%', height: 50, background: 'rgba(2, 0, 4, 0.5)', position: 'absolute', left: 0, bottom: 30, padding: 5, borderRadius: 4, display: 'flex', alignItems: 'center'}}>
                <span style={{color: colorText, '-webkit-text-shadow': '#5dc8e5 0 0 100px', fontWeight: 700, fontFamily: 'Monthserrat, sans-serif', fontSize: 50, marginRight: 12}}>200 000</span>
                <span 
                    onClick={() => router.history.push('/mobile/tournament/view/8')}
                    style={{cursor: 'pointer', color: '#fff', padding: 10, borderRadius: 10, background: 'rgb(255,0,92)', width: '30%', display: 'flex', justifyContent: 'center', marginTop: 4, marginBottom: 12}}>
                    {translate('common.view')}
                </span> 
            </div>
            <div style={{position: 'absolute', bottom: 5, left: 10}}  >
                <span style={{fontSize: 14}} className={classes.bannerTitle}>
                    {translate('common.tournament')}
                </span>
            </div>
            
        </div>
    )
}

export default BannerHappy;