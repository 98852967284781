
import { postCreateFreespinV1 } from '../api/postCreateFreespinV1';
import { translate } from '../../../dictionaries';

export const setPostCreateFreespinV1 = (setShowModal) => {
    return async (dispatch, getState) => {
        try {
            if (setShowModal) {
                setShowModal(false)
            }
            const {data} = await postCreateFreespinV1();
            
            setTimeout(() => {
                window.location.replace('/games/' + data.data.game_uuid);    
            }, 10000);

            
        } catch(error) {
            alert(translate('error.fs'))
            console.log(error);
        }
    }
}