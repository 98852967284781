import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Dialog, makeStyles } from '@material-ui/core';
import { ReactComponent as Present } from '../../@casino/img/present.svg';
import { setPostCreateFreespinV1 } from '../../store/freespin/thunk/setPostCreateFreespinV1';
import Modal from './Modal';
import { translate } from '../../dictionaries';

const useStyles = makeStyles((theme) => ({
    present: {
        position: 'fixed',
        bottom: 30,
        right: 30,
        zIndex: 100,
        animation: '$presentEffect .9s infinite linear',
        '-webkit-animation': '$presentEffect .9s infinite linear',
        cursor: 'pointer'
    },
    "@keyframes presentEffect": {
        "0%": {
            bottom: 30,
            transform: 'scale(1)'
        },

        "50%": {
            bottom: 50,
            transform: 'scale(1.3)'
        },

        "100%": {
            bottom: 30,
            transform: 'scale(1)'
        }
    },
}))

const Freespin = ({profile}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fs = profile?.fs;

    const currentDate = new Date().getTime();
    
    const [finishTime] = React.useState(Number(profile?.last_issue_fs) + 86400000);
    const [[diffDays, diffH, diffM, diffS], setDiff] = React.useState([0, 0, 0, 0]);
    const [tick, setTick] = React.useState(false);

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff < 0) return 
        setDiff([
            Math.floor(diff / 86400),
            Math.floor((diff / 3600) % 24),
            Math.floor((diff / 60) % 60),
            Math.floor(diff % 60)
        ])
    }, [tick, finishTime])

    React.useEffect(() => {
        if (profile?.active_bonuses_registration === 'fs50' && (Number(profile?.last_issue_fs) + 86400000) > currentDate && Number(profile?.count_issue_fs) < 3) {
            const timerID = setInterval(() => setTick(!tick), 1000);
            return () => clearInterval(timerID);
        }
    }, [tick]);

    const [isActiveBtn, setIsActiveBtn] = React.useState(true);
    const [isShowModal, setIsShowModal] = React.useState(true);

    return (
        <div>
            {
                !isShowModal && <Modal />
            }
            {
                profile?.connection_groups === 'true' && fs?.status && Number(fs?.quantity > 0) && (!(Number(profile?.fs?.date_from) + 172800000 > new Date().getTime()) || !(Number(profile?.fs?.freespin_id) + 172800000 > new Date().getTime())) && (
                    <div
                        className={classes.present}
                        onClick={() => {
                            if (isActiveBtn) {
                                if (profile?.connection_groups === 'true') {
                                    dispatch(setPostCreateFreespinV1(setIsShowModal));
                                }
                                setIsActiveBtn(false);
                            }
                        }}
                    >
                        <div style={{ position: 'absolute', top: -10, left: -110, background: 'rgba(0,0,0,.4)', borderRadius: 5, padding: '5px 10px' }}>
                            {
                                profile?.active_bonuses_registration === 'fs50' && (Number(profile?.last_issue_fs) + 86400000) > currentDate && Number(profile?.count_issue_fs) <= 3 && (
                                    <span style={{ color: '#fff' }}>{translate('common.claimBonusAfter')} {diffH < 10 ? '0' + diffH : diffH}:{diffM < 10 ? '0' + diffM : diffM}:{diffS < 10 ? '0' + diffS : diffS} </span>
                                )
                            }
                            {
                                profile?.active_bonuses_registration === 'fs50' && (Number(profile?.last_issue_fs) + 86400000) < currentDate && Number(profile?.count_issue_fs) <= 3 && (
                                    <span style={{ color: '#fff' }}>{translate('common.claimBonus')}</span>
                                )
                            }
                            {
                                Number(profile?.count_issue_fs) > 3 && profile?.active_bonuses_registration === 'fs50' && <span style={{ color: '#fff' }}>{translate('common.claimBonus')}</span>
                            }
                            {
                                profile?.active_bonuses_registration === 'alldeposit' && profile?.connection_groups !== 'true' && <span style={{ color: '#fff' }}>{translate('common.claimBonus')}</span>
                            }
                            {
                                profile?.connection_groups === 'true' && <span style={{ color: '#fff' }}>{translate('common.claimBonus')}</span>
                            }
                        </div>
                        <Present />
                    </div>
                )
            }
        </div>
    )
}

export default Freespin;