import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as User} from '../../img/user.svg';
import { translate } from '../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex'
    },
    wrapLogo: {
        width: 55, 
        height: 55, 
        borderRadius: '50%', 
        background: '#001137', 

        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    logo: {
        width: 45,
        height: 45,
        borderRadius: '50%'
    },
    wrapAmount: {
        display: 'flex', 
        flexDirection: 'column', 
        marginLeft: 15
    },
    labelName: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        marginTop: 5
    },
    amount: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#fff',
        marginTop: 5
    }
}))

const GamerItem = ({label_name, amount, currency}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.wrapLogo}>
               <User />
            </div>        
            <div className={classes.wrapAmount}>
                <span className={classes.labelName}>{label_name}</span>
                <span className={classes.amount}>{translate('common.point')}: {amount} </span>
            </div>
        </div>
    )
}

export default GamerItem;