import {types} from './types';

export const setReplenishmentCreateAction = (payload) => {
    return {
        type: types.SET_REPLENISHMENT_CREATE,
        payload,
      }
}

export const setGetListPaymentAction = (payload) => {
  return {
    type: types.SET_GET_LIST_PAYMENT,
    payload
  }
}