import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRouter } from '../../../../../@casino/hook/useRouter';
import Label from '../../../../../@casino/ui/label';
import {useStyles} from './helper';
import {translate} from '../../../../../dictionaries';

const Game = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
           
        </div>
    )
}

export default Game;