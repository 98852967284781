import {types} from './types';

const initialState = {
    status: null,
}

export function callbackReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_STATUS:
            return { ...state, status: action.payload};
        default:
            return state
    }
}