import React from 'react';

const HelpTR = () => {
    return (
        <div class="page-content rules">
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 28, color: 'gold'}}>Sıkça Sorulan Sorular</h2>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Kumarhaneye nasıl kayıt olunur?</h3>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Kayıt hiç bu kadar kolay olmamıştı. Sitenin herhangi bir sayfasında "Giriş" butonuna tıklayın, "Kayıt" sekmesini seçin ve açılan formda gerekli tüm alanları doldurun. Doldurduktan sonra "Kayıt Ol" düğmesine tıklayın. Bunun ardından, e-posta adresinize bir onay e-postası gönderilecektir. Kaydınızı onaylamak için bu e-postadaki bağlantıyı takip etmeniz yeterlidir.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Şifremi unuttum, ne yapmalıyım?</h3>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Şifrenizi kurtarmak için yetkilendirme formunu açmanız ve "Unuttun mu?" bağlantısını tıklamanız gerekir. Ardından, e-posta veya telefon numaranızı girmeniz ve "Geri Yükle" düğmesini tıklamanız gerekecektir. Bundan sonra, size e-posta veya telefon ile gönderilecek talimatları izleyin.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>E-postamı unuttum, ne yapmalıyım?</h3>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Bir tanımlama sürecinden geçmeniz gerekecek. Prosedür düzenlenmemiştir ve her müşteri için benzersizdir. Her durumda, size yardımcı olmak için elimizden geleni yapacağız. Kullanıcı adınızı unutmamaya çalışın.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Kimlik kanıtı gerekli mi?</h3>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Kesinlikle hayır. Gizliliğinize önem veriyoruz ve kimliğinizi belgelerle doğrulamanızı istemiyoruz. Bu sadece son derece istisnai durumlarda gerekli olabilir.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Hesabın yenilenmesi ne kadar hızlı?</h3>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Neredeyse anında. Ödeme sağlayıcısının hızına bağlıdır.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Bonus nasıl alınır?</h3>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Bonus almak için ayrıntılı koşulları "Bonuslar" sayfasında bulabilirsiniz.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Bonus bahis nedir?</h3>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Bonusun çevrimi, bonus şartlarının izin verdiği oyunlarda oyun sırasında gerçekleşir. Bahis yaparken, bu miktarın değeri istenen değere ulaşana kadar bahisleriniz toplanır. Genellikle bu, bahis miktarı ile çarpılan bonus miktarıdır. Örnek: bonus miktarı 100 ruble ise ve bahis 10 ise, bahsi tamamlamak için bahis yapmanız gerekir: 100 ruble × 10 = 1000 ruble.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Bonusum silinmedi, ne yapmalıyım?</h3>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Bonus şartlarını dikkatlice okuyunuz. Belki de şartları ihlal ederek bahis oynamaya çalışıyorsunuz. Örneğin, izin verilen oranı aşan bir oranda bahis oynamak veya bahis için uygun olmayan bir oyun oynamak. Kendinizde herhangi bir hata bulamadıysanız, lütfen desteğe başvurun. Size kesinlikle yardımcı olacağız.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Kişisel bonuslar var mı?</h3>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Evet, elbette oyuncularımıza çok özen gösteriyoruz ve çok ilginç koşullar sağlıyoruz.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Oyun donuyor, başlamıyor, ne yapmalıyım?</h3>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Sayfayı yeniden yüklemeyi deneyin. Veri kaybından korkmayın. Oyun kesintiye uğradığı yerden devam eder. Bu işe yaramazsa ve donma tekrar oluşursa, tarayıcınızı yeniden başlatmayı deneyin (Google Chrome, Mozilla Firefox, Opera, Internet Explorer). Bu işe yaramazsa, tarayıcınızın önbelleğini temizleyin. Yukarıdakilerin hiçbiri yardımcı olmazsa, desteğe başvurun.
            </p>
            <h3 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Sormak istediğiniz bir şey var mı?</h3>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Her zaman çevrimiçi destek sohbeti ile iletişime geçebilirsiniz. Sorunuzu mümkün olduğunca çabuk değerlendireceğiz ve kapsamlı bir cevap vermeye çalışacağız.
            </p>
        </div>
    )
}

export default HelpTR;