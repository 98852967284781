import React from 'react';
import {getArray} from '../../../../../@casino/lib/getArray';
import Label from '../../../../../@casino/ui/label';
import {useStyles} from './helper'
import { translate } from '../../../../../dictionaries';
import { useSelector } from 'react-redux';

const CurrentResult = ({tournament}) => {
    const classes = useStyles();
    const places_table = getArray(tournament.users, Math.ceil(tournament.users.length / 3));
    const places_table_mobile = getArray(tournament.users, Math.ceil(tournament.users.length / 1));
    let placesSize = 0;
    let placesSizeMobile = 0;
    const profile = useSelector(state => state.profile.view);
 
    return (
        <div>
            <div className={classes.wrapLabel}>
                <Label title={translate("commont.currentResult")} />
            </div>
            <div className={classes.wrapBody}>
                <div style={{width: '100%'}}>
                   {
                        places_table.length === 0 &&
                        <div className={classes.wrapInfo}>
                            <span  className={classes.info}>
                                {translate('lottery.infoNotFound')}
                            </span>
                        </div>
                    }
                    <div style={{marginBottom: 12}}>
                        <span style={{fontFamily: 'Montserrat, sans-serif', color: 'red'}}>{translate('tournament.position')}</span>
                    </div>
                    
                    <div className={classes.wrapPlacesTable}>
                        {
                            places_table.map((item, key) => {
                                return (
                                    <div key={key}>
                                        <div className={classes.wrapHeaderTable}>
                                            <span className={classes.participant}>{translate('common.participant')}</span>
                                            <span className={classes.result}>{translate('common.result')}</span>
                                        </div>
                                    <div>
                                    {
                                        item.map((data, key) => {
                                            placesSize += 1;
                                            
                                            return (
                                                <div key={key} className={classes.wrapRow} style={{background: data.id == profile?.id ? 'gold' : !(key % 2) && '#142A8F'}}>
                                                    <span className={classes.placesSize} >{placesSize}</span>
                                                    <span className={classes.login} style={{color: data.id == profile?.id && '#000'}}>
                                                        {data.id}
                                                    </span>
                                                    <span className={classes.points} style={{color: data.id == profile?.id && '#000'}}>
                                                        {+data.points - 10}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            )})
                        }
                    </div>
                    <div className={classes.wrapHeaderTableMobile}>
                        <span className={classes.participant}>{translate('common.participant')}</span>
                        <span className={classes.result}>{translate('common.result')}</span>
                    </div>
                    <div className={classes.wrapPlacesTableMobile}>
                        {
                            places_table_mobile.map((item, key) => {
                                return (
                                    <div key={key}>
                                    {
                                        item.map((data, key) => {
                                            placesSizeMobile += 1;
                                            
                                            return (
                                                <div key={key} className={classes.wrapRow} style={{background: !(key % 2) && '#142A8F', }}>
                                                    <span className={classes.placesSize}>{placesSizeMobile}</span>
                                                    <span className={classes.login}>
                                                        {data.id}
                                                    </span>
                                                    <span className={classes.points}>
                                                        {+data.points - 10}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                            )})
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentResult;