import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../dictionaries';
import FS from '../img/fs3.png';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 109,
    },
    wrapTitle: {
        marginBottom: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        userSelect: 'none'
    },
    bonus: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#001137',
        borderRadius: '3px',
        width: 300,
        height: 120,
        marginRight: 20
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '51%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        userSelect: 'none',
        paddingRight: 20
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    },
    iconBonus: {
        width: 117,
    },
    wrapBonusDesktop: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 31,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            marginBottom: 40
        }
    },
    field2: {
        width: 300,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        userSelect: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
}))

const FreespinWin = ({ profile, onSubmit, btn, date, max_date, wager, wager_amount, wager_played, amount}) => {
    document.title = translate('title.bonuses')
    const classes = useStyles();

    const [finishTime] = React.useState(Number(date) + max_date);

    const [[diffDays, diffH, diffM, diffS], setDiff] = React.useState([0, 0, 0, 0]);
    const [tick, setTick] = React.useState(false);

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff < 0) return
        setDiff([
            Math.floor(diff / 86400),
            Math.floor((diff / 3600) % 24),
            Math.floor((diff / 60) % 60),
            Math.floor(diff % 60)
        ])
    }, [tick, finishTime])

    React.useEffect(() => {
        const diff = (finishTime - new Date()) / 1000;
        if (diff > 0) {
            const timerID = setInterval(() => setTick(!tick), 1000);
            return () => clearInterval(timerID);
        }
    }, [tick]);

    return (   
        <div>
            <div>
                <div style={{ boxShadow: 'rgba(0, 11, 36, 0.5) 0px 10px 20px', maxWidth: 380, width: '100%', borderRadius: 30, height: 405, position: 'relative' }}>
                    <div style={{
                        maxWidth: 380,
                        width: '100%',
                        height: 425,
                        background: '#012E90',
                        boxShadow: ' inset 0px 0px 100px 14px rgba(83, 28, 201, 1)',
                        borderRadius: 30,
                        position: 'relative'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 30, marginBottom: 20 }}>
                            <img src={FS} alt="" style={{ width: '300px' }} />
                        </div>
                        <div style={{ padding: '0 30px', display: 'flex', justifyContent: 'space-between' }}>
                            <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 18, textTransform: 'uppercase', textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{translate('navigation.bonuses')}</span>
                            <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 18, textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{amount} {profile?.currency}</span>
                        </div>

                        <div style={{ margin: '0 30px', display: 'flex', flexDirection: 'column', marginTop: 20, borderRadius: 4, background: 'rgba(255,255,255,0.1)' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', color: '#fff', fontSize: 14 }}>
                                <span>{translate('common.wager1')}</span>
                                <span>x{wager}</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 10px', color: '#fff', fontSize: 14 }}>
                                <span>{translate('common.event')}</span>
                                <span>{diffDays} D. {diffH < 10 ? '0' + diffH : diffH}:{diffM < 10 ? '0' + diffM : diffM}:{diffS < 10 ? '0' + diffS : diffS}</span>
                            </div>
                        </div>
                        {
                            btn && (
                                <div style={{margin: '0 30px'}}>
                                    <span style={{display: 'flex', justifyContent: 'space-between', padding: '5px 10px', color: '#fff', fontSize: 14 }}>{translate('fs.afterActive')} {wager * amount} {profile?.currency}</span>
                                </div>
                            )
                        }
                        {
                            btn && (
                                <div style={{ display: 'flex', justifyContent: 'flex-start', position: 'absolute', bottom: 20, left: 30 }}>
                                    <span
                                        onClick={onSubmit}
                                        style={{
                                            background: '#ff005d',
                                            padding: '5px 10px',
                                            borderRadius: 6,
                                            color: '#ffffff',
                                            cursor: 'pointer'
                                        }}
                                    >{btn}</span>
                                </div>
                            )
                        }
                        {
                            !btn && (
                                <div>
                                    <div style={{margin: '0 30px', width: 'calc(100% - 60px)', display: 'flex', justifyContent: 'space-between', position:'absolute', bottom: 60}}>
                                        <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 15, textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{new Intl.NumberFormat('ru-RU').format(wager_played)}</span>
                                        <span style={{ fontFamily: 'Montserrat, sans-serif', color: '#fff', fontWeight: 700, fontSize: 15, textShadow: '2px 2px 15px rgba(0,0,0,0.57)' }}>{new Intl.NumberFormat('ru-RU').format(wager_amount)}</span>
                                    </div>
                                    <div style={{margin: '0 30px', width: 'calc(100% - 60px)', height: 10, borderRadius: 8, background: 'rgba(0,0,0,.3)', position:'absolute', bottom: 40}}>
                                        <div style={{width: `${wager_played/wager_amount*100}%`, background: '#D0BD7C', height: 10, borderRadius: 8}}></div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {
                        wager_amount && wager_amount <= wager_played && (
                            <div style={{ position: 'absolute', top: 0, left: 0, backdropFilter: 'blur(2px)', width: '100%', height: '100%'}}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <span
                                        onClick={onSubmit}
                                        style={{
                                            background: '#ff005d',
                                            padding: '15px 30px',
                                            borderRadius: 8,
                                            color: '#ffffff',
                                            cursor: 'pointer',
                                            fontSize: 20
                                        }}
                                    >{translate('common.pickupBonus')}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
           
        </div>
    )
}

export default FreespinWin;