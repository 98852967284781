import React from 'react';
import BonusComponent from '../../../../../../../@casino/ui/bonus';
import { useDispatch, useSelector } from 'react-redux';
import MainPageBlock from '../../../../../../../@casino/ui/mainPageBlock';
import {icon, mask, getLabel} from './helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { setGetListBonuses } from '../../../../../bonuses/store/thunk/setGetListBonuses';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex', 
        justifyContent: 'space-between', 
        flexWrap: 'wrap', 
        gap: 10,
        background: 'rgba(2, 0, 4, 0.55)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    roots: {
        width: 560, 
        height: 200, 
        filter: 'drop-shadow(0px 10px 20px rgba(0, 11, 36, 0.5))', 
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: 560,
        }
    },
    wrapTitleBlock: {
        padding: '32px 37px 0px 40px', 
        width: 320,
        minHeight: 155,
        marginBottom: 30
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        display: 'flex',
        margin: 0,
        padding: 0,
        color: "#fff"
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        lineHeight: '20px',
        color: '#fff',
        marginTop: 20,
        marginBottom: 7
    },
    view: {
        textDecoration: 'underline', 
        color: '#D2D8E3', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 12, 
        cursor: 'pointer', 
        userSelect: 'none'
    },
    wrapBtn: {
        paddingLeft: 40, 
        paddingRight: 30, 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center'
    },
    btn: {
        padding: '10px 26px', 
        background: '#FF005C', 
        borderRadius: '50px', 
        color: '#fff', 
        fontFamily: 'Montserrat, sans-serif', 
        cursor: 'pointer', 
        userSelect: 'none',
        transition: 'background .3s ease',
        '&:hover': {
            background: '#ED074C',
            transition: 'background .3s ease'
        }
    },
    icon: {
        width: 159, 
        height: 150, 
        position: 'absolute', 
        top: 69, 
        right: 30,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    }
}))


const BonusesMobile = ({auth}) => {
    document.title = translate('title.bonuses');
    const router = useRouter();
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetListBonuses());
    }, [])

    const bonuses = useSelector(state => state.bonuses.listBonuses);
    const cals = window.screen.height - 780;

    return (
        <div style={{
            width: 'calc(100% - 40px)',
            minHeight: cals,
            padding: '20px',
            marginBottom: 12
        }}
            className={classes.root}>
                <div style={{marginBottom: 12}}>
                    <span style={{fontFamily: 'Montserrat, sans-serif', fontSize: 20, color: '#fff'}}>{translate('navigation.bonuses')}</span>
                </div>
            {
                bonuses.map((item, key) => {
                    if (item.label !== 'bonus-referral' && item.label !== 'bonus-promocode' && item.label !== 'bonus-personal') {
                            return (
                                <div key={key} className={classes.roots} style={{marginBottom: 19, background: `url(${mask[key%6]}) top right`}}>
                                    <div className={classes.wrapTitleBlock}>
                                        <h3 className={classes.title}>{getLabel(item.label)}</h3>
                                        <p className={classes.description}>{item.description}</p>
                                    </div>
                                    
                                </div>
                            )
                        }
                    })
                }
        </div>
    )
}

export default BonusesMobile;