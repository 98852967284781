import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {translate} from '../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 1140,
        margin: '120px auto',
        color: '#fff',
        fontFamily: 'Montserrat, sans-serif',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            padding: '0 20px'
        }
    }
}))

const Contacts = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div dangerouslySetInnerHTML={{__html: translate("common.contacts")}} />
            <br/><br/>
            <a href="mailto:win777fun@gmail.com" style={{color: '#fff'}}>win777fun@gmail.com</a>
            <br/>
            <a href="https://t.me/winbingo" style={{color: '#fff'}}>Telegram</a>
            <br/>
            <a href="tel:+50684441596" style={{color: '#fff'}}>+506 8444 1596</a>
            
        </div>
    )
}

export default Contacts;