import React from 'react';
import BackgroundBonuses from '../../../../../@casino/img/backgroundBonuses.png';
import {ReactComponent as Star} from '../../../../../@casino/img/star2.svg';
import Confetti from 'react-confetti'
import { translate } from '../../../../../dictionaries';

const Notification = ({labelHelper, label, amount, success}) => {
    return (
        <div style={{width: 320, height: 500, position: 'fixed', top: '2%',right: '1%', zIndex: 900, display: 'flex', alignItems: 'center', transition: 'all 1s ease'}}>
            <div style={{width: '100%', height: '100%', background: `url(${BackgroundBonuses})`, backgroundSize: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 10}}>
                <span style={{
                    fontSize: 22,
                    color: '#fff',
                    fontWeight: 700,
                    fontFamily: 'Montserrat, sans-serif',
                    paddingLeft: 10,
                    marginBottom: 23,
                }}>{labelHelper}</span>
                <div style={{display: 'flex', alignItems: 'center', gap: 14, marginBottom: 23}}>
                    <Star style={{width: 60, height: 57}}/>
                    <span style={{
                        fontSize: 18,
                        color: '#fff',
                        fontWeight: 700,
                        fontFamily: 'Montserrat, sans-serif',
                    
                    }}>
                        {label}
                    </span>
                </div>
                <div style={{marginBottom: 70}}>
                    <span style={{
                        fontSize: 25,
                        color: '#fff',
                        fontWeight: 700,
                        fontFamily: 'Montserrat, sans-serif'
                    }}>
                        {amount}
                    </span>
                </div>
                <div style={{display: 'flex'}}>
                    <span style={{
                        padding: 15,
                        background: '#FF005C',
                        color: '#fff',
                        fontWeight: 700,
                        width: 200,
                        fontFamily: 'Montserrat, sans-serif',
                        borderRadius: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => success()}
                    >{translate('common.ok')}</span>
                </div>
                </div>
            </div>
            <Confetti
                width={320}
                height={500}
            />
        </div>
    )
}

export default Notification;