import {types} from './types';

const initialState = {
    url: null
}

export function gamesBetReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GAMES_BET:
            return { ...state, url: action.payload};
        default:
            return state
    }
}