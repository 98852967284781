import React from 'react';
import {useFormik, FormikContext} from "formik";
import { useDispatch, useSelector } from 'react-redux';
import {setPostCreatePromocode} from '../layout/store/thunk/setPostCreatePromocode';
import {setGetReferralsCheck} from '../layout/store/thunk/setGetReferralsCheck';
import {setGetReferrerList} from '../layout/store/thunk/setGetReferrerList';
import {getRows, columns, useStyles, getRowsMobile, columnsMobile} from './helper';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import WinTable from '../../../@casino/ui/WinTable';
import {translate} from '../../../dictionaries';

const ReferralProgramm = () => {
    document.title = translate('title.referral')
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetReferralsCheck());
        dispatch(setGetReferrerList({
            page: 1,
            rowsPerPage: 10
        }));
    }, [])

    const initialValues = useSelector(state => state.profile_page.promocode);
  
    const onSubmit = (store) => {
        dispatch(setPostCreatePromocode(store));
    }

    const enableReinitialize = true;

    const formik = useFormik({onSubmit, initialValues, enableReinitialize});
    const referrals_list = useSelector(state => state.profile_page.referrals_list);

    const rows = React.useMemo(() => getRows(referrals_list.list, initialValues.percent), [referrals_list]);
    const rowsMobile = React.useMemo(() => getRowsMobile(referrals_list.list, initialValues.percent), [referrals_list]);
    
    return (
        <FormikContext.Provider value={formik}>
            <form onSubmit={formik.handleSubmit} >
                <WinPageBlock 
                    title={translate('navigation.referralProfile')}
                >
                    {
                        formik.values.disabled &&
                        <div className={classes.wrapPromocode}>
                            <span className={classes.titlePromocode}>{translate('common.promocode')}</span>
                            <span className={classes.labelPromocode}>{initialValues.label}</span>
                        </div>
                    }   
                    {
                       formik.values.disabled &&
                       <div style={{marginTop: 20}}>
                        <span className={classes.titlePromocode}>{translate('common.link')}: <span style={{paddingLeft: 30, userSelect: 'all'}}>{window.location.origin}/?referral={initialValues.label}</span></span>
                       </div> 
                    }
                    {
                        !formik.values.disabled &&
                        <div className={classes.disabledBlock}>
                            <span className={classes.titleCreateLabel}>{translate('common.createPromocode')}</span>
                            <input 
                                type="text" 
                                className={classes.inputPromocode}
                                placeholder={translate('common.fieldPromocode')}
                                value={formik.values.label} 
                                onChange={(v) => formik.setFieldValue('label', v.target.value)}
                            />
                            <span 
                                className={classes.createBtn}
                                onClick={() => formik.submitForm()} 
                            >
                                {translate('common.create')}
                            </span>
                        </div>
                    }
                    <div className={classes.wrapBlockInformation}>
                        <span className={classes.information}>
                            {translate("common.promocodeHelper")}
                        </span>
                        {
                            formik.values.disabled &&
                            <>
                                <div className={classes.wrapProfit}>
                                    <span className={classes.profitLabel}>{translate('common.job')}</span> 
                                    <span className={classes.profit}>
                                        {new Intl.NumberFormat('ru-RU').format(initialValues.balance ? initialValues.balance : 0)}
                                    </span>
                                </div>
                                <div className={classes.wrapProfit}>
                                    <span className={classes.profitLabel}>{translate('common.output')}</span> 
                                    <span className={classes.profit}>
                                        {new Intl.NumberFormat('ru-RU').format(initialValues.output ? initialValues.output : 0)}
                                    </span>
                                </div>
                            </>
                        }
                    </div>
        
                    <div className={classes.wrapTitle}>
                        <h3 className={classes.title}>
                            {translate('common.people')}
                        </h3>
                    </div>
                    <div className={classes.desktop}>
                        <WinTable 
                            columns={columns}
                            rows={rows}
                            pageCount={referrals_list.page}
                            changePage={(page) => dispatch(setGetReferrerList({
                                page,
                                rowsPerPage: 10
                            }))}
                            labelNotFound={translate('common.promocodeNotFound')}
                        /> 
                
                    </div>
                    <div className={classes.mobile}>
                        <WinTable 
                            columns={columnsMobile}
                            rows={rowsMobile}
                            pageCount={referrals_list.page}
                            changePage={(page) => dispatch(setGetReferrerList({
                                page,
                                rowsPerPage: 10
                            }))}
                            labelNotFound={translate('common.promocodeNotFound')}
                        /> 
                
                    </div>
                    
                </WinPageBlock>
            </form>
        </FormikContext.Provider>
    )
}

export default ReferralProgramm;