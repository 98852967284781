import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        paddingLeft: 109,
    },
    wrapTitle: {
        paddingTop: 47,
        marginBottom: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
        userSelect: 'none'
    },
    bonus: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        background: '#001137',
        borderRadius: '3px',
        cursor: 'pointer',
        marginBottom: 31,
        width: 300,
        height: 120
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '51%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        userSelect: 'none',
        paddingRight: 20
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    },
    iconBonus: {
        width: 117,
        height: 117
    }
}))

export {
    useStyles
}