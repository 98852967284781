import {types} from './types';

const initialState = {
    list: [],
    history: {
        list: [],
        count: 1
    }
}

export function jackpotReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_JACKPOT:
            return { ...state, list: action.payload};
        case types.SET_HISTORY:
            return { ...state, history: action.payload};
        default:
            return state
    }
}