import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 120, 
        maxWidth: 1140, 
        width: '100%', 
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        color: '#fff',
        textAlign: 'center',
        marginBottom: 52,
    },
    flexBox: {
        display: 'flex', 
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }
    },
    wrapBonuses: {
        display: 'flex', 
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20
        }
    },
    wrapTournament: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px'
        }
    }
}));

const getLabel = (value) => {
    switch (value) {
        case 'bonus-registration': return translate('bonus.registration');
        case 'bonus-referral': return translate('bonus.referral');
        case 'bonus-everyday': return translate('bonus.everyday');
        case 'bonus-promocode': return translate('bonus.promocode');
        case 'bonus-weekly': return translate('bonus.weekly');
        case 'bonus-cashback': return translate('bonus.cashback');
        case 'bonus-first-deposit': return translate('bonus.first-deposit');
        case 'bonus-next-deposit': return translate('bonus.next-deposit');
        case 'bonus-personal': return translate('bonus.personal');
        case 'bonus-tournament': return translate('bonus.tournament');
        case 'bonus-loterry': return translate('bonus.loterry');
        default: return translate('common.default');
    }
}

export {
    getLabel,
    useStyles
}