import React from 'react';
import { translate } from '../../dictionaries';

const configNagiation = [
    {
        label: translate('navigation.main'),
        path: '/',
        exact: true,
        component: React.lazy(() => import('./home/page'))
    },
    {
        label: translate('navigation.jackpot'),
        path: '/jackpot',
        exact: true,
        component: React.lazy(() => import('./jackpot/page/list'))
    },
    // {
    //     label: translate('navigation.tournament'),
    //     path: '/tournament',
    //     exact: true,
    //     component: React.lazy(() => import('./tournament/page/list'))
    // },
    {
        label: translate('navigation.lotteries'),
        path: '/bet',
        exact: true,
        component: React.lazy(() => import('./bet'))
    }
]

export {
    configNagiation
}