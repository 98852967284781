import React from 'react';
import { useDispatch } from 'react-redux';
import {ReactComponent as Add} from '../../../../@casino/img/add.svg';
import { setPostUpdateVerification } from '../../layout/store/thunk/setPostUpdateVerification';
import { setPostCreateVerification } from '../../layout/store/thunk/setPostVerification';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {translate} from '../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 50,  
        marginBottom: 50,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column', 
        }
    },
    title: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
    },
    wrap: {
        marginTop: 20, 
        width: '100%', 
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr 1fr', 
        columnGap: 35,
    },
    wrapImage: {
        width: 220, 
        height: 130, 
        background: '#142A8F', 
        borderRadius: 3, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: 190,
            height: 130, 
        }
    },
    wrapIconAdd: {
        width: 30, 
        height: 30
    },
    image: {
        height: 100,
    },
    mb26: {
        marginBottom: 26,
    },
    info: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        color: '#8E9EC2',
    },
    relativ: {
        position: 'relative',
    },
    loading: {
        background: '#FF005C',
        borderRadius: '50px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: '10px 0',
        cursor: 'pointer'
    },
    notloading: {
        background: 'grey',
        borderRadius: '50px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: '10px 0',
    },
    loadingFile: {
        opacity: 0, 
        position: 'absolute', 
        cursor: 'pointer'
    },
    cancelBtn: {
        background: '#FF005C',
        borderRadius: '50px',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#FFFFFF',
        textAlign: 'center',
        padding: '10px 0',
        cursor: 'pointer'
    },
    wrapIcon: {
        width: 121, 
        height: 130, 
        background: '#142A8F', 
        borderRadius: 3, 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    description: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        color: '#8E9EC2',
    },
    mt20: {
        marginTop: 20,
    },
    infodescription: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        color: '#8E9EC2',
    },
    star: {
        color: '#FF005C'
    },
    flexbox: {
        display: 'flex', 
        flexDirection: 'column',
        marginTop: 20
    },
    field2: {
        width: 414,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
}))

const ItemBlock = ({title, description, Icon, SVGIcon, type, image, cardNumber, setCardNumber}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    
    return (
        <div className={classes.root} style={{borderBottom: type !== 'photo_window_passport' && '1px solid rgba(255,255,255,.2)'}}>
            <div>
                <span className={classes.title}>{title}</span>
                {
                    type === 'selfie_card' &&
                    <div style={{marginTop: 12}}>
                        <input 
                            type="text" 
                            name="selfie-card" 
                            id="selfie-card" 
                            placeholder={translate('common.placeholder.numberCard')}
                            value={cardNumber}
                            onChange={(v) => setCardNumber(v.target.value)}
                            className={classes.field2}
                        />
                    </div>
                }
                
                <div className={classes.wrap}>
                    <div>
                        <div className={classes.wrapImage}>
                            {
                                !image && <Add className={classes.wrapIconAdd}/>
                            }
                            {
                                image && <img src={image} className={classes.image} alt=""/>
                            }
                        </div>
                        <div className={classes.mb26}>
                            <span className={classes.info}>
                            {translate('common.fileSize')} PNG, JPG, JPEG
                            </span>
                        </div>
                        {
                            !image && 
                            <label htmlFor="loading" className={classes.relativ}>
                                <span className={type !== 'selfie_card' || (type === 'selfie_card' && !!cardNumber) ? classes.loading : classes.notloading}>
                                    {translate('common.loading')}
                                </span>
                                <input 
                                    type="file" 
                                    name="loading" 
                                    id="loading" 
                                    className={classes.loadingFile}
                                    onChange={(v) => {
                                        if (type !== 'selfie_card' || (type === 'selfie_card' && !!cardNumber)) {
                                            const file = v.target.files[0];

                                            dispatch(setPostCreateVerification({
                                                type,
                                                file,
                                                cardNumber
                                            }))
                                        }
                                    }}
                                />
                            </label>
                        }
                        {
                            image &&
                            <span 
                                className={classes.cancelBtn}
                                onClick={() => dispatch(setPostUpdateVerification({type}))}
                            >
                                {translate('common.cancel')}
                            </span>
                        }
                        
                    </div>
                    <div>
                        <div className={classes.wrapIcon}>
                            {
                                Icon && <Icon />
                            }
                            {
                                SVGIcon && <img src={SVGIcon} alt=""/>
                            }
                        </div>
                        <div className={classes.mt20}>
                            <span className={classes.description}>
                                {description}
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div className={classes.flexbox} style={{marginTop: type === 'selfie_card' && 90}}>
                <span className={classes.star}>
                    *
                </span>
                <span className={classes.infodescription}>
                    {translate('common.informationDescription')}
                </span>
            </div>
            
        </div>
    )
}

export default ItemBlock;