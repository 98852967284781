import {ReactComponent as Replenishment} from '../../../../@casino/img/replenishment.svg';
import {ReactComponent as Withdrawal} from '../../../../@casino/img/withdrawal.svg';
import React from 'react';
import { translate } from '../../../../dictionaries';

const getDateTime = (value) => {
    const date = new Date(+value);

    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();

    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    
    return `${hours}:${min}  ${day}.${month}.${year}`
}

const getTag = (val) => {
    switch (val) {
        case 'replenishment': return translate('common.replenishment');
        case 'withdrawal': return translate('common.withdrawal');
        default: return translate('common.default');
    }
}

    
const columns = [
    {id: 'type', label: translate('common.typeOperation'), width: '25%'},
    {id: 'summ', label: translate('common.amountSumm'), width: '25%'},
    {id: 'date', label: translate('common.data'), width: '25%'},
    {id: 'status', label: translate('common.status'), width: '25%'},
]

const getRows = (values) => {
    return values.map((item, key) => {
        return {
            type: getTag(item.tag),
            summ: new Intl.NumberFormat('ru-RU').format(item.summ),
            date: getDateTime(item.event_date),
            status: item.status === 'awaiting' ? translate('common.statusAwaiting') : item.status === 'success' ? translate('common.statusSuccess') : translate('common.statusCancel'),
        }
    })
}

const columnsMobile = [
    {id: 'tag', label: '', width: '10%'},
    {id: 'summ', label: translate('common.amountSumm'), width: '30%'},
    {id: 'date', label: translate('common.data'), width: '30%'},
    {id: 'status', label: translate('common.status'), width: '30%'},
]

const getRowsMobile = (values) => {
    return values.map((item, key) => {
        return {
            tag: item.tag === 'replenishment' ? <Replenishment /> : <Withdrawal />,
            summ: new Intl.NumberFormat('ru-RU').format(item.summ),
            date: getDateTime(item.event_date),
            status: item.status === 'awaiting' ? <span style={{color: '#FFBF39'}}>{translate('common.statusAwaiting')}</span> : item.status === 'success' ? <span style={{color: '#00B07B'}}>{translate('common.statusSuccess')}</span> : <span style={{color: '#F44336'}}>{translate('common.statusCancel')}</span>,
        }
    })
}

export {
    columns,
    getRows,
    columnsMobile,
    getRowsMobile
}