
import {getLoterryList} from '../api/getLoterryList';
import {setGetLoterryListAction} from '../action';

export const setGetLoterryList = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getLoterryList(params);

            if (data.status === 'success') {
                dispatch(setGetLoterryListAction({
                    data: data.data.list,
                    page: data.data.countPage
                }));
            }
        } catch(error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}