import React from 'react';

const ConfidetcialTR = () => {
    return (
        <div class="page-content rules">
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>ACTIV AGUA ALIANZA LIMITADA</h2>
            <h2  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Gizlilik Politikası</h2>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                Bu Kişisel Veri Gizlilik Politikası (bundan böyle Gizlilik Politikası olarak anılacaktır), {window.location.origin}  alan adında (ve bunun alt alan adlarında) bulunan {window.location.origin}  web sitesinin (bundan böyle WIN777 olarak anılacaktır) alabileceği tüm bilgiler için geçerlidir. Kullanıcı {window.location.origin}  web sitesini (ve alt alanlarını), programlarını ve ürünlerini kullanırken.
            </p>
            <h2  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Kuralların tanımı</h2>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1 Bu Gizlilik Politikasında aşağıdaki terimler kullanılmaktadır:
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.1. "Site Yönetimi" (bundan sonra - İdare) - kişisel verilerin işlenmesini organize eden ve (veya) gerçekleştiren ve ayrıca kişisel verilerin işlenme amaçlarını, kişisel verilerin bileşimini belirleyen {window.location.origin}  sitesini yönetmeye yetkili çalışanlar işlenecek, eylemler (işlemler), kişisel verilerle işlenir.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.2. "Kişisel veriler" - doğrudan veya dolaylı olarak kimliği belirli veya belirlenebilir bir gerçek kişiye (kişisel verilerin konusu) ilişkin her türlü bilgi.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.3. "Kişisel verilerin işlenmesi" - toplama, kaydetme, sistemleştirme, biriktirme, depolama, açıklama (güncelleme, değiştirme) dahil olmak üzere kişisel verilerle otomasyon araçları kullanılarak veya kullanılmadan gerçekleştirilen herhangi bir eylem (işlem) veya bir dizi eylem (işlem) , çıkarma, kullanma, aktarma (dağıtım, sağlama, erişim), duyarsızlaştırma, engelleme, silme, kişisel verilerin imhası.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.4. “Kişisel verilerin gizliliği”, İşletmeci'nin veya kişisel verilere erişim sağlayan diğer kişilerin, kişisel verilerin sahibinin rızası veya diğer yasal gerekçeler olmaksızın dağıtılmaması için zorunlu bir gerekliliktir.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.5. "Site {window.location.origin} ", İnternette benzersiz bir adreste (URL) barındırılan birbirine bağlı web sayfalarından oluşan bir koleksiyondur: {window.location.origin}  ve bunun alt alanları.</p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.6. "Alt alanlar", {window.location.origin}  sitesine ait üçüncü düzey alanlarda bulunan sayfalar veya bir dizi sayfanın yanı sıra, altında İdarenin iletişim bilgilerinin belirtildiği diğer geçici sayfalardır.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.5. "{window.location.origin}  web sitesinin kullanıcısı" (bundan böyle Kullanıcı olarak anılacaktır), internet üzerinden {window.location.origin}  web sitesine erişimi olan ve {window.location.origin}  web sitesinin bilgi, materyal ve ürünlerini kullanan bir kişidir.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                1.1.7. "Çerez", bir web sunucusu tarafından gönderilen ve kullanıcının bilgisayarında depolanan, web istemcisi veya web tarayıcısının ilgili sitenin bir sayfasını her açmaya çalıştığında bir HTTP isteğinde web sunucusuna gönderdiği küçük bir veri parçasıdır.
            </p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>1.1.8. “IP adresi”, Kullanıcının WIN777'ye erişim sağladığı bir bilgisayar ağındaki bir düğümün benzersiz ağ adresi anlamına gelir.</p>
            <h2  style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Genel Hükümler</h2>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>2.1. {window.location.origin}  sitesinin Kullanıcı tarafından kullanılması, bu Gizlilik Politikasının ve Kullanıcının kişisel verilerinin işlenmesine ilişkin şartların kabul edilmesi anlamına gelir.</p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>2.2. Gizlilik Politikasının şartları ile anlaşmazlık olması durumunda, Kullanıcı {window.location.origin}  sitesini kullanmayı bırakmalıdır.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }} >2.3. Bu Gizlilik Politikası, {window.location.origin}  web sitesi için geçerlidir. WIN777, Kullanıcının {window.location.origin}  web sitesinde bulunan bağlantıları takip edebileceği üçüncü taraf web sitelerini kontrol etmez ve bunlardan sorumlu değildir.</p>
            <p  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>2.4. Yönetim, Kullanıcı tarafından sağlanan kişisel verilerin doğruluğunu doğrulamaz.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Gizlilik politikasının konusu</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>3.1. Bu Gizlilik Politikası, İdarenin ifşa etmeme ve Kullanıcının {window.location.origin}  web sitesine kaydolurken veya e-postaya abone olurken İdarenin talebi üzerine sağladığı kişisel verilerin gizliliğini koruma rejimini sağlama yükümlülüklerini belirler. haber bülteni.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
               3.2. Bu Gizlilik Politikası kapsamında işlenmesine izin verilen kişisel veriler, Kullanıcı tarafından {window.location.origin}  web sitesindeki formlar doldurularak sağlanır ve aşağıdaki bilgileri içerir:
             3.2.1. Kullanıcının soyadı, adı, soyadı;
             3.2.2. Kullanıcının iletişim telefon numarası;
             3.2.3. e-posta adresi (e-posta)
             3.2.4. Kullanıcının ikamet ettiği yer (gerekirse)
             3.2.5. fotoğraf (gerekirse)</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>3.3. WIN777, sayfaları ziyaret ederken otomatik olarak iletilen Verileri korur:</p>
            <ul  style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
            <li>IP adresi;</li>
             <li>çerezlerden gelen bilgiler;</li>
             <li>tarayıcı bilgileri</li>
             <li>erişim süresi;</li>
             <li>yönlendiren (önceki sayfa adresi).</li>
            </ul>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>3.3.1. Çerezlerin devre dışı bırakılması, sitenin yetki gerektiren bölümlerine erişilememesiyle sonuçlanabilir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>3.3.2. WIN777, ziyaretçilerinin IP adresleri hakkında istatistikler toplar. Bu bilgiler teknik sorunları önlemek, tespit etmek ve çözmek için kullanılır.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                3.4. Yukarıda belirtilmeyen diğer kişisel bilgiler (ziyaret geçmişi, kullanılan tarayıcılar, işletim sistemleri vb.), paragraflarda belirtilenler dışında güvenli depolamaya ve dağıtılmaya tabidir. 5.2. bu Gizlilik Politikasının
            </p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Kullanıcının kişisel bilgilerini toplama amaçları</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                4.1. Yönetim, Kullanıcının kişisel verilerini aşağıdaki amaçlarla kullanabilir:
            4.1.1. Daha fazla yetkilendirme için {window.location.origin}  sitesinde kayıtlı Kullanıcının Kimliği.
            4.1.2. Kullanıcıya {window.location.origin}  web sitesinin kişiselleştirilmiş verilerine erişim sağlama.
            4.1.3. Bildirimlerin gönderilmesi, {window.location.origin}  sitesinin kullanımına ilişkin talepler, Kullanıcıdan gelen taleplerin ve uygulamaların işlenmesi dahil olmak üzere Kullanıcı ile geri bildirim oluşturmak.
            4.1.4. Güvenliğin sağlanması, dolandırıcılığın önlenmesi için Kullanıcının lokasyonunun belirlenmesi.
            4.1.5. Kullanıcı tarafından sağlanan kişisel verilerin doğruluğunun ve eksiksizliğinin teyidi.
            4.1.6. Kullanıcı bir hesap oluşturmayı kabul ettiyse, {window.location.origin}  sitesinin bölümlerini kullanmak için bir hesap oluşturma.
            4.1.7. E-posta ile kullanıcı bildirimleri.
            4.1.8. {window.location.origin}  sitesinin kullanımıyla ilgili sorunlarda Kullanıcıya etkin teknik destek sağlanması.
            4.1.9. {window.location.origin}  sitesi adına özel teklifler, haber bültenleri ve diğer bilgilerle Kullanıcının rızasını sağlamak.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Kişisel bilgileri işleme yöntemleri ve şartları</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                5.1. Kullanıcı'nın kişisel verilerinin işlenmesi, kişisel veri bilgi sistemleri de dahil olmak üzere, herhangi bir yasal şekilde, herhangi bir süre sınırlaması olmaksızın, otomasyon araçları kullanılarak veya bu araçlar kullanılmadan gerçekleştirilir.
            </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>5.2. Kullanıcının kişisel verileri, Rusya Federasyonu'nun yetkili devlet makamlarına yalnızca Rusya Federasyonu mevzuatı ile belirlenen gerekçelerle ve şekilde aktarılabilir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>5.3. Kişisel verilerin kaybolması veya ifşa edilmesi durumunda İdare, kişisel verilerin kaybı veya ifşası hakkında Kullanıcıyı bilgilendirmeme hakkına sahiptir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>5.4. Yönetim, Kullanıcının kişisel bilgilerini yetkisiz veya kazara erişim, imha, değiştirme, engelleme, kopyalama, dağıtım ve ayrıca üçüncü şahısların diğer yasa dışı eylemlerinden korumak için gerekli organizasyonel ve teknik önlemleri alır.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>5.5. Yönetim, Kullanıcı ile birlikte, Kullanıcının kişisel verilerinin kaybolması veya ifşa edilmesinden kaynaklanan kayıpları veya diğer olumsuz sonuçları önlemek için gerekli tüm önlemleri alır.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Tarafların hak ve yükümlülükleri</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.1. Kullanıcının hakkı:</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.1.1. {window.location.origin}  sitesini kullanmak için gerekli olan kişisel verilerinizi sağlamak için ücretsiz bir karar verin ve bunların işlenmesine izin verin.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.1.2. Kişisel verilerle ilgili sağlanan bilgileri güncelleyin, bu bilgilerin değişmesi durumunda tamamlayın.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.1.3. Kullanıcı, federal yasalara göre sınırlandırılmamışsa, kişisel verilerinin işlenmesiyle ilgili olarak İdareden bilgi alma hakkına sahiptir. Kullanıcı, kişisel verilerinin eksik, güncel olmayan, yanlış, yasa dışı olarak elde edilmiş veya belirtilen işleme amacı için gerekli olmadığı durumlarda Yönetim'den kişisel verilerini açıklığa kavuşturmasını, bunları engellemesini veya imha etmesini talep etme ve ayrıca korumak için yasal önlemler alma hakkına sahiptir. onların hakları. Bunu yapmak için, belirtilen E-posta adresinden Yönetime haber vermeniz yeterlidir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.2. Yönetim şunları yapmakla yükümlüdür:</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.2.1. Alınan bilgileri yalnızca bu Gizlilik Politikasının 4. maddesinde belirtilen amaçlar için kullanın.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.2.2. Gizli bilgilerin gizli tutulmasını, Kullanıcının önceden yazılı izni olmaksızın ifşa edilmemesini ve ayrıca maddeler dışında Kullanıcının aktarılan kişisel verilerini satmamasını, değiştirmemesini, yayınlamamasını veya diğer olası şekillerde açıklamamasını sağlamak. 5.2. bu Gizlilik Politikasının.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.2.3. Mevcut ticari işlemlerde bu tür bilgileri korumak için genellikle kullanılan prosedüre uygun olarak Kullanıcının kişisel verilerinin gizliliğini korumak için önlemler alın.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>6.2.4. İlgili Kullanıcı ile ilgili kişisel verilerin, kişisel veri sahiplerinin haklarının korunması için Kullanıcı veya yasal temsilcisi veya yetkili organı ile iletişime geçildiği veya doğrulanma süresi talep edildiği andan itibaren, yanlış kişisel verilerin ortaya çıkması veya yasa dışı eylemler.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Tarafların sorumluluğu</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.1. Yükümlülüklerini yerine getirmeyen İdare, Rusya Federasyonu mevzuatına uygun olarak, kişisel verilerin yasa dışı kullanımı ile bağlantılı olarak Kullanıcı'nın uğradığı zararlardan, paragraflarda belirtilen durumlar dışında sorumludur. 5.2. ve 7.2. bu Gizlilik Politikasının.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                7.2. Gizli Bilgilerin kaybolması veya ifşa edilmesi durumunda, İdare bu gizli bilgilerin aşağıdaki durumlardan sorumlu değildir:
             7.2.1. Kaybolmadan veya ifşa edilmeden önce kamu malı oldu.
             7.2.2. Kaynak İdaresi tarafından alınana kadar üçüncü bir taraftan alındı.
             7.2.3. Kullanıcının rızasıyla ifşa edilmiştir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.3. Kullanıcı, reklamcılık, telif hakkı ve ilgili hakların korunması, ticari markaların ve hizmet markalarının korunmasına ilişkin yasalar da dahil olmak üzere Rusya Federasyonu mevzuatının gerekliliklerine uymaktan tamamen sorumludur, ancak bunlarla sınırlı değildir. materyallerin içeriği ve biçiminden sorumludur.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.4. Kullanıcı, {window.location.origin}  sitesinin bir parçası olarak erişebileceği her türlü bilginin (veri dosyaları, metinler vb. dahil ancak bunlarla sınırlı olmamak üzere) sorumluluğunun, bu bilgileri sağlayan kişiye ait olduğunu kabul eder. </p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>
                7.5. Kullanıcı, {window.location.origin}  web sitesinin bir parçası olarak kendisine sağlanan bilgilerin fikri mülkiyet olabileceğini, haklarının korunduğunu ve bu bilgileri {window.location.origin}  web sitesine yerleştiren diğer Kullanıcılar, ortaklar veya reklamcılara ait olduğunu kabul eder.
            Kullanıcı, bu tür İçeriklerin sahipleri tarafından yazılı olarak açıkça yetkilendirilmediği sürece, bu tür İçeriği (tamamen veya kısmen) değiştiremez, kiralayamaz, ödünç veremez, satamaz, dağıtamaz veya bu İçeriği temel alan türev çalışmalar oluşturamaz. ayrı bir sözleşme.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.6. Metin malzemeleriyle ilgili olarak (makaleler, {window.location.origin}  sitesinde halka açık ücretsiz erişime sahip yayınlar), WIN777'ye bir bağlantı verilmesi koşuluyla bunların dağıtımına izin verilir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.7. Yönetim, {window.location.origin}  web sitesinde yer alan veya bu web sitesi aracılığıyla iletilen herhangi bir İçeriğin ve diğer iletişim verilerinin silinmesi, başarısızlığı veya kaydedilememesi nedeniyle Kullanıcının uğrayacağı herhangi bir kayıp veya zarardan Kullanıcıya karşı sorumlu değildir.</p >
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.8. Yönetim, aşağıdakilerden kaynaklanan doğrudan veya dolaylı zararlardan sorumlu değildir: sitenin veya bireysel hizmetlerin kullanımı veya kullanılamaması; Kullanıcının iletişimlerine yetkisiz erişim; sitedeki herhangi bir üçüncü tarafın beyanları veya davranışları.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>7.9. Yönetim, kullanıcı tarafından {window.location.origin}  web sitesinde yayınlanan, bunlarla sınırlı olmamak üzere, telif hakkı sahibinin açık izni olmadan telif hakkıyla korunan bilgiler dahil olmak üzere hiçbir bilgiden sorumlu değildir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Tartışmalı karar</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>8.1. Kullanıcı ve İdare arasındaki ilişkiden kaynaklanan uyuşmazlıklar için mahkemeye gitmeden önce bir talep (yazılı bir teklif veya uyuşmazlığın gönüllü olarak çözülmesi için elektronik bir teklif) sunulması zorunludur.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>8.2. Talebin alıcısı, talebin alındığı tarihten itibaren 30 takvim günü içinde, yazılı veya elektronik biçimde, talebin değerlendirilmesinin sonuçlarını davacıya bildirir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>8.3. Bir anlaşmaya varılmazsa, anlaşmazlık Tahkim Mahkemesine götürülecektir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>8.4. Rusya Federasyonu'nun mevcut mevzuatı, bu Gizlilik Politikası ve Kullanıcı ile Yönetim arasındaki ilişki için geçerlidir.</p>
            <h2 style={{display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'}}>Ek koşullar</h2>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>9.1. Yönetim, Kullanıcının izni olmadan bu Gizlilik Politikasında değişiklik yapma hakkına sahiptir.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>9.2. Yeni Gizlilik Politikası, Gizlilik Politikasının yeni baskısı tarafından aksi belirtilmedikçe, {window.location.origin}  web sitesinde yayınlandığı andan itibaren yürürlüğe girer.</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>9.3. Bu Gizlilik Politikası ile ilgili herhangi bir öneri veya soru teknik desteğe bildirilmelidir</p>
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>9.4. Mevcut Gizlilik Politikası, http://{window.location.origin} /</p> adresindeki sayfada yayınlanmıştır.
            <p style={{
                color: 'rgba(255,255,255,0.8)',
                fontFamily: 'Montserrat, sans-serif'
            }}>Güncelleme: 1 Temmuz 2021</p>
        </div>
    )
}

export default ConfidetcialTR;