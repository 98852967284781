import {types} from './types';

const initialState = {
    view: null,
    country: null
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_PROFILE:
            return { ...state, view: action.payload};
        case types.SET_COUNTRY:
            return { ...state, country: action.payload}
        default:
            return state
    }
}